import { Typography } from "@mui/material";
import { useGetRechnungsDublette } from "../../client/hooks";
import { useEffect, useState } from "react";
import { Field, useField } from "formik";
import { TextField } from "formik-mui";

export default function DublettenCheck({
  rechnungId,
  lieferantenId,
  rechnungsnummer,
}: {
  rechnungId: string;
  lieferantenId: string;
  rechnungsnummer: string;
}) {
  const checkDublette = useGetRechnungsDublette();
  const [isDublette, setIsDublette] = useState<boolean>(false);
  const [rechnungsnummerField] = useField("rechnungsnummer");
  useEffect(() => {
    const fetchDublette = async () => {
      if (rechnungsnummer !== undefined && lieferantenId !== undefined) {
        let dublette = await checkDublette(rechnungId, {
          rechnungsNummer: rechnungsnummer ? rechnungsnummer : "",
          lieferantenId: lieferantenId ? lieferantenId : "",
        });
        console.log("rechnungsnunmmer" + rechnungsnummer);
        console.log("lieferant" + lieferantenId);
        setIsDublette(dublette.isDublette);
      }
    };
    fetchDublette();
    console.log("rechnungsnunmmer" + rechnungsnummer);
    console.log("lieferant" + lieferantenId);
  }, [rechnungsnummer, lieferantenId]);
  return (
    <>
      {isDublette ? (
        <Typography variant="h5" color={"error"}>
          Diese Rechnungsnummer ist bereits bekannt
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
}
