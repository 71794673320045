import {
  Box,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
} from "@mui/material";
import { useT } from "../../i18n";
import { useCallback } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import SubmitButton from "../SubmitButton";
import { useGeneriereZahlungsdatei } from "../../client/hooks";
import { Zahllauf, Konto, Zahlungsdatei } from "../../api";
import { useQueryClient } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import ZahlungsdateiGenerierenModalFieldsContent from "./ZahlungsdateiGenerierenModalFieldsContent";

interface ZahlungsdateiGenerierenModalProps {
  setAktuelleZahllaufId: (value: string) => void;
  aktuelleZahllaufId: string;
  setAktuelleKontoId: (value: string) => void;
  aktuelleKontoId: string;
  setZahlungsdateiGenerierenModalOpened: (value: boolean) => void;
}

function ZahlungsdateiGenerierenModal({
  setAktuelleZahllaufId,
  aktuelleZahllaufId,
  setAktuelleKontoId,
  aktuelleKontoId,
  setZahlungsdateiGenerierenModalOpened,
}: ZahlungsdateiGenerierenModalProps) {
  const { t } = useT("zahllauf");
  const queryClient = useQueryClient();

  const generiereZahlungsdatei = useGeneriereZahlungsdatei();

  let schema = yup.object().shape({
    zahllauf: yup.object().required(),
    konto: yup.object().required(),
  });

  const onSubmit = useCallback(
    async (
      values: {
        zahllauf: any;
        konto: any;
      },
      formikHelpers: FormikHelpers<{
        zahllauf: any;
        konto: any;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      try {
        const zahlungsdatei = await generiereZahlungsdatei(
          typeof values.zahllauf === "string"
            ? values.zahllauf
            : (values.zahllauf as Zahllauf)?.id ?? "",
          {
            kontoId:
              typeof values.konto === "string"
                ? values.konto
                : (values.konto as Konto)?.id ?? "",
          }
        ).then((response: Zahlungsdatei) => {
          const blob = new Blob([response.zahlungsdatei], {
            type: "application/xml",
          });
          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download =
            (typeof values.zahllauf === "string"
              ? values.zahllauf
              : (values.zahllauf as Zahllauf)?.bezeichnung ?? "file") + ".xml";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        });
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
        setZahlungsdateiGenerierenModalOpened(false);
        queryClient.invalidateQueries(["zahllauf"]);
      }
    },
    [generiereZahlungsdatei, t]
  );

  return (
    <Card
      sx={{
        position: "fixed",
        right: "2em",
        bottom: "1.5em",
        minWidth: "40%",
        boxShadow: 20,
        marginTop: 2,
        padding: 3,
        zIndex: 6,
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <CardHeader
          title={`${capitalize(t("payment-file"))}`}
          subheader={`${capitalize(t("generate"))}`}
        ></CardHeader>
        <IconButton
          onClick={() => setZahlungsdateiGenerierenModalOpened(false)}
          sx={{ marginBottom: "2em" }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <CardContent>
        <Stack>
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={{
              zahllauf: aktuelleZahllaufId,
              konto: aktuelleKontoId,
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => {
              return (
                <>
                  <Form id="zahlungsdatei-generieren">
                    <ZahlungsdateiGenerierenModalFieldsContent
                      setAktuelleZahllaufId={setAktuelleZahllaufId}
                      setAktuelleKontoId={setAktuelleKontoId}
                    />
                    <Box
                      display={"grid"}
                      sx={{
                        marginTop: 3,
                        gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr 1fr"],
                        gap: 2,
                      }}
                    >
                      <SubmitButton
                        form={"zahlungsdatei-generieren"}
                        variant="contained"
                        color="secondary"
                        sx={{
                          gridColumn: { sm: "5 / 6" },
                        }}
                        loading={isSubmitting}
                        disabled={isValidating || !isValid || !dirty}
                        type={"submit"}
                      >
                        {capitalize(t("generate"))}
                      </SubmitButton>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ZahlungsdateiGenerierenModal;
