// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Maschinentransport } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteMaschinentransport(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  maschinentransportId: string
) => Promise<Maschinentransport> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, fuhrparkeintragId, maschinentransportId) =>
      (await getAPI()).maschinentransporte.deleteMaschinentransport({
        taetigkeitsberichtId,
        fuhrparkeintragId,
        maschinentransportId,
      }),
    [getAPI]
  );
}
