import {
  Box,
  capitalize,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Field, useField } from "formik";
import { Select, TextField } from "formik-mui";
import { useT } from "../../../i18n";
import { FahrzeugGruppe, HerkunftTyp } from "../../../api";
import { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import FahrzeugKostenDataTable from "./FahrzeugKostenDataTable";

interface FahrzeugFieldsContentProps {
  isNew: boolean;
  fahrzeugId?: string;
}

export default function FahrzeugFieldsContent({
  isNew,
  fahrzeugId,
}: FahrzeugFieldsContentProps) {
  const { t } = useT("fuhrpark");
  const [ladeMengeField, ladeMengeMeta, ladeMengeHelpers] =
    useField("ladeMenge");
  const [aktuelleGruppe, setAktuelleGruppe] = useState("EIGEN_GERAET");
  const fahrzeugGruppen: FahrzeugGruppe[] = [
    "BAGGER",
    "LKW",
    "RADLADER",
    "RAUPE",
    "PKW",
    "WALZE",
    "KOMPAKTLADER",
    "DUMPER",
    "BRECHER",
    "SIEBANLAGE",
    "FÖRDERBAND",
    "BOHRGERÄT",
    "TELESKOP",
  ];
  const herkunftTypen: HerkunftTyp[] = ["EIGEN_GERAET", "FREMD_GERAET"];
  const [fahrzeugKostenOpen, setFahrzeugKostenOpen] = useState(false);

  const handleFahrzeugKostenClickOpen = () => {
    setFahrzeugKostenOpen(true);
  };

  const handleFahrzeugKostenClose = () => {
    setFahrzeugKostenOpen(false);
  };

  return (
    <Stack direction="column">
      <Dialog
        title={t("new-attachment-part")}
        open={fahrzeugKostenOpen}
        onClose={handleFahrzeugKostenClose}
      >
        <DialogContent>
          <FahrzeugKostenDataTable fahrzeugKostenId={fahrzeugId ?? ""} />
        </DialogContent>
      </Dialog>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          name="gruppe"
          component={Select}
          label={capitalize(t("vehicle-group")) + " *"}
          required
        >
          {fahrzeugGruppen.map((gruppe) => (
            <MenuItem key={gruppe} value={gruppe}>
              {capitalize(gruppe.toLowerCase())}
            </MenuItem>
          ))}
        </Field>
        <Field
          name="herkunft"
          component={Select}
          label={capitalize(t("vehicle-origin")) + " *"}
          required
          onChange={(element: any) => {
            setAktuelleGruppe(element.target.value);
            if (
              element.target.value === "FREMD_GERAET" &&
              ladeMengeField.value === 0
            )
              ladeMengeHelpers.setValue(19);
            else if (
              element.target.value === "EIGEN_GERAET" &&
              ladeMengeField.value === 19
            ) {
              ladeMengeHelpers.setValue(0);
            } else {
              return null;
            }
          }}
        >
          {herkunftTypen.map((typ) => (
            <MenuItem key={typ} value={typ}>
              {typ === "EIGEN_GERAET" ? "Eigenes Gerät" : "Fremdgerät"}
            </MenuItem>
          ))}
        </Field>
        <Field
          component={TextField}
          label={capitalize(t("vehicle-purchased"))}
          name="angeschafft"
          type={"date"}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field
          component={TextField}
          label={
            capitalize(t("vehicle-make")) +
            (aktuelleGruppe === "EIGEN_GERAET" ? " *" : "")
          }
          name="fabrikant"
        />
        <Field
          component={TextField}
          label={
            capitalize(t("vehicle-vehicle-type")) +
            (aktuelleGruppe === "EIGEN_GERAET" ? " *" : "")
          }
          name="fahrzeugTyp"
        />
        <Field
          component={TextField}
          label={capitalize(t("vehicle-current-number"))}
          name="laufendeNummer"
        />
        <Field
          component={TextField}
          label={capitalize(t("vehicle-mark"))}
          name="kennzeichen"
          required
        />
        <Box
          display={"grid"}
          sx={{
            gridTemplateColumns: ["1fr", "1fr .1fr"],
            gap: 2,
            marginTop: 2,
          }}
        >
          <Field
            component={TextField}
            label={capitalize(t("vehicle-cost-hour"))}
            name="kostenStundeAktuell"
            type={"number"}
            inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          />
          {!isNew ? (
            <IconButton onClick={handleFahrzeugKostenClickOpen}>
              <HelpIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Typography sx={{ paddingTop: 2 }}>
        {capitalize(t("technical-data"))}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          component={TextField}
          label={capitalize(t("vehicle-width"))}
          name="breite"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
        <Field
          component={TextField}
          label={capitalize(t("vehicle-depth"))}
          name="tiefe"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
        <Field
          component={TextField}
          label={capitalize(t("vehicle-height"))}
          name="hoehe"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
        <Field
          component={TextField}
          label={capitalize(t("vehicle-total-mass"))}
          name="gesamtMasse"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
        <Field
          component={TextField}
          label={capitalize(t("vehicle-loudness"))}
          name="lautstaerke"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
        <Field
          component={TextField}
          label={capitalize(t("vehicle-loading-quantity"))}
          name="ladeMenge"
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
        />
      </Box>
    </Stack>
  );
}
