// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Dublette } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type GetRechnungsDubletteQuery = {
  rechnungsNummer?: string;
  lieferantenId?: string;
};

export function useGetRechnungsDublette(): (
  rechnungId: string,
  query: GetRechnungsDubletteQuery
) => Promise<Dublette> {
  const getAPI = useGetApi();
  return useCallback(
    async (rechnungId, query = {}) =>
      (await getAPI()).rechnungen.getRechnungsDublette({
        rechnungId,
        ...query,
      }),
    [getAPI]
  );
}
