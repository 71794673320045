// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type EntsorgerListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheEntsorgerQuery = {
  search?: string;
  filtered?: string;
  page?: number;
  limit?: number;
  sort?: string;
  isArchiviert?: boolean;
};

export function useSucheEntsorger(): (
  query: SucheEntsorgerQuery
) => Promise<EntsorgerListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) =>
      (await getAPI()).entsorger.sucheEntsorger({ ...query }),
    [getAPI]
  );
}
