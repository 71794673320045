// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abwesenheit, MyAbwesenheitDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateMeineAbwesenheit(): (
  abwesenheitId: string,
  requestBody: MyAbwesenheitDaten
) => Promise<Abwesenheit> {
  const getAPI = useGetApi();
  return useCallback(
    async (abwesenheitId, requestBody) =>
      (await getAPI()).abwesenheiten.updateMeineAbwesenheit({
        abwesenheitId,
        requestBody,
      }),
    [getAPI]
  );
}
