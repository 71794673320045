// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  type Materialtransport,
  MaterialtransportZwischenDaten,
} from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateMaterialtransportZwischenBaustellen(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  requestBody: MaterialtransportZwischenDaten
) => Promise<Materialtransport> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, fuhrparkeintragId, requestBody) =>
      (
        await getAPI()
      ).materialtransporte.createMaterialtransportZwischenBaustellen({
        taetigkeitsberichtId,
        fuhrparkeintragId,
        requestBody,
      }),
    [getAPI]
  );
}
