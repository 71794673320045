import {
  Box,
  Button,
  capitalize,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Select,
  Typography,
} from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React, { useEffect, useState } from "react";
import {
  useDeleteMaterialtransport,
  useGetAbfallart,
  useGetFahrzeug,
  useGetMaterial,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid2";
import {
  ActionTimestamp,
  Buchungstyp,
  EinheitenListe,
  Material,
} from "../../../../api";
import AbladestellenSelect from "../../../abladestellen/AbladestellenSelect";
import Buchungsart from "../../../Buchungsart";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import MaterialSelect from "../../../material/MaterialSelect";
import MaterialEakSelect from "../../../material/MaterialEakSelect";
import EinheitSelect from "../../../einheiten/EinheitSelect";

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelle?: any;
  zielAbladestelle?: any;
  quelleBaustelleId?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: any;
}

export default function MaterialanfuhrFieldsContent({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: MaterialTransportDaten;
  fahrzeugId: string;
}) {
  const { t } = useT("fuhrpark");
  const deleteMaterialtransport = useDeleteMaterialtransport();
  const { setFieldValue } = useFormikContext();
  const getMaterial = useGetMaterial();
  const ladeAbfallart = useGetAbfallart();
  const [isKosten, setIsKosten] = useState(
    materialtransport.buchungsArt === "Kosten"
  );
  const getfahrzeug = useGetFahrzeug();
  const [currentfahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  const [abladestelleId, setAbladestelleId] = useState<String | null>(
    materialtransport?.quelleAbladestelle ?? null
  );
  const [eakSchluessel, setEAKSchluessel] = useState<string>("-");
  const [mengeField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.menge`
  );
  const [preisField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.kostenProEinheit`
  );
  const [fuhrenField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.anzahlFuhren`
  );
  const [einheitField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.einheit`
  );
  const [materialField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.material`
  );

  useEffect(() => {
    setAbladestelleId(abladestelleId);
    setFieldValue(
      `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.material`,
      null
    );
  }, [setAbladestelleId, abladestelleId]);

  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  useEffect(() => {
    const fetchData = async () => {
      const material = materialField.value as Material;
      if (material) {
        const data = await ladeAbfallart(material.abfallartId);
        setEAKSchluessel(data.key ?? "");
      }
    };
    fetchData();
  }, [materialField.value]);
  useEffect(() => {
    setFieldValue(
      `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.mengeGesamt`,
      mengeField.value * fuhrenField.value
    );
    setFieldValue(
      `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.preisGesamt`,
      (mengeField.value * fuhrenField.value * preisField.value).toFixed(2)
    );
  }, [mengeField.value, fuhrenField.value, preisField.value]);
  useEffect(() => {
    if (!materialtransport.id) {
      if (einheitField.value === "TO") {
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          ).then((material: Material) => {
            if ((material?.kostenProTonne ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.kostenProEinheit`,
                material.kostenProTonne ?? ""
              );
            }
          });
        }
      }
      if (einheitField.value === "CB") {
        console.log("fahrzeugId: ", fahrzeugId);
        if (fahrzeugId) {
          getfahrzeug(fahrzeugId).then((fahrzeug) => {
            console.log("fahrzeug:lademenge ", fahrzeug.ladeMenge);
            if ((fahrzeug?.ladeMenge ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.menge`,
                fahrzeug.ladeMenge ?? ""
              );
            }
          });
        }
        if (materialField.value) {
          getMaterial(
            typeof materialField.value === "string"
              ? materialField.value
              : (materialField.value as Material)?.id ?? ""
          ).then((material: Material) => {
            if ((material?.kostenProKubikmeter ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.kostenProEinheit`,
                material.kostenProKubikmeter ?? ""
              );
            }
          });
        }
      }
    }
  }, [einheitField.value, materialField.value]);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {t("material-delivery")}
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 3, md: 3 }}>
            {materialtransport.zielBaustelle ? (
              <FastField
                component={BaustellenSelect}
                name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.zielBaustelle`}
                label={t("vehicle-unloading-point")}
                required
              />
            ) : (
              <FastField
                component={AbladestellenSelect}
                name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.quelleAbladestelle`}
                label={t("vehicle-unloading-point")}
                required
                setAbladestelleId={setAbladestelleId}
              />
            )}
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            {abladestelleId ? (
              <FastField
                component={MaterialEakSelect}
                name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.material`}
                label={t("material")}
                abladestelleId={abladestelleId}
                setAbfallartKey={setEAKSchluessel}
                required
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("material") + " *"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Material"
                  disabled={true}
                ></Select>
              </FormControl>
            )}
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <Stack>
              <Typography sx={{ color: "gray" }}>EAK-Schlüssel</Typography>
              {eakSchluessel}
            </Stack>
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <Box></Box>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }} container>
            {materialTransportIndex === newestIndex ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  push({
                    quelleAbladestelle: "",
                    meLadeVolumenFahrzeug: 0,
                    anzahlFuhren: "",
                    lieferscheinNummer: "",
                    bemerkung: "",
                    buchungsArt: "Kosten",
                    zielBaustelle: "",
                  });
                }}
              >
                <AddCircleIcon />
                {"(MA)"}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("vehicle-number-of-trips")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.anzahlFuhren`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={EinheitSelect}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.einheit`}
              label={t("unit")}
              required
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("quantity")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.menge`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-quantity")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.mengeGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("price")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.kostenProEinheit`}
              required
              type={"number"}
              inputProps={{ step: 0.01, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-price")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.preisGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Button
              sx={{ color: "gray" }}
              onClick={() => {
                const materialransportId = materialtransport.id;
                if (materialransportId) {
                  deleteMaterialtransport(
                    materialtransport.taetigkeitsberichtId,
                    materialtransport.fuhrparkeintragId,
                    materialransportId
                  ).then(() => {
                    remove(materialTransportIndex);
                  });
                } else {
                  remove(materialTransportIndex);
                }
              }}
              aria-label="delete"
            >
              <DeleteIcon />
              {"(MA)"}
            </Button>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-lfsNo")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.lieferscheinNummer`}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-remark")}
              name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.bemerkung`}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}
