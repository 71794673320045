import { Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import BenutzerDataTable from "../../components/benutzer/BenutzerDataTable";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import React from "react";
import Guard from "../../components/Guard";

export default function BenutzerListe() {
  const { t } = useT("customer");
  const limit = parseInt(localStorage.getItem("benutzerLimit") ?? "25", 10);
  return (
    <Layout>
      <Guard
        permission={"benutzer:list"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Typography variant="h5">Benutzer</Typography>
          <BenutzerDataTable limit={limit} />
        </Paper>
      </Guard>
    </Layout>
  );
}
