import React, { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import GlobalLoading from "../components/GlobalLoading";
import ErstelleKunde from "./kunden/ErstelleKunde";
import KundenListe from "./kunden/KundenListe";
import DetailsKunde from "./kunden/DetailsKunde";
import LieferantenListe from "./lieferanten/LieferantenListe";
import DetailsLieferant from "./lieferanten/DetailsLieferant";
import ErstelleLieferant from "./lieferanten/ErstelleLieferant";
import AbfallartenListe from "./abfallarten/AbfallartenListe";
import DetailsAbfallart from "./abfallarten/DetailsAbfallart";
import ErstelleAbfallart from "./abfallarten/ErstelleAbfallart";
import Fahrzeuge from "./fuhrpark/fahrzeug/Fahrzeuge";
import ErstelleFahrzeug from "./fuhrpark/fahrzeug/ErstelleFahrzeug";
import FahrzeugDetails from "./fuhrpark/fahrzeug/FahrzeugDetails";
import BaustellenListe from "./baustellen/BaustellenListe";
import DetailsBaustelle from "./baustellen/DetailsBaustelle";
import ErstelleBaustelle from "./baustellen/ErstelleBaustelle";
import ErzeugerListe from "./erzeuger/ErzeugerListe";
import DetailsErzeuger from "./erzeuger/DetailsErzeuger";
import ErstelleErzeuger from "./erzeuger/ErstelleErzeuger";
import Taetigkeitsberichte from "./taetigkeitsberichte/Taetigkeitsberichte";
import ErstelleTaetigkeitsbericht from "./taetigkeitsberichte/ErstelleTaetigkeitsbericht";
import TaetigkeitsberichtDetails from "./taetigkeitsberichte/TaetigkeitsberichtDetails";
import BenutzerListe from "./benutzer/BenutzerListe";
import DetailsBenutzer from "./benutzer/DetailsBenutzer";
import ErstelleBenutzer from "./benutzer/ErstelleBenutzer";
import AbladestellenErstellen from "./abladestellen/AbladestellenErstellen";
import AbladestellenListe from "./abladestellen/AbladestellenListe";
import AbladestellenDetails from "./abladestellen/AbladestellenDetails";
import AusgangsrechnungenListe from "./ausgangsrechnungen/AusgangsrechnungenListe";
import ErstelleAusgangsrechnung from "./ausgangsrechnungen/ErstelleAusgangsrechnung";
import DetailsAusgangsrechnung from "./ausgangsrechnungen/DetailsAusgangsrechnung";
import { ThemeWrapper } from "../theme";
import { red } from "@mui/material/colors";
import BaustellenPage from "./baustellen/BaustellenPage";
import DetailsBaustellenKonto from "./baustellen/DetailsBaustellenKonto";
import DetailsBaustellenVorgaenge from "./baustellen/DetailsBaustellenVorgaenge";
import EingangsrechnungenListe from "./rechnungen/EingangsrechnungenListe";
import ExterneSystemeListe from "./externesysteme/ExterneSystemeListe";
import EntsorgerListe from "./entsorger/EntsorgerListe";
import DetailsEntsorger from "./entsorger/DetailsEntsorger";
import ErstelleEntsorger from "./entsorger/ErstelleEntsorger";
import Datev from "./externesysteme/datev";
import Google from "./externesysteme/google";
import ErstelleBetriebstagebuch from "./betriebstagebuecher/ErstelleBetriebstagebuch";
import RechnungsFormular from "./rechnungen/RechnungsFormular";
import TaetigkeitsberichteExtern from "./taetigkeitsberichte/TaetigkeitsberichteExtern";
import TaetigkeitsberichtDetailsExtern from "./taetigkeitsberichte/TaetigkeitsberichtDetailsExtern";
import ErstelleTaetigkeitsberichtExtern from "./taetigkeitsberichte/ErstelleTaetigkeitsberichtExtern";
import ErstelleEntsorgungsdokumentation from "./entsorgungsdokumentationen/ErstelleEntsorgungsdokumentation";
import MeineAnwesenheiten from "./anwesenheiten/meineAnwesenheiten";
import Anwesenheiten from "./anwesenheiten/Anwesenheiten";
import ErstelleMeineAnwesenheit from "./anwesenheiten/ErstelleMeineAnwesenheit";
import ErstelleAnwesenheit from "./anwesenheiten/ErstelleAnwesenheit";
import DetailsAnwesenheit from "./anwesenheiten/DetailsAnwesenheit";
import MeineAbwesenheit from "./abwesenheit/meineAbwesenheiten";
import ErstelleAbwesenheit from "./abwesenheit/ErstelleAbwesenheit";
import Abwesenheiten from "./abwesenheit/Abwesenheiten";
import DetailsAbwesenheit from "./abwesenheit/DetailsAbwesenheit";
import ErstelleMeineAbwesenheit from "./abwesenheit/ErstelleMeineAbwesenheit";
import DetailsMeineAbwesenheit from "./abwesenheit/DetailsMeineAbwesenheit";
import DetailsMeineAnwesenheit from "./anwesenheiten/DetailsMeineAnwesenheit";
import ZahllaufListe from "./zahllauf/ZahllaufListe";
import ZahllaufArchivListe from "./zahllauf/ZahllaufArchivListe";

// This is the root of the routing tree. Here we define all URL paths of the
// application and which components should be rendered when the user navigates
// to them.

export default function Pages() {
  return <RouterProvider router={useRouter()} />;
}

// We load all pages lazily to minimize the bundle size. The code for each page
// will only be loaded once the page is first requested.
const Home = lazy(() => import("./Home"));
const NotFound = lazy(() => import("./NotFound"));

/**
 * Define the routing tree of the application.
 */
function useRouter() {
  return createBrowserRouter([
    {
      element: <PageLoader />,
      children: [
        { index: true, element: <Home /> },
        { path: "dashboard", element: <Home /> },
        { path: "neu-kunde", element: <ErstelleKunde /> },
        { path: "kunden", element: <KundenListe /> },
        { path: "kunden/:id", element: <DetailsKunde /> },
        { path: "neu-lieferant", element: <ErstelleLieferant /> },
        { path: "lieferanten", element: <LieferantenListe /> },
        { path: "lieferanten/:id", element: <DetailsLieferant /> },
        { path: "abfallarten", element: <AbfallartenListe /> },
        { path: "abfallarten/:id", element: <DetailsAbfallart /> },
        { path: "neu-abfallart", element: <ErstelleAbfallart /> },
        { path: "suppliers/:id", element: <DetailsLieferant /> },
        { path: "neues-fahrzeug", element: <ErstelleFahrzeug /> },
        { path: "fahrzeuge", element: <Fahrzeuge /> },
        { path: "fahrzeuge/:id", element: <FahrzeugDetails /> },
        {
          path: "baustellen",
          element: <ThemeWrapper primary={red} />,
          children: [
            { index: true, element: <BaustellenListe /> },
            {
              path: ":id",
              element: <BaustellenPage />,
              children: [
                { index: true, element: <Navigate to="vorgaenge" replace /> },
                { path: "stammdaten", element: <DetailsBaustelle /> },
                { path: "konto", element: <DetailsBaustellenKonto /> },
                { path: "vorgaenge", element: <DetailsBaustellenVorgaenge /> },
              ],
            },
          ],
        },

        { path: "neu-baustelle", element: <ErstelleBaustelle /> },
        { path: "erzeuger", element: <ErzeugerListe /> },
        { path: "erzeuger/:id", element: <DetailsErzeuger /> },
        { path: "neu-erzeuger", element: <ErstelleErzeuger /> },
        { path: "entsorger", element: <EntsorgerListe /> },
        { path: "entsorger/:id", element: <DetailsEntsorger /> },
        { path: "neu-entsorger", element: <ErstelleEntsorger /> },
        {
          path: "taetigkeitsberichte",
          element: <Taetigkeitsberichte />,
        },
        {
          path: "fremdleistungen",
          element: <TaetigkeitsberichteExtern />,
        },
        {
          path: "taetigkeitsberichte/:id",
          element: <TaetigkeitsberichtDetails />,
        },
        {
          path: "fremdleistungen/:id",
          element: <TaetigkeitsberichtDetailsExtern />,
        },
        {
          path: "neuer-taetigkeitsbericht",
          element: <ErstelleTaetigkeitsbericht />,
        },
        {
          path: "neue-fremdleistung",
          element: <ErstelleTaetigkeitsberichtExtern />,
        },
        { path: "benutzer", element: <BenutzerListe /> },
        { path: "benutzer/:id", element: <DetailsBenutzer /> },
        { path: "neu-benutzer", element: <ErstelleBenutzer /> },
        { path: "abladestellen", element: <AbladestellenListe /> },
        { path: "abladestellen/:id", element: <AbladestellenDetails /> },
        { path: "neu-abladestelle", element: <AbladestellenErstellen /> },
        { path: "ausgangsrechnungen", element: <AusgangsrechnungenListe /> },
        { path: "neu-ausgangsrechnung", element: <ErstelleAusgangsrechnung /> },
        {
          path: "ausgangsrechnungen/:id",
          element: <DetailsAusgangsrechnung />,
        },
        { path: "eingangsrechnungen", element: <EingangsrechnungenListe /> },
        { path: "eingangsrechnungen/:id", element: <RechnungsFormular /> },
        { path: "zahllaeufe", element: <ZahllaufListe /> },
        { path: "zahllaufarchiv", element: <ZahllaufArchivListe /> },
        { path: "externesysteme", element: <ExterneSystemeListe /> },
        { path: "externesysteme/rewritten-datev", element: <Datev /> },
        { path: "externesysteme/rewritten-google", element: <Google /> },
        { path: "externesysteme/datev", element: null },
        { path: "externesysteme/google", element: null },
        { path: "betriebstagebuch", element: <ErstelleBetriebstagebuch /> },
        {
          path: "entsorgungsdokumentation",
          element: <ErstelleEntsorgungsdokumentation />,
        },
        { path: "anwesenheiten", element: <Anwesenheiten /> },
        {
          path: "anwesenheiten/:id",
          element: <DetailsAnwesenheit />,
        },
        { path: "neu-anwesenheit", element: <ErstelleAnwesenheit /> },
        {
          path: "neu-meine-anwesenheit",
          element: <ErstelleMeineAnwesenheit />,
        },
        { path: "meine-anwesenheiten", element: <MeineAnwesenheiten /> },
        {
          path: "meine-anwesenheiten/:id",
          element: <DetailsMeineAnwesenheit />,
        },
        { path: "abwesenheit", element: <Abwesenheiten /> },
        { path: "meine-abwesenheit", element: <MeineAbwesenheit /> },
        { path: "meine-abwesenheit/:id", element: <DetailsMeineAbwesenheit /> },
        { path: "abwesenheit/:id", element: <DetailsAbwesenheit /> },
        { path: "neu-abwesenheit", element: <ErstelleAbwesenheit /> },
        {
          path: "neu-meine-abwesenheit",
          element: <ErstelleMeineAbwesenheit />,
        },

        // Since this route comes last, it acts as a catch-all.
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);
}

function PageLoader() {
  return (
    <Suspense fallback={<GlobalLoading />}>
      <Outlet />
    </Suspense>
  );
}
