// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { DeponieDaten, type Materialtransport } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateMaterialtransportDeponie(): (
  requestBody: DeponieDaten
) => Promise<Materialtransport> {
  const getAPI = useGetApi();
  return useCallback(
    async (requestBody) =>
      (await getAPI()).materialtransporte.updateMaterialtransportDeponie({
        requestBody,
      }),
    [getAPI]
  );
}
