/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DatevProtokolleListe } from '../models/DatevProtokolleListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DatevService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns DatevProtokolleListe Liste der Datev Protokolle
   * @throws ApiError
   */
  public getRechnungDatevProtokolle({
    rechnungId,
  }: {
    rechnungId: string,
  }): CancelablePromise<DatevProtokolleListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rechnungen/{rechnungId}/datev/protokolle',
      path: {
        'rechnungId': rechnungId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
