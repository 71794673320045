import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  capitalize,
  Stack,
} from "@mui/material";
import { ActionTimestamp, Buchungstyp, EinheitenListe } from "../../../api";
import { useTranslation } from "react-i18next";
import FuhrparkeintragFieldsContent from "./FuhrparkeintragFieldsContent";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteFuhrparkeintragButton from "./DeleteFuhrparkeintragButton";
import React from "react";
import DeleteFuhrparkeintragNewButton from "./DeleteFuhrparkeintragNewButton";

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelle?: any;
  zielAbladestelle?: any;
  quelleBaustelle?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: any;
  abfallart?: any;
}

interface MaschinentransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  baustelleId: string;
  transportiertesFahrzeug: any;
  fuhrTyp: Buchungstyp;
  bemerkung?: string;
  lieferscheinNummer?: string;
  abholortBaustelle?: any;
  abstellortBaustelle?: any;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface ExtendedFuhrparkeintragAnbauteilDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  anbauteil: any;
  anbauteilNutzungsdauer: number;
  anbauteilAnzahl: number;
  anbauteilStundenPreis: number;
  anbauteilKosten: number;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeug: any;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: ExtendedFuhrparkeintragAnbauteilDaten[];
  maschinentransporte: MaschinentransportDaten[];
  materialanfuhr: MaterialTransportDaten[];
  materialabtransport: MaterialTransportDaten[];
  materialtransport: MaterialTransportDaten[];
}

export default function FuhrparkeintragFremdPersonalItem({
  isNew,
  fuhrparkeintrag,
  index,
  expanded,
  handleChange,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  isValidating,
  isValid,
  remove,
  taetigkeitsberichtId,
}: {
  isNew: boolean;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
  index: number;
  expanded: boolean;
  handleChange: (index: number) => void;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  isValidating: boolean;
  isValid: boolean;
  remove: () => void;
  taetigkeitsberichtId: string;
}) {
  const { t } = useTranslation("taetigkeitsbericht");

  return (
    <Accordion
      key={fuhrparkeintrag.id}
      expanded={expanded}
      onChange={() => handleChange(index)}
      sx={{ width: "100%", padding: 2 }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ paddingLeft: 0 }}>
        <Box>{capitalize(t("carpool-entry"))}</Box>

        {/* Right side: Button */}
        {taetigkeitsberichtId === "" ? (
          <DeleteFuhrparkeintragNewButton remove={remove} index={index} />
        ) : (
          <DeleteFuhrparkeintragButton
            remove={remove}
            index={index}
            id={fuhrparkeintrag.id}
            taetigkeitsberichtId={taetigkeitsberichtId}
          />
        )}
      </AccordionSummary>
      {expanded && (
        <AccordionDetails sx={{ p: 0 }}>
          <FuhrparkeintragFieldsContent
            isNew={isNew}
            index={index}
            fahrzeugHerkunft={"FREMD_GERAET"}
            taetigkeitsberichtDatum={taetigkeitsberichtDatum}
            dauerVorhanden={dauerVorhanden}
            isValidating={isValidating}
            isValid={isValid}
            isFremdleistung={true}
            fuhrparkeintrag={fuhrparkeintrag}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
}
