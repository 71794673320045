/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Erzeuger } from '../models/Erzeuger';
import type { ErzeugerDaten } from '../models/ErzeugerDaten';
import type { ErzeugerListe } from '../models/ErzeugerListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ErzeugerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ErzeugerListe Liste der Erzeuger
   * @throws ApiError
   */
  public sucheErzeuger({
    search,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<ErzeugerListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/erzeuger',
      query: {
        'search': search,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Erzeuger der angelegte Erzeuger
   * @throws ApiError
   */
  public createErzeuger({
    requestBody,
  }: {
    requestBody: ErzeugerDaten,
  }): CancelablePromise<Erzeuger> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/erzeuger',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Erzeuger Erzeuger
   * @throws ApiError
   */
  public getErzeuger({
    erzeugerId,
  }: {
    erzeugerId: string,
  }): CancelablePromise<Erzeuger> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/erzeuger/{erzeugerId}',
      path: {
        'erzeugerId': erzeugerId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Erzeuger der aktualisierte Erzeuger
   * @throws ApiError
   */
  public updateErzeuger({
    erzeugerId,
    requestBody,
  }: {
    erzeugerId: string,
    requestBody: ErzeugerDaten,
  }): CancelablePromise<Erzeuger> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/erzeuger/{erzeugerId}',
      path: {
        'erzeugerId': erzeugerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Erzeuger der gelöschte Erzeuger
   * @throws ApiError
   */
  public deleteErzeuger({
    erzeugerId,
  }: {
    erzeugerId: string,
  }): CancelablePromise<Erzeuger> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/erzeuger/{erzeugerId}',
      path: {
        'erzeugerId': erzeugerId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
