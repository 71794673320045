// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abwesenheit, AbwesenheitDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useApproveAbwesenheit(): (
  abwesenheitId: string,
  requestBody: AbwesenheitDaten
) => Promise<Abwesenheit> {
  const getAPI = useGetApi();
  return useCallback(
    async (abwesenheitId, requestBody) =>
      (await getAPI()).abwesenheiten.approveAbwesenheit({
        abwesenheitId,
        requestBody,
      }),
    [getAPI]
  );
}
