// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type AbladestellenListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheAbladestellenQuery = {
  search?: string;
  filtered?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheAbladestellen(): (
  query: SucheAbladestellenQuery
) => Promise<AbladestellenListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) =>
      (await getAPI()).abladestellen.sucheAbladestellen({ ...query }),
    [getAPI]
  );
}
