import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  CircularProgress,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useGetAccessToken } from "../../client";
import { useQuery } from "react-query";
import { apiUrl } from "../../config";
import EntsorgungsdokumentationenFieldsContent from "../../components/entsorgungsdokumentationen/EntsorgungsdokumentationenFieldsContent";
import { Baustelle } from "../../api";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function ErstelleEntsorgungsdokumentation() {
  const { t } = useTranslation("entsorgungsdokumentation");

  const [baustellenId, setBaustellenId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [preiseAnzeigen, setPreiseAnzeigen] = useState(false);
  const [bemerkungenAnzeigen, setBemerkungenAnzeigen] = useState(false);
  const [
    gewaehlteAbladestellenMaterialien,
    setGewaehlteAbladestellenMaterialien,
  ] = useState<[]>([]);

  const [isGewaehlt, setIsGewaehlt] = useState(false);

  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  const { refetch } = useCreateEntsorgungsdokumentation(
    baustellenId,
    startDate,
    endDate,
    preiseAnzeigen,
    bemerkungenAnzeigen,
    gewaehlteAbladestellenMaterialien
  );

  useEffect(() => {
    setIsGewaehlt(gewaehlteAbladestellenMaterialien.length > 0);
    console.log(gewaehlteAbladestellenMaterialien);
  }, [gewaehlteAbladestellenMaterialien]);

  const handlePreviewClick = async () => {
    if (baustellenId && startDate && endDate) {
      setIsLoadingPreview(true);
      const result = await refetch();
      setIsLoadingPreview(false);
      if (result.data) {
        window.open(result.data, "_blank");
      }
    }
  };

  let schema = yup.object().shape({
    baustelle: yup.object().required(),
    startDatum: yup.string().required(),
    endDatum: yup.string().required(),
    preiseAuswahl: yup.boolean().nullable(),
    bemerkungenAuswahl: yup.boolean().nullable(),
    gewaehlteAbladestellenMaterialien: yup.array(),
  });
  const getAccessToken = useGetAccessToken();
  const onSubmit = useCallback(
    async (
      values: {
        baustelle: any;
        startDatum: string;
        endDatum: string;
        preiseAuswahl: boolean;
        bemerkungenAuswahl: boolean;
        gewaehlteAbladestellenMaterialien: [];
      },
      formikHelpers: FormikHelpers<{
        baustelle: any;
        startDatum: string;
        endDatum: string;
        preiseAuswahl: boolean;
        bemerkungenAuswahl: boolean;
        gewaehlteAbladestellenMaterialien: [];
      }>
    ) => {
      const {
        baustelle,
        startDatum,
        endDatum,
        preiseAuswahl,
        bemerkungenAuswahl,
        gewaehlteAbladestellenMaterialien,
      } = values;
      const { setSubmitting, setStatus } = formikHelpers;
      setSubmitting(true);
      try {
        const baustellenId = (baustelle as Baustelle)?.id ?? "";
        const response = await fetch(
          `${apiUrl}/entsorgungsdokumentation/download`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${await getAccessToken()}`,
            },
            body: JSON.stringify({
              baustelleId: baustellenId,
              startDatum: startDatum,
              endDatum: endDatum,
              preiseAuswahl: preiseAuswahl,
              bemerkungenAuswahl: bemerkungenAuswahl,
              gewaehlteAbladestellenMaterialien:
                gewaehlteAbladestellenMaterialien,
            }),
          }
        );

        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "entsorgungsdokumentation.pdf";
        link.click();
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    []
  );

  return (
    <Layout
      title={capitalize(t("disposal-documentation"))}
      back="/entsorgungsdokumentation"
    >
      <Guard
        permission={"entsorgungsdokumentation:generate"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              baustelle: "",
              startDatum: "",
              endDatum: new Date().toISOString().split("T")[0],
              preiseAuswahl: false,
              bemerkungenAuswahl: false,
              gewaehlteAbladestellenMaterialien: [],
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ handleChange, isSubmitting, isValidating, isValid, dirty }) => {
              const customHandleChange = (e: React.ChangeEvent<any>) => {
                handleChange(e);
                if (e.target.name === "startDatum") {
                  setStartDate(e.target.value);
                } else if (e.target.name === "endDatum") {
                  setEndDate(e.target.value);
                }
              };

              const customHandleChangeBaustelle = (
                selectedBaustellenId: string
              ) => {
                setBaustellenId(selectedBaustellenId);
              };

              const customHandleChangePreisAuswahl = (
                isPreiseAusgewaehlt: boolean
              ) => {
                setPreiseAnzeigen(isPreiseAusgewaehlt);
              };

              const customHandleChangeBemerkungAuswahl = (
                isBemerkungAusgewaehlt: boolean
              ) => {
                setBemerkungenAnzeigen(isBemerkungAusgewaehlt);
              };

              const customHandleChangeGewaehlteAbladestellenMaterialien = (
                auswahlItems: []
              ) => {
                setGewaehlteAbladestellenMaterialien(auswahlItems);
              };

              return (
                <>
                  <Typography variant="h5">
                    {capitalize(t("new-disposal-documentation"))}
                  </Typography>
                  <DialogContent>
                    <Form id="new-entsorgungsdokumentation">
                      <EntsorgungsdokumentationenFieldsContent
                        handleChangeBaustelle={customHandleChangeBaustelle}
                        handleChange={customHandleChange}
                        handleChangePreisAuswahl={
                          customHandleChangePreisAuswahl
                        }
                        handleChangeBemerkungAuswahl={
                          customHandleChangeBemerkungAuswahl
                        }
                        handleChangeGewaehlteAbladestellenMaterialien={
                          customHandleChangeGewaehlteAbladestellenMaterialien
                        }
                      />
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    <SubmitButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isLoadingPreview}
                      disabled={
                        isValidating || !isValid || !dirty || !isGewaehlt
                      }
                      onClick={handlePreviewClick}
                    >
                      {isLoadingPreview ? (
                        <CircularProgress size={24} />
                      ) : (
                        capitalize(t("preview"))
                      )}
                    </SubmitButton>
                    <SubmitButton
                      form="new-entsorgungsdokumentation"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={
                        isValidating || !isValid || !dirty || !isGewaehlt
                      }
                    >
                      {capitalize(t("create"))}
                    </SubmitButton>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}

function useCreateEntsorgungsdokumentation(
  baustellenId: string,
  startDate: string,
  endDate: string,
  preiseAuswahl: boolean,
  bemerkungenAuswahl: boolean,
  gewaehlteAbladestellenMaterialien: []
) {
  const getAccessToken = useGetAccessToken();

  return useQuery(
    [
      "create-entsorgungsdokumentation",
      baustellenId,
      startDate,
      endDate,
      preiseAuswahl,
      bemerkungenAuswahl,
      gewaehlteAbladestellenMaterialien,
    ],
    async () => {
      const response = await fetch(
        `${apiUrl}/entsorgungsdokumentation/download`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${await getAccessToken()}`,
          },
          body: JSON.stringify({
            baustelleId: baustellenId,
            startDatum: startDate,
            endDatum: endDate,
            preiseAuswahl: preiseAuswahl,
            bemerkungenAuswahl: bemerkungenAuswahl,
            gewaehlteAbladestellenMaterialien:
              gewaehlteAbladestellenMaterialien,
          }),
        }
      );

      const blob = await response.blob();
      return window.URL.createObjectURL(blob);
    },
    {
      enabled: false,
      suspense: false,
      cacheTime: 0,
    }
  );
}
