import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useMutation, useQueryClient } from "react-query";
import { useUpdateAusgangsrechnung } from "../../client/hooks";
import { AusgangsrechnungDaten, Baustelle } from "../../api";
import { useAusgangsrechnung } from "../../client/queries";
import DeleteIcon from "@mui/icons-material/Delete";
import { useT } from "../../i18n";
import DeleteAusgangsrechnungButton from "../../components/ausgangsrechnungen/DeleteAusgangsrechnungButton";
import AusgangsrechnungenFieldsContent from "../../components/ausgangsrechnungen/AusgangsrechnungenFieldsContent";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function DetailsAusgangsrechnung() {
  const { t } = useT("ausgangsrechnung");
  const { id: rechnungId = "" } = useParams<{
    id: string;
  }>();
  const { mutateAsync } = useSaveAusgangsrechnung(rechnungId);
  let schema = yup.object().shape({
    baustelle: yup.object(),
    kunde: yup.object({ id: yup.string(), name: yup.string() }),
    rechnungsNummer: yup.string(),
    buchungsart: yup.string(),
    bemerkung: yup.string(),
    lfsNr: yup.string(),
    faelligkeitsDatum: yup.string(),
    gesamtPreis: yup.string().test("is-decimal", "Invalid saldo", (value) => {
      if (!value) return true; // Allow empty value
      const parsedValue = parseFloat(value.replace(",", "."));
      return !isNaN(parsedValue) && parsedValue >= 0;
    }),
  });
  const ausgangsrechnung = useAusgangsrechnung(rechnungId);
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        faelligkeitsDatum: string;
        rechnungsNummer: string;
        buchungsart: string;
        bemerkung: string;
        lfsNr: string;
        baustelle: any;
        kunde: { id: string };
        gesamtPreis: string;
      },
      formikHelpers: FormikHelpers<{
        faelligkeitsDatum: string;
        rechnungsNummer: string;
        buchungsart: string;
        bemerkung: string;
        lfsNr: string;
        baustelle: any;
        kunde: { id: string; name: string };
        gesamtPreis: string;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      let gesamtPreis = parseFloat(
        values.gesamtPreis.replace(/\./g, "").replace(",", ".")
      );
      gesamtPreis = isNaN(gesamtPreis) ? 0.0 : gesamtPreis;

      let datum = values.faelligkeitsDatum
        ? values.faelligkeitsDatum + "T00:00:00Z"
        : "";
      const ausgangsrechnung = await mutateAsync({
        baustellenId:
          typeof values.baustelle === "string"
            ? values.baustelle
            : (values.baustelle as Baustelle)?.id ?? "",
        kundenId: values.kunde?.id,
        gesamtPreis: gesamtPreis,
        rechnungsNummer: values.rechnungsNummer,
        buchungsart: values.buchungsart,
        faelligkeitsDatum: datum,
        lfsNr: values.lfsNr,
        bemerkung: values.bemerkung,
        art: "AR",
      });
      navigate(`/ausgangsrechnungen`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split("T")[0];
  };
  const formatNumber = (value: number | undefined) => {
    if (value == null || isNaN(value)) return "";

    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  return (
    <Layout title={t("outgoing-invoice")} back="/ausgangsrechnungen">
      <Guard
        permission={"ausgangsrechnung:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          <Guard permission={"ausgangsrechnung:delete"}>
            <DeleteAusgangsrechnungButton
              id={rechnungId}
            ></DeleteAusgangsrechnungButton>
          </Guard>
          <Formik
            initialValues={{
              faelligkeitsDatum:
                ausgangsrechnung.faelligkeitsDatum?.split("T")[0] ??
                getCurrentDate(),
              rechnungsNummer: ausgangsrechnung.rechnungsNummer ?? "",
              baustelle: ausgangsrechnung.baustellenId ?? "",
              lfsNr: ausgangsrechnung.lfsNr ?? "",
              kunde: {
                id: ausgangsrechnung.kundenId ?? "",
                name: ausgangsrechnung.kundenName ?? "",
              },
              gesamtPreis: ausgangsrechnung.gesamtPreis
                ? formatNumber(ausgangsrechnung.gesamtPreis)
                : "",
              buchungsart: ausgangsrechnung.buchungsart ?? "Ertrag",
              bemerkung: ausgangsrechnung.bemerkung ?? "",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">
                  {ausgangsrechnung.geloeschtAm === undefined ? (
                    t("outgoing-invoice")
                  ) : (
                    <>
                      <>{t("outgoing-invoice")}</>
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={t("deleted")}
                        color="error"
                      />
                    </>
                  )}
                </Typography>
                <DialogContent>
                  <Form id="new-ausgangsrechnung">
                    <AusgangsrechnungenFieldsContent
                      buchungsart={ausgangsrechnung.buchungsart ?? "Ertrag"}
                    />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-ausgangsrechnung"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveAusgangsrechnung(rechnungId: string) {
  const updateAusgangsrechnung = useUpdateAusgangsrechnung();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AusgangsrechnungDaten) => {
      return updateAusgangsrechnung(rechnungId, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["ausgangsrechnungen"]);
      },
    }
  );
}
