import { Box, capitalize, Stack } from "@mui/material";
import { Field } from "formik";
import { useT } from "../../i18n";
import { TextField } from "formik-mui";
import { useState } from "react";

export default function BetriebstagebuecherFieldsContent({
  handleChange,
}: {
  handleChange: (e: React.ChangeEvent<any>) => void;
}) {
  const { t } = useT("betriebstagebuch");

  const [startDatum, setStartDatum] = useState("");
  const [endDatum, setEndDatum] = useState("");

  const handleStartDatumChange = (e: React.ChangeEvent<any>) => {
    setStartDatum(e.target.value);
    handleChange(e);
  };

  const handleEndDatumChange = (e: React.ChangeEvent<any>) => {
    setEndDatum(e.target.value);
    handleChange(e);
  };

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field
          component={TextField}
          name="startDatum"
          label={capitalize(t("start-date"))}
          type={"date"}
          InputLabelProps={{ shrink: true }}
          required
          onChange={handleStartDatumChange}
          inputProps={{ max: endDatum }}
        />
        <Field
          component={TextField}
          name="endDatum"
          label={capitalize(t("end-date"))}
          type={"date"}
          InputLabelProps={{ shrink: true }}
          required
          onChange={handleEndDatumChange}
          inputProps={{ min: startDatum }}
        />
      </Box>
    </Stack>
  );
}
