// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type MaterialKostenPage } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type GetMaterialCostsQuery = {
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useGetMaterialCosts(): (
  materialId: string,
  query: GetMaterialCostsQuery
) => Promise<MaterialKostenPage> {
  const getAPI = useGetApi();
  return useCallback(
    async (materialId, query = {}) =>
      (await getAPI()).material.getMaterialCosts({ materialId, ...query }),
    [getAPI]
  );
}
