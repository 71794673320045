import { capitalize, Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import ZahllaufArchivDataTable from "../../components/zahllauf/ZahllaufArchivDataTable";
import React from "react";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

function ZahllaufArchivListe() {
  const { t } = useT("zahllauf");
  const limit = parseInt(
    localStorage.getItem("zahllaufarchivLimit") ?? "25",
    10
  );

  return (
    <Layout>
      <Guard
        permission={"zahllauf:archiv:list"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Typography variant="h5">
            {capitalize(t("paymentrun-archive"))}
          </Typography>
          <ZahllaufArchivDataTable limit={limit} />
        </Paper>
      </Guard>
    </Layout>
  );
}

export default ZahllaufArchivListe;
