import { capitalize, Paper, Typography, SxProps, Theme } from "@mui/material";
import { useT } from "../../../i18n";
import AnbauteilDataTable from "../../../components/fuhrpark/anbauteil/AnbauteilDataTable";
import ZugewieseneAnbauteileDataTable from "../../../components/fuhrpark/anbauteil/ZugewieseneAnbauteileDataTable";
import ErrorAlert from "../../../components/ErrorAlert";
import { notPermitted } from "../../../errors";
import React from "react";
import Guard from "../../../components/Guard";

interface ZugewieseneAnbauteileProps {
  sx?: SxProps<Theme>;
}

export default function ZugewieseneAnbauteile({
  sx,
}: ZugewieseneAnbauteileProps) {
  const { t } = useT("fuhrpark");
  const limit = parseInt(
    localStorage.getItem("zugewieseneAnbauteileLimit") ?? "25",
    10
  );
  return (
    <Guard permission={"fuhrpark:list"}>
      <Paper elevation={1} sx={{ padding: 3, ...sx }}>
        <Typography variant="h5">
          {capitalize(t("attachment-parts-assigned"))}
        </Typography>
        <ZugewieseneAnbauteileDataTable limit={limit} />
      </Paper>
    </Guard>
  );
}
