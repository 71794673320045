import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../../components/Layout";
import {
  Box,
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import FahrzeugFieldsContent from "../../../components/fuhrpark/fahrzeug/FahrzeugFieldsContent";
import { useUpdateFahrzeug } from "../../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import {
  checkFahrzeugMessage,
  FahrzeugDaten,
  FahrzeugGruppe,
  HerkunftTyp,
} from "../../../api";
import { useFahrzeug } from "../../../client/queries";
import Anbauteile from "../anbauteil/Anbauteile";
import ZugewieseneAnbauteile from "./ZugewieseneAnbauteile";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteFahrzeugButton from "../../../components/fuhrpark/fahrzeug/DeleteFahrzeugButton";
import { apiUrl } from "../../../config";
import ErrorAlert from "../../../components/ErrorAlert";
import { notPermitted } from "../../../errors";
import Guard from "../../../components/Guard";

export default function FahrzeugDetails() {
  const { id: fahrzeugId = "" } = useParams<{
    id: string;
  }>();
  const { t } = useTranslation("fuhrpark");
  const fahrzeug = useFahrzeug(fahrzeugId);
  const { mutateAsync } = useSaveFahrzeug(fahrzeugId);

  let schema = yup.object().shape({
    gruppe: yup.string().required(capitalize(t("vehicle-required-group"))),
    herkunft: yup.string().required(capitalize(t("vehicle-required-origin"))),
    fabrikant: yup.string().when("herkunft", {
      is: "EIGEN_GERAET",
      then: (schema) => schema.required(capitalize(t("vehicle-required-make"))),
    }),
    fahrzeugTyp: yup.string().when("herkunft", {
      is: "EIGEN_GERAET",
      then: (schema) => schema.required(capitalize(t("vehicle-required-type"))),
    }),
    laufendeNummer: yup.string(),
    kennzeichen: yup
      .string()
      .required(capitalize(t("vehicle-required-mark")))
      .test(
        "is-unique",
        capitalize(t("vehicle-mark-already-exists")),
        async (value) => {
          if (value === fahrzeug.kennzeichen) {
            return true;
          } else {
            const response = await fetch(`${apiUrl}/fahrzeuge-check`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ kennzeichen: value }),
            });

            if (!response.ok) {
              throw new Error(response.statusText);
            }
            const fahrzeuge: checkFahrzeugMessage = await response.json();
            return fahrzeuge.check === undefined || fahrzeuge.check;
          }
        }
      ),
    angeschafft: yup.string(),
    breite: yup.number().min(0).nullable(),
    tiefe: yup.number().min(0).nullable(),
    hoehe: yup.number().min(0).nullable(),
    gesamtMasse: yup.number().min(0).nullable(),
    lautstaerke: yup.number().min(0).nullable(),
    kostenStundeAktuell: yup
      .number()
      .min(0)
      .required(capitalize(t("vehicle-required-cost-hour"))),
    ladeMenge: yup.number().min(0).nullable(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        gruppe: FahrzeugGruppe;
        herkunft: HerkunftTyp;
        fabrikant: string;
        fahrzeugTyp: string;
        laufendeNummer: string;
        kennzeichen: string;
        angeschafft: string;
        breite: number;
        tiefe: number;
        hoehe: number;
        gesamtMasse: number;
        lautstaerke: number;
        kostenStundeAktuell: number;
        ladeMenge: number;
      },
      formikHelpers: FormikHelpers<{
        gruppe: FahrzeugGruppe;
        herkunft: HerkunftTyp;
        fabrikant: string;
        fahrzeugTyp: string;
        laufendeNummer: string;
        kennzeichen: string;
        angeschafft: string;
        breite: number;
        tiefe: number;
        hoehe: number;
        gesamtMasse: number;
        lautstaerke: number;
        kostenStundeAktuell: number;
        ladeMenge: number;
      }>
    ) => {
      const angeschafft = values.angeschafft
        ? values.angeschafft + "T00:00:00.000Z"
        : undefined;
      const updatedFahrzeug = await mutateAsync({
        gruppe: values.gruppe,
        herkunft: values.herkunft,
        fabrikant: values.fabrikant,
        fahrzeugTyp: values.fahrzeugTyp,
        laufendeNummer: values.laufendeNummer,
        kennzeichen: values.kennzeichen,
        angeschafft: angeschafft,
        breite: values.breite,
        tiefe: values.tiefe,
        hoehe: values.hoehe,
        gesamtMasse: values.gesamtMasse,
        lautstaerke: values.lautstaerke,
        kostenStundeAktuell: values.kostenStundeAktuell,
        ladeMenge: values.ladeMenge,
      });
      const { setSubmitting, setStatus } = formikHelpers;
      navigate(`/fahrzeuge/`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={capitalize(t("vehicle"))} back="/fahrzeuge">
      <Guard
        permission={"fuhrpark:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          {fahrzeug.deleted === undefined ? (
            <Guard permission={"fuhrpark:delete"}>
              <DeleteFahrzeugButton id={fahrzeugId}></DeleteFahrzeugButton>
            </Guard>
          ) : (
            <></>
          )}
          <Formik
            enableReinitialize={true}
            initialValues={{
              gruppe: fahrzeug?.gruppe ?? "",
              herkunft: fahrzeug?.herkunft ?? "",
              fabrikant: fahrzeug?.fabrikant ?? "",
              fahrzeugTyp: fahrzeug?.fahrzeugTyp ?? "",
              laufendeNummer: fahrzeug?.laufendeNummer ?? "",
              kennzeichen: fahrzeug?.kennzeichen ?? "",
              angeschafft: fahrzeug?.angeschafft?.split("T")[0] ?? "",
              breite: fahrzeug?.breite ?? 0,
              tiefe: fahrzeug?.tiefe ?? 0,
              hoehe: fahrzeug?.hoehe ?? 0,
              gesamtMasse: fahrzeug?.gesamtMasse ?? 0,
              lautstaerke: fahrzeug?.lautstaerke ?? 0,
              kostenStundeAktuell: fahrzeug?.kostenStundeAktuell ?? 0,
              ladeMenge: fahrzeug?.ladeMenge ?? 0,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">
                  {fahrzeug.gruppe +
                    " | " +
                    fahrzeug.fahrzeugTyp +
                    (fahrzeug.gruppe === "PKW" || fahrzeug.gruppe === "LKW"
                      ? " | " + fahrzeug.kennzeichen
                      : "")}
                  {fahrzeug.deleted === undefined ? (
                    ""
                  ) : (
                    <Chip
                      size="small"
                      icon={<DeleteIcon />}
                      label={"gelöscht"}
                      color="error"
                    />
                  )}
                </Typography>
                <Typography variant="h5"></Typography>
                <DialogContent>
                  <Form id="details-fahrzeug">
                    <FahrzeugFieldsContent
                      isNew={false}
                      fahrzeugId={fahrzeug.id}
                    />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="details-fahrzeug"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
          <Box display="flex" sx={{ gap: 2, marginTop: 2, width: "100%" }}>
            <Anbauteile sx={{ flex: 2 }} />
            <ZugewieseneAnbauteile sx={{ flex: 3 }} />
          </Box>
        </Paper>
      </Guard>
    </Layout>
  );
}

function useSaveFahrzeug(id: string) {
  const updateFahrzeug = useUpdateFahrzeug();
  const queryClient = useQueryClient();

  return useMutation(
    (input: FahrzeugDaten) => {
      return updateFahrzeug(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["fahrzeuge", id]);
      },
    }
  );
}
