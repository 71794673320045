// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abwesenheit, type ApiError } from "../../api";
import { useGetAbwesenheit as useGetter } from "../hooks";
import { useQuery, type UseQueryOptions } from "react-query";

export function useAbwesenheit(
  abwesenheitId: string,
  options: Omit<
    UseQueryOptions<Abwesenheit, ApiError>,
    "queryKey" | "queryFn"
  > = {}
): Abwesenheit {
  const getter = useGetter();
  return useQuery(
    ["abwesenheiten", abwesenheitId],
    () => getter(abwesenheitId),
    options
  ).data!;
}
