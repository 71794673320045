import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  ApiError,
  ZugewiesenesFahrzeugAnbauteil,
  ZugewiesenesFahrzeugAnbauteilPage,
} from "../../../api";
import {
  useGetZugewiesenesFahrzeugAnbauteil,
  useSucheZugewieseneFahrzeugAnbauteile,
} from "../../../client/hooks";
import { ZugewiesenesAnbauteil } from "./ZugewieseneAnbauteileDataTable";

interface ExtendedZugewiesenesAnbauteil extends ZugewiesenesAnbauteil {
  geraeteBezeichnung: string;
}

interface ZugewieseneAnbauteileSelectProps extends FieldProps {
  fahrzeugId: string;
  label: string;
  required?: boolean;
}

function ZugewieseneAnbauteileSelect({
  fahrzeugId,
  label,
  required,
  field,
  form,
  meta,
}: ZugewieseneAnbauteileSelectProps) {
  const searchZugewieseneAnbauteile = useSucheZugewieseneFahrzeugAnbauteile();
  const ladeZugewiesenesAnbauteil = useGetZugewiesenesFahrzeugAnbauteil();
  const [optionen, setOptionen] = useState<
    ReadonlyArray<ZugewiesenesFahrzeugAnbauteil>
  >([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeZugewiesenesAnbauteil(fahrzeugId, field.value)
        .then((zugewiesenesAnbauteil) => {
          form.setFieldValue(field.name, zugewiesenesAnbauteil);
          console.log(zugewiesenesAnbauteil);
          setInputValue(zugewiesenesAnbauteil);
        })
        .catch((error) => {
          console.error("Error loading Zugewiesenes Anbauteil:", error);
        });
    }
  }, [field.value]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (
            error?: ApiError,
            results?: ZugewiesenesFahrzeugAnbauteilPage
          ) => void
        ) =>
          searchZugewieseneAnbauteile(fahrzeugId, {
            search: request.input,
            page: 0,
            limit: 20,
            sort: "created",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchZugewieseneAnbauteile]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
    });
  }, [searchValue, ladeOptionen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | ExtendedZugewiesenesAnbauteil) => {
        if (typeof option === "string") {
          return "";
        } else {
          return option.geraeteBezeichnung;
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: ExtendedZugewiesenesAnbauteil) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={option.geraeteBezeichnung}
              secondary={[option.geraeteBezeichnung, option.kostenStunde]
                .filter(Boolean)
                .join(", ")}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default ZugewieseneAnbauteileSelect;
