import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useCreateBaustelle } from "../../client/hooks";
import { BaustelleDaten } from "../../api";
import BaustellenFieldsContent from "../../components/baustellen/BaustellenFieldsContent";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function ErstelleBaustelle() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveBaustelle();
  let schema = yup.object().shape({
    bezeichnung: yup.string(),
    name: yup.string().required(),
    plz: yup.string(),
    strasse: yup.string(),
    ort: yup.string().required(),
    telefon: yup.string(),
    email: yup.string(),
    kunde: yup.object({ id: yup.string() }).nullable(),
    auftragsSumme: yup
      .string()
      .test("is-decimal", "invalid auftragssumme", (value) => {
        if (!value) return true; // Allow empty value
        const parsedValue = parseFloat(value.replace(",", "."));
        return !isNaN(parsedValue) && parsedValue >= 0;
      }),
    saldo: yup.string().test("is-decimal", "Invalid saldo", (value) => {
      if (!value) return true; // Allow empty value
      const parsedValue = parseFloat(value.replace(",", "."));
      return !isNaN(parsedValue) && parsedValue >= 0;
    }),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        bezeichnung: string;
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        auftragsSumme: string;
        saldo: string;
        kunde: { id: string; name: string };
      },
      formikHelpers: FormikHelpers<{
        bezeichnung: string;
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        auftragsSumme: string;
        saldo: string;
        kunde: { id: string; name: string };
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      let auftragsSumme = values.auftragsSumme
        ? parseFloat(values.auftragsSumme.replace(/\./g, "").replace(",", "."))
        : 0.0;
      let saldo = values.saldo
        ? parseFloat(values.saldo.replace(/\./g, "").replace(",", "."))
        : 0.0;
      const formatNumber = (value: number | undefined) => {
        if (value == null || isNaN(value)) return "";

        return value
          .toFixed(2)
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };
      const baustellen = await mutateAsync({
        name: values.name,
        strasse: values.strasse,
        plz: values.plz,
        telefon: values.telefon,
        email: values.email,
        ort: values.ort,
        erstAuftragsSumme: auftragsSumme,
        saldo: saldo,
        kundenId: values.kunde?.id,
        bezeichnung: values.bezeichnung,
      });
      navigate(`/baustellen`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={t("construction-site-creation-form")} back="/baustellen">
      <Guard
        permission={"baustelle:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              bezeichnung: "",
              name: "",
              plz: "",
              strasse: "",
              ort: "",
              telefon: "",
              email: "",
              auftragsSumme: "",
              saldo: "",
              kunde: { id: "", name: "" },
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">
                  {t("construction-site_one")}
                </Typography>
                <DialogContent>
                  <Form id="new-baustelle">
                    <BaustellenFieldsContent isNew={true} />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-baustelle"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveBaustelle() {
  const createBaustelle = useCreateBaustelle();
  const queryClient = useQueryClient();

  return useMutation(
    (input: BaustelleDaten) => {
      return createBaustelle(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["baustellen"]);
      },
    }
  );
}
