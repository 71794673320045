import { useMemo, type PropsWithChildren, type ReactNode } from "react";
import { usePermissions } from "./PermissionsContext";

export type Expression = {
  permissions: string[];
  fallback?: ReactNode;
};

export type Props = PropsWithChildren<Expression>;

function MultipleGuard({ children, fallback, ...props }: Props) {
  const permitted = usePermitted(props);
  return permitted ? <>{children}</> : <>{fallback}</> ?? null;
}

export function usePermitted({
  permissions: requiredPermissions,
}: Expression): boolean {
  const userPermissions = usePermissions();

  return useMemo(
    () =>
      requiredPermissions.length > 0 &&
      requiredPermissions.some((permission) =>
        userPermissions.includes(permission)
      ),
    [userPermissions, requiredPermissions]
  );
}

export default MultipleGuard;
