import {
  AppBar,
  Badge,
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { ExpandContextProvider } from "../expand";
import React, { useEffect, useState } from "react";
import { NavButton } from "./NavButton";
import { DashboardIcon } from "./icons";
import { useT } from "../i18n";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CommuteIcon from "@mui/icons-material/Commute";
import FoundationIcon from "@mui/icons-material/Foundation";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import EggIcon from "@mui/icons-material/Egg";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Guard from "./Guard";
import { apiUrl, applicationVersion, applicationVersionTime } from "../config";
import HardwareIcon from "@mui/icons-material/Hardware";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DescriptionIcon from "@mui/icons-material/Description";
import CableIcon from "@mui/icons-material/Cable";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import Logo_white from "./Logo_white";
import { useGetEingangsrechnungenInPruefungBadge } from "../client/hooks";
import MultipleGuard from "./MultipleGuard";

export function MainMenu({ close }: { close: () => void }) {
  const { t: t2, capitalize: capitalize2 } = useT("taetigkeitsbericht");
  const getEingangsrechnungenBadge = useGetEingangsrechnungenInPruefungBadge();
  const [badgeCount, setBadgeCount] = useState(0);
  const [expanded, setExpanded] = useState(() => {
    const savedState = localStorage.getItem("stammdaten-expanded");
    return savedState !== null ? JSON.parse(savedState) : true;
  });
  const [expandedZeiterfassung, setExpandedZeiterfassung] = useState(() => {
    const savedState = localStorage.getItem("zeiterfassung-expanded");
    return savedState !== null ? JSON.parse(savedState) : true;
  });
  const [expandedZahllaeufe, setExpandedZahllaeufe] = useState(() => {
    const savedState = localStorage.getItem("zahllaeufe-expanded");
    return savedState !== null ? JSON.parse(savedState) : true;
  });
  const [expandedReport, setExpandedReport] = useState(() => {
    const savedState = localStorage.getItem("bericht-expanded");
    return savedState !== null ? JSON.parse(savedState) : true;
  });
  const [expandedDokumentationen, setExpandedDokumentationen] = useState(() => {
    const savedState = localStorage.getItem("dokumentationen-expanded");
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  const [expandedAnAbwesenheiten, setExpandedAnAbwesenheiten] = useState(() => {
    const savedState = localStorage.getItem("anAbwesenheiten-expanded");
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    localStorage.setItem("stammdaten-expanded", JSON.stringify(expanded));
  }, [expanded]);

  useEffect(() => {
    localStorage.setItem(
      "dokumentationen-expanded",
      JSON.stringify(expandedDokumentationen)
    );
  }, [expandedDokumentationen]);

  useEffect(() => {
    localStorage.setItem("bericht-expanded", JSON.stringify(expandedReport));
  }, [expandedReport]);

  useEffect(() => {
    localStorage.setItem(
      "zeiterfassung-expanded",
      JSON.stringify(expandedZeiterfassung)
    );
  }, [expandedZeiterfassung]);

  useEffect(() => {
    localStorage.setItem(
      "anAbwesenheiten-expanded",
      JSON.stringify(expandedAnAbwesenheiten)
    );
  }, [expandedAnAbwesenheiten]);

  useEffect(() => {
    localStorage.setItem(
      "zahllaeufe-expanded",
      JSON.stringify(expandedZahllaeufe)
    );
  }, [expandedZahllaeufe]);

  useEffect(() => {
    const fetchBadgeCount = async () => {
      try {
        const badge = await getEingangsrechnungenBadge(); // Get the badge data
        setBadgeCount(badge.count ?? 0); // Assuming 'count' is the field that contains the count
      } catch (error) {
        console.error("Error fetching badge count:", error);
      }
    };

    fetchBadgeCount(); // Call the async function
  }, [getEingangsrechnungenBadge]);
  return (
    <ExpandContextProvider id="menu" store>
      <Box
        sx={{
          bgcolor: "grey.50",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ".MuiListItemIcon-root": {
            color: "white",
          },
          ".MuiListItemText-primary": {
            color: "white",
          },
          ".MuiListItemSecondaryAction-root": {
            color: "grey.500",
          },
          ".MuiListItemButton-root": {
            "&.Mui-selected": {
              bgcolor: "grey.300",
              ".MuiListItemIcon-root": {
                color: "primary.900",
              },
              ".MuiListItemText-primary": {
                color: "primary.900",
                fontWeight: 500,
              },
              "&:hover": {
                bgcolor: "grey.300",
              },
            },
            "&:hover": {
              bgcolor: "grey.200",
              ".MuiListItemIcon-root": {
                color: "primary.900",
              },
              ".MuiListItemText-primary": {
                color: "primary.900",
              },
            },
          },
        }}
      >
        <AppBar
          position="static"
          color="primary"
          elevation={0}
          sx={{ bgcolor: "primary.900", color: "common.white" }}
        >
          <Toolbar sx={{ gap: 2 }}>
            <Box
              component={Logo_white}
              sx={{ color: "primary.100", fontSize: "2rem", width: "550px" }}
            />
          </Toolbar>
          <Box sx={{ flex: 1 }}>
            <List>
              <NavButton href="/dashboard" onClick={close}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </NavButton>
              <Guard permission={"eingangsrechnung:list"}>
                <NavButton href="/eingangsrechnungen" onClick={close}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Eingangsrechnungen"} />
                  <Badge badgeContent={badgeCount} color={"warning"}></Badge>
                </NavButton>
              </Guard>
              <Zahllaeufe
                close={close}
                expanded={expandedZahllaeufe}
                setExpanded={setExpandedZahllaeufe}
              />
              <Guard permission={"ausgangsrechnung:list"}>
                <NavButton href="/ausgangsrechnungen" onClick={close}>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Ausgangsrechnungen"} />
                </NavButton>
              </Guard>
              <Guard permission={"baustelle:list"}>
                <NavButton href="/baustellen" onClick={close}>
                  <ListItemIcon>
                    <FoundationIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Baustellen"} />
                </NavButton>
              </Guard>
              <Zeiterfassung
                close={close}
                expanded={expandedZeiterfassung}
                setExpanded={setExpandedZeiterfassung}
              />
              <AnAbwesenheiten
                close={close}
                expanded={expandedAnAbwesenheiten}
                setExpanded={setExpandedAnAbwesenheiten}
              />
              <Taetigkeitsberichte
                close={close}
                expanded={expandedReport}
                setExpanded={setExpandedReport}
              />
              <Dokumentationen
                expanded={expandedDokumentationen}
                close={close}
                setExpanded={setExpandedDokumentationen}
              />
              <Stammdaten
                close={close}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            </List>
          </Box>
        </AppBar>
        {apiUrl.includes("localhost") ||
        apiUrl.includes("test") ||
        apiUrl.includes("staging") ? (
          <List>
            <Divider />
            <ListItem>
              <ListItemText
                inset
                primary={<>Version {applicationVersion}</>}
                secondary={<>{applicationVersionTime}</>}
                primaryTypographyProps={{
                  variant: "caption",
                  style: { color: "black", fontSize: "11pt" },
                }}
              />
            </ListItem>
          </List>
        ) : (
          <></>
        )}
      </Box>
    </ExpandContextProvider>
  );
}

function Stammdaten({
  close,
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  close: () => void;
  setExpanded: (e: any) => void;
}) {
  const { t: t1, capitalize: capitalize1 } = useT("fuhrpark");
  const { t: t3 } = useT("abladestelle");
  const { t: t2, capitalize: capitalize2 } = useT("taetigkeitsbericht");
  return (
    <>
      <MultipleGuard
        permissions={[
          "kunde:list",
          "lieferant:list",
          "fuhrpark:list",
          "abfallart:list",
          "abladestelle:list",
          "erzeuger:list",
          "entsorger:list",
          "anwesenheit:list",
          "abwesenheit:list",
          "benutzer:list",
          "systemextern:list",
        ]}
      >
        <ListItemButton onClick={() => setExpanded((e: any) => !e)}>
          <ListItemIcon>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary="Stammdaten" />
        </ListItemButton>
      </MultipleGuard>
      <Collapse in={expanded}>
        <List component="div" disablePadding>
          <Guard permission={"kunde:list"}>
            <NavButton href="/kunden" onClick={close} indent={true}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={"Kunden"} />
            </NavButton>
          </Guard>
          <Guard permission={"lieferant:list"}>
            <NavButton href="/lieferanten" onClick={close} indent={true}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={"Lieferanten"} />
            </NavButton>
          </Guard>
          <Guard permission={"fuhrpark:list"}>
            <NavButton href="/fahrzeuge" indent={true}>
              <ListItemIcon>
                <CommuteIcon />
              </ListItemIcon>
              <ListItemText primary={capitalize1(t1("fuhrpark"))} />
            </NavButton>
          </Guard>
          <Guard permission={"abfallart:list"}>
            <NavButton href="/abfallarten" onClick={close} indent={true}>
              <ListItemIcon>
                <CleaningServicesIcon />
              </ListItemIcon>
              <ListItemText primary={"Abfallarten"} />
            </NavButton>
          </Guard>
          <Guard permission={"abladestelle:list"}>
            <NavButton href="/abladestellen" onClick={close} indent={true}>
              <ListItemIcon>
                <HardwareIcon />
              </ListItemIcon>
              <ListItemText primary={t3("landfill-and-unloading-point")} />
            </NavButton>
          </Guard>
          <Guard permission={"erzeuger:list"}>
            <NavButton href="/erzeuger" onClick={close} indent={true}>
              <ListItemIcon>
                <EggIcon />
              </ListItemIcon>
              <ListItemText primary={"Erzeuger"} />
            </NavButton>
          </Guard>
          <Guard permission={"entsorger:list"}>
            <NavButton href="/entsorger" onClick={close} indent={true}>
              <ListItemIcon>
                <FolderDeleteIcon />
              </ListItemIcon>
              <ListItemText primary={"Entsorger"} />
            </NavButton>
          </Guard>
          <Guard permission={"benutzer:list"}>
            <NavButton href="/benutzer" onClick={close} indent={true}>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary={"Benutzer"} />
            </NavButton>
          </Guard>
          <Guard permission={"systemextern:list"}>
            <NavButton href="/externesysteme" onClick={close} indent={true}>
              <ListItemIcon>
                <CableIcon />
              </ListItemIcon>
              <ListItemText primary={"Externe Systeme"} />
            </NavButton>
          </Guard>
        </List>
      </Collapse>
    </>
  );
}

function Taetigkeitsberichte({
  close,
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  close: () => void;
  setExpanded: (e: any) => void;
}) {
  const { t, capitalize } = useT("taetigkeitsbericht");
  return (
    <>
      <MultipleGuard permissions={["taetigkeitsbericht:list"]}>
        <ListItemButton onClick={() => setExpanded((e: any) => !e)}>
          <ListItemIcon>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary={capitalize(t("activity-reports"))} />
        </ListItemButton>
      </MultipleGuard>
      <Collapse in={expanded}>
        <List component="div" disablePadding>
          <Guard permission={"taetigkeitsbericht:list"}>
            <NavButton
              href="/taetigkeitsberichte"
              onClick={close}
              indent={true}
            >
              <ListItemIcon>
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText primary={capitalize(t("activity-reports"))} />
            </NavButton>
          </Guard>
          <Guard permission={"taetigkeitsbericht:list"}>
            <NavButton href="/fremdleistungen" onClick={close} indent={true}>
              <ListItemIcon>
                <MapsHomeWorkIcon />
              </ListItemIcon>
              <ListItemText
                primary={capitalize(t("activity-reports-external"))}
              />
            </NavButton>
          </Guard>
        </List>
      </Collapse>
    </>
  );
}

function Zeiterfassung({
  close,
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  close: () => void;
  setExpanded: (e: any) => void;
}) {
  const { t, capitalize } = useT("taetigkeitsbericht");
  return (
    <>
      <MultipleGuard
        permissions={["meine:anwesenheit:list", "meine:abwesenheit:list"]}
      >
        <ListItemButton onClick={() => setExpanded((e: any) => !e)}>
          <ListItemIcon>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary={"Zeiterfassung"} />
        </ListItemButton>
        <Collapse in={expanded}>
          <List component="div" disablePadding>
            <Guard permission={"meine:anwesenheit:list"}>
              <NavButton
                href="/meine-anwesenheiten"
                onClick={close}
                indent={true}
              >
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary={"Meine Anwesenheit"} />
              </NavButton>
            </Guard>
            <Guard permission={"meine:abwesenheit:list"}>
              <NavButton
                href="/meine-abwesenheit"
                onClick={close}
                indent={true}
              >
                <ListItemIcon>
                  <BeachAccessIcon />
                </ListItemIcon>
                <ListItemText primary={"Meine Abwesenheiten"} />
              </NavButton>
            </Guard>
          </List>
        </Collapse>
      </MultipleGuard>
    </>
  );
}

function Zahllaeufe({
  close,
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  close: () => void;
  setExpanded: (e: any) => void;
}) {
  const { t, capitalize } = useT("taetigkeitsbericht");
  return (
    <>
      <MultipleGuard permissions={["zahllauf:list", "zahllauf:archiv:list"]}>
        <ListItemButton onClick={() => setExpanded((e: any) => !e)}>
          <ListItemIcon>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary={"Zahlläufe"} />
        </ListItemButton>
        <Collapse in={expanded}>
          <List component="div" disablePadding>
            <Guard permission={"zahllauf:list"}>
              <NavButton href="/zahllaeufe" onClick={close} indent={true}>
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary={"Zahlläufe"} />
              </NavButton>
            </Guard>
            <Guard permission={"zahllauf:archiv:list"}>
              <NavButton href="/zahllaufarchiv" onClick={close} indent={true}>
                <ListItemIcon>
                  <SendAndArchiveIcon />
                </ListItemIcon>
                <ListItemText primary={"Archiv"} />
              </NavButton>
            </Guard>
          </List>
        </Collapse>
      </MultipleGuard>
    </>
  );
}

function Dokumentationen({
  close,
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  close: () => void;
  setExpanded: (e: any) => void;
}) {
  return (
    <>
      <MultipleGuard
        permissions={[
          "betriebsdokumentation:generate",
          "entsorgungsdokumentation:generate",
        ]}
      >
        <ListItemButton onClick={() => setExpanded((e: any) => !e)}>
          <ListItemIcon>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary="Dokumentationen" />
        </ListItemButton>
      </MultipleGuard>
      <Collapse in={expanded}>
        <List component="div" disablePadding>
          <Guard permission={"betriebsdokumentation:generate"}>
            <NavButton href="/betriebstagebuch" onClick={close} indent={true}>
              <ListItemIcon>
                <NoteAltIcon />
              </ListItemIcon>
              <ListItemText primary={"Betriebstagebuch"} />
            </NavButton>
          </Guard>
          <Guard permission={"entsorgungsdokumentation:generate"}>
            <NavButton
              href="/entsorgungsdokumentation"
              onClick={close}
              indent={true}
            >
              <ListItemIcon>
                <DeleteSweepIcon />
              </ListItemIcon>
              <ListItemText primary={"Entsorgungsdokumentation"} />
            </NavButton>
          </Guard>
        </List>
      </Collapse>
    </>
  );
}

function AnAbwesenheiten({
  close,
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  close: () => void;
  setExpanded: (e: any) => void;
}) {
  return (
    <MultipleGuard permissions={["anwesenheit:list", "abwesenheit:list"]}>
      <ListItemButton onClick={() => setExpanded((e: any) => !e)}>
        <ListItemIcon>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText primary={"An- & Abwesenheiten"} />
      </ListItemButton>
      <Collapse in={expanded}>
        <List component="div" disablePadding>
          <Guard permission={"anwesenheit:list"}>
            <NavButton href="/anwesenheiten" onClick={close} indent={true}>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={"Alle Anwesenheiten"} />
            </NavButton>
          </Guard>
          <Guard permission={"abwesenheit:list"}>
            <NavButton href="/abwesenheit" onClick={close} indent={true}>
              <ListItemIcon>
                <BeachAccessIcon />
              </ListItemIcon>
              <ListItemText primary={"Alle Abwesenheiten"} />
            </NavButton>
          </Guard>
        </List>
      </Collapse>
    </MultipleGuard>
  );
}
