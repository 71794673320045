// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Personaleintrag, PersonaleintragDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreatePersonaleintrag(): (
  taetigkeitsberichtId: string,
  requestBody: PersonaleintragDaten
) => Promise<Personaleintrag> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, requestBody) =>
      (await getAPI()).personaleintraege.createPersonaleintrag({
        taetigkeitsberichtId,
        requestBody,
      }),
    [getAPI]
  );
}
