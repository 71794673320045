// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Zahllauf, ZahllaufDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateZahllauf(): (
  zahllaufId: string,
  requestBody: ZahllaufDaten
) => Promise<Zahllauf> {
  const getAPI = useGetApi();
  return useCallback(
    async (zahllaufId, requestBody) =>
      (await getAPI()).zahllaeufe.updateZahllauf({ zahllaufId, requestBody }),
    [getAPI]
  );
}
