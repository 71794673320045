// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type MaterialtransportBemerkungListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheMaterialtransportBemerkungenQuery = {
  zielbaustelleId?: string;
  abfallartId?: string;
};

export function useSucheMaterialtransportBemerkungen(): (
  query: SucheMaterialtransportBemerkungenQuery
) => Promise<MaterialtransportBemerkungListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) =>
      (await getAPI()).materialtransporte.sucheMaterialtransportBemerkungen({
        ...query,
      }),
    [getAPI]
  );
}
