import { MaterialKosten, MaterialKostenZeitraum } from "../../../api";
import { useCallback, useEffect, useState } from "react";
import {
  useGetMaterialCosts,
  useUpdateMaterialZeitraum,
} from "../../../client/hooks";
import LocalDate from "../../LocalDate";
import { Button, CircularProgress, IconButton, Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryClient } from "react-query";
import * as yup from "yup";
export default function MaterialKostenEditableRow({
  type,
  gueltig,
  gueltigVon,
  gueltigBis,
  eintrag,
}: {
  type: string;
  gueltig: string;
  gueltigVon: string;
  gueltigBis: string | undefined;
  eintrag: MaterialKosten;
}) {
  const [editing, setEditing] = useState(false);
  const update = useUpdateMaterialZeitraum();
  const existingPeriods = useGetMaterialCosts();
  const [periods, setPeriods] = useState<MaterialKosten[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const queryClient = useQueryClient();
  useEffect(() => {
    const fetchPeriods = async () => {
      if (eintrag.materialId) {
        const materialsData = await existingPeriods(eintrag.materialId, {
          search: "",
        });
        const index = materialsData.items.findIndex(
          (item) => item.id === eintrag.id
        );
        setCurrentIndex(index);
        setPeriods(materialsData.items);
      }
    };
    fetchPeriods();
  }, [eintrag.id, eintrag.materialId, existingPeriods]);
  const onSubmit = useCallback(
    async (values: MaterialKostenZeitraum) => {
      if (eintrag.id) {
        await update(eintrag.id, {
          type: type,
          datum: values.datum + "T00:00:00.000Z",
        });
      }
      await queryClient.invalidateQueries("material-kosten");
      setEditing(false);
    },
    [update, setEditing]
  );

  let schema = yup.object().shape({
    datum: yup.string(),
  });
  return (
    <>
      <Stack direction="row">
        {gueltig ? (
          editing ? (
            <></>
          ) : (
            <>
              <Button
                color="inherit"
                sx={{ fontSize: "1em", fontWeight: "normal" }}
                onClick={() => {
                  setEditing(true);
                }}
              >
                <LocalDate
                  value={gueltig.split("T")[0]}
                  options={{
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  }}
                />
              </Button>
            </>
          )
        ) : (
          <></>
        )}
        {editing ? (
          <Formik
            initialValues={{
              type: "von",
              datum: gueltig.split("T")[0],
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <Stack direction="row">
                  {type === "von" ? (
                    periods[currentIndex + 1] &&
                    periods[currentIndex + 1].gueltigBis ? (
                      <Field
                        name="datum"
                        component={TextField}
                        type="date"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          max: gueltigBis?.split("T")[0],
                          min: periods[currentIndex + 1].gueltigBis?.split(
                            "T"
                          )[0],
                        }}
                      />
                    ) : (
                      <Field
                        name="datum"
                        component={TextField}
                        type="date"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          max: gueltigBis?.split("T")[0],
                        }}
                      />
                    )
                  ) : periods[currentIndex - 1] ? (
                    <Field
                      name="datum"
                      component={TextField}
                      type="date"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: gueltigVon?.split("T")[0],
                        max: periods[currentIndex - 1].gueltigVon.split("T")[0],
                      }}
                    />
                  ) : (
                    <Field
                      name="datum"
                      component={TextField}
                      type="date"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: gueltigVon?.split("T")[0],
                      }}
                    />
                  )}
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton
                      type="submit"
                      size="small"
                      color="success"
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      {isSubmitting ? (
                        <CircularProgress size="1em" />
                      ) : (
                        <CheckIcon />
                      )}
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => setEditing(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
}
