/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Baustelle } from '../models/Baustelle';
import type { BaustelleDaten } from '../models/BaustelleDaten';
import type { BaustellenInfo } from '../models/BaustellenInfo';
import type { BaustellenListe } from '../models/BaustellenListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BaustellenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Baustellen.
   * @returns BaustellenListe Die Seite der Baustellen.
   * @throws ApiError
   */
  public sucheBaustellen({
    search,
    kundenId,
    archivierte,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    kundenId?: string,
    archivierte?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<BaustellenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/baustellen',
      query: {
        'search': search,
        'kundenId': kundenId,
        'archivierte': archivierte,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Baustelle die angelegte Baustelle
   * @throws ApiError
   */
  public createBaustelle({
    requestBody,
  }: {
    requestBody: BaustelleDaten,
  }): CancelablePromise<Baustelle> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/baustellen',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Baustellen für die Select Auswahlliste.
   * @returns BaustellenListe Die Liste der Baustellen.
   * @throws ApiError
   */
  public sucheBaustellenFuerSelect({
    search,
    kundenId,
    page,
    limit,
    sort,
    aktuelleBaustelle,
  }: {
    search?: string,
    kundenId?: string,
    page?: number,
    limit?: number,
    sort?: string,
    aktuelleBaustelle?: string,
  }): CancelablePromise<BaustellenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/baustellen/select-list',
      query: {
        'search': search,
        'kundenId': kundenId,
        'page': page,
        'limit': limit,
        'sort': sort,
        'aktuelleBaustelle': aktuelleBaustelle,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns BaustellenInfo Baustelle
   * @throws ApiError
   */
  public getBaustelle({
    id,
  }: {
    id: string,
  }): CancelablePromise<BaustellenInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/baustellen/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Baustelle die aktualisierte Baustelle
   * @throws ApiError
   */
  public updateBaustelle({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: BaustelleDaten,
  }): CancelablePromise<Baustelle> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/baustellen/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Baustelle die gelöschte Baustelle
   * @throws ApiError
   */
  public deleteBaustelle({
    id,
  }: {
    id: string,
  }): CancelablePromise<Baustelle> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/baustellen/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Archiviert die angegebene Baustelle
   * @returns void
   * @throws ApiError
   */
  public archiviereBaustelle({
    id,
  }: {
    id: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/baustellen/{id}/archive',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Stellt die angegebene Baustelle wieder her
   * @returns void
   * @throws ApiError
   */
  public restoreBaustelle({
    id,
  }: {
    id: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/baustellen/{id}/restore',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
