/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KontenPage } from '../models/KontenPage';
import type { Konto } from '../models/Konto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class KontenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Konten.
   * @returns KontenPage Die Seite der Konten.
   * @throws ApiError
   */
  public sucheKonten({
    search,
    page,
    limit,
    sort,
    filtered,
  }: {
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
    filtered?: string,
  }): CancelablePromise<KontenPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/konten',
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
        'filtered': filtered,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme ein bestimmtes Konto.
   * @returns Konto Das Konto.
   * @throws ApiError
   */
  public getKonto({
    kontoId,
  }: {
    kontoId: string,
  }): CancelablePromise<Konto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/konten/{kontoId}',
      path: {
        'kontoId': kontoId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
