import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

interface EinheitSelectProps extends FieldProps {
  label: string;
  required?: boolean;
}

function EinheitSelect({
  label,
  required,
  field,
  form,
  meta,
}: EinheitSelectProps) {
  const optionen = ["CB", "TO", "SK", "PA"];

  const [inputValue, setInputValue] = useState(field.value);

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={false}
      options={optionen}
      getOptionLabel={(option: string) => {
        switch (option) {
          case "CB":
            return "CBM";
          case "TO":
            return "TO";
          case "SK":
            return "STÜCK";
          case "PA":
            return "PAUSCHAL";
          default:
            return "";
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
      }}
      renderOption={(props, option: string) => {
        var optionValue = "";
        switch (option) {
          case "CB":
            optionValue = "CBM";
            break;
          case "TO":
            optionValue = "TO";
            break;
          case "SK":
            optionValue = "STÜCK";
            break;
          case "PA":
            optionValue = "PAUSCHAL";
            break;
          default:
            optionValue = "";
            break;
        }
        return (
          <ListItem {...props}>
            <ListItemText primary={optionValue} />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default EinheitSelect;
