import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useCreateAnwesenheit } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { AnwesenheitDaten, Benutzer } from "../../api";
import AnwesenheitFieldsContent from "../../components/anwesenheiten/AnwesenheitFieldsContent";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function ErstelleAnwesenheit() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveAnwesenheit();
  let schema = yup.object().shape({
    datum: yup.string().required(),
    start: yup.string(),
    ende: yup.string(),
    bezahltePause: yup.number(),
    unbezahltePause: yup.number(),
    fahrzeiten: yup.number(),
    arbeitszeitBrutto: yup.number(),
    arbeitszeitNetto: yup.number(),
    mitarbeiter: yup.object().required(),
  });
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: {
        datum: string;
        start: string;
        ende: string;
        mitarbeiter: any;
        bezahltePause: number;
        unbezahltePause: number;
        fahrzeiten: number;
        arbeitszeitBrutto: number;
        arbeitszeitNetto: number;
      },
      formikHelpers: FormikHelpers<{
        datum: string;
        start: string;
        ende: string;
        mitarbeiter: any;
        bezahltePause: number;
        unbezahltePause: number;
        fahrzeiten: number;
        arbeitszeitBrutto: number;
        arbeitszeitNetto: number;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      if (values.start !== "" && values.ende !== "") {
        const mitarbeiterId =
          typeof values.mitarbeiter === "string"
            ? values.mitarbeiter
            : (values.mitarbeiter as Benutzer).id ?? "";
        if (mitarbeiterId !== "") {
          const anwesenheit = await mutateAsync({
            datum: values.datum,
            mitarbeiterId: mitarbeiterId,
            start: values.start,
            ende: values.ende,
            bezahltePause: values.bezahltePause,
            unbezahltePause: values.unbezahltePause,
            fahrzeiten: values.fahrzeiten,
            arbeitszeitBrutto: values.arbeitszeitBrutto,
            arbeitszeitNetto: values.arbeitszeitNetto,
          });
          navigate(`/anwesenheiten`);
        }
      } else {
        console.log("start", values.start);
        console.log("ende", values.ende);
      }
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <Layout title={"Anwesenheit anlegen"} back="/anwesenheiten">
      <Guard
        permission={"anwesenheit:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              datum: getCurrentDate(),
              start: "",
              ende: "",
              mitarbeiter: "",
              bezahltePause: 0,
              unbezahltePause: 0,
              fahrzeiten: 0,
              arbeitszeitBrutto: 0,
              arbeitszeitNetto: 0,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => (
              <>
                <Typography variant="h5">Anwesenheit anlegen</Typography>
                <DialogContent>
                  <Form id="new-anwesenheit">
                    <AnwesenheitFieldsContent
                      isVerwaltungAnwesenheit={true}
                      startzeit={null}
                      endzeit={null}
                    />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-anwesenheit"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveAnwesenheit() {
  const createAnwesenheit = useCreateAnwesenheit();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AnwesenheitDaten) => {
      return createAnwesenheit(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["anwesenheiten"]);
      },
    }
  );
}
