import {
  Box,
  Button,
  capitalize,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React, { useEffect } from "react";
import {
  useDeleteFuhrparkeintragAnbauteil,
  useGetFahrzeugAnbauteilKostenFuerBestimmtesDatum,
} from "../../../../client/hooks";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ActionTimestamp } from "../../../../api";
import ZugewieseneAnbauteileSelect from "../../../fuhrpark/anbauteil/ZugewieseneAnbauteileSelect";
import { ZugewiesenesAnbauteil } from "../../../fuhrpark/anbauteil/ZugewieseneAnbauteileDataTable";

interface ExtendedFuhrparkeintragAnbauteilDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  anbauteil: any;
  anbauteilNutzungsdauer: number;
  anbauteilAnzahl: number;
  anbauteilStundenPreis: number;
  anbauteilKosten: number;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

export default function FuhrparkeintragAnbauteileFieldsContent({
  isNew,
  index,
  anbauteilIndex,
  fahrzeugId,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  push,
  remove,
  newestIndex,
  fuhrparkeintragAnbauteil,
}: {
  isNew: boolean;
  index: number;
  anbauteilIndex: number;
  fahrzeugId: string;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  fuhrparkeintragAnbauteil: ExtendedFuhrparkeintragAnbauteilDaten;
}) {
  const { t } = useT("taetigkeitsbericht");
  const { setFieldValue } = useFormikContext();

  const deleteFuhrparkeintragAnbauteil = useDeleteFuhrparkeintragAnbauteil();

  const [anchorElAnbauteilanlegenInfo, setAnchorElAnbauteilanlegenInfo] =
    React.useState<HTMLElement | null>(null);
  const handlePopoverOpenAnbauteilanlegenInfo = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElAnbauteilanlegenInfo(event.currentTarget);
  };

  const handlePopoverCloseAnbauteilanlegenInfo = () => {
    setAnchorElAnbauteilanlegenInfo(null);
  };

  const openAnbauteilanlegenInfo = Boolean(anchorElAnbauteilanlegenInfo);

  const bekommeAnbauteilKosten =
    useGetFahrzeugAnbauteilKostenFuerBestimmtesDatum();

  const [anbauteilField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteil`
  );
  const [anbauteilNutzungsdauerField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilNutzungsdauer`
  );
  const [anbauteilStundenpreisField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`
  );
  const [anbauteilAnzahlField] = useField(
    `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilAnzahl`
  );

  const calculateAnbauteilkosten = () => {
    setFieldValue(
      `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilKosten`,
      (
        anbauteilAnzahlField.value *
        anbauteilNutzungsdauerField.value *
        anbauteilStundenpreisField.value
      ).toFixed(2)
    );
  };

  useEffect(() => {
    calculateAnbauteilkosten();
  }, [
    anbauteilNutzungsdauerField.value,
    anbauteilAnzahlField.value,
    anbauteilStundenpreisField.value,
  ]);

  useEffect(() => {
    const fetchAndSetAnbauteilStundenPreis = async () => {
      if (anbauteilField.value) {
        if (isNew) {
          try {
            if (taetigkeitsberichtDatum) {
              taetigkeitsberichtDatum = new Date(
                taetigkeitsberichtDatum
              ).toISOString();
            } else {
              taetigkeitsberichtDatum = new Date().toISOString();
            }

            const anbauteilStundenPreis = await bekommeAnbauteilKosten(
              fahrzeugId,
              typeof anbauteilField.value === "string"
                ? anbauteilField.value
                : (anbauteilField.value as ZugewiesenesAnbauteil)
                    ?.anbauteilId ?? "",
              { date: taetigkeitsberichtDatum }
            );

            setFieldValue(
              `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`,
              anbauteilStundenPreis.kostenStunde.toFixed(2)
            );
          } catch (error) {
            setFieldValue(
              `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`,
              0
            );
          }
        }
      }
    };

    fetchAndSetAnbauteilStundenPreis();

    if (!anbauteilField.value && isNew && dauerVorhanden) {
      setFieldValue(
        `fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilNutzungsdauer`,
        dauerVorhanden
      );
    }
  }, [anbauteilField.value, index, setFieldValue]);

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 6.2fr .5fr 3fr 2fr 1fr 1fr"],
          gap: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-duration"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilNutzungsdauer`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-attachment-part-pieces"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilAnzahl`}
          type={"number"}
          inputProps={{ min: 1, style: { textAlign: "right" } }}
          required
        />
        <FastField
          key={fahrzeugId}
          component={ZugewieseneAnbauteileSelect}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteil`}
          label={capitalize(t("carpool-entry-attachment-part"))}
          required
          fahrzeugId={fahrzeugId}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => window.open(`/fahrzeuge/${fahrzeugId}`)}
          aria-owns={
            openAnbauteilanlegenInfo ? "mouse-over-popover" : undefined
          }
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpenAnbauteilanlegenInfo}
          onMouseLeave={handlePopoverCloseAnbauteilanlegenInfo}
          disabled={!fahrzeugId}
        >
          <AddIcon />
        </Button>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={openAnbauteilanlegenInfo}
          anchorEl={anchorElAnbauteilanlegenInfo}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverCloseAnbauteilanlegenInfo}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {capitalize(t("create-attachment-part-info"))}
          </Typography>
        </Popover>
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-price"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilStundenPreis`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("carpool-entry-attachment-part-costs"))}
          name={`fuhrparkeintraege.${index}.fuhrparkeintragAnbauteile.${anbauteilIndex}.anbauteilKosten`}
          type={"number"}
          inputProps={{ min: 0, step: 0.01, style: { textAlign: "right" } }}
          disabled={true}
        />
        {anbauteilIndex === newestIndex ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              push({
                anbauteil: "",
                anbauteilNutzungsdauer: 0,
                anbauteilAnzahl: 1,
                anbauteilStundenPreis: 0,
              });
            }}
            disabled={!fahrzeugId}
          >
            <AddCircleIcon />
          </Button>
        ) : (
          <></>
        )}
        <IconButton
          onClick={() => {
            const fuhrparkeintragAnbauteilId = fuhrparkeintragAnbauteil.id;
            if (fuhrparkeintragAnbauteilId) {
              deleteFuhrparkeintragAnbauteil(
                fuhrparkeintragAnbauteil.taetigkeitsberichtId,
                fuhrparkeintragAnbauteil.fuhrparkeintragId,
                fuhrparkeintragAnbauteilId
              ).then(() => {
                remove(anbauteilIndex);
              });
            } else {
              remove(anbauteilIndex);
            }
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  );
}
