import {
  capitalize,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../../data";
import { useT } from "../../../i18n";
import DataTable from "../../data/DataTable";
import { useGetApi } from "../../../client";
import { MaterialKosten } from "../../../api";
import MaterialKostenEditableRow from "./MaterialKostenEditableRow";
import MaterialKostenEditableRowPrice from "./MaterialKostenEditableRowPrice";

export type MaterialKostenFilter = {
  materialId: string;
  search?: string;
};

export type Props = Omit<DataRequest<MaterialKostenFilter>, "filter"> &
  Partial<Pick<DataRequest<MaterialKostenFilter>, "filter">> & {
    materialId: string;
  };

export default function MaterialKostenDataTable({
  materialId,
  ...input
}: Props) {
  const { t } = useT("fuhrpark");
  const request = useDataRequest<MaterialKostenFilter>({
    filter: { materialId: materialId },
    ...input,
  });

  return (
    <>
      <Paper elevation={1} sx={{ padding: 3 }}>
        <Stack direction={"column"} spacing={2}>
          <Typography variant="h5">{capitalize(t("cost-history"))}</Typography>
          <FilterComp {...request} />
          <MaterialKostenResults {...request} />
        </Stack>
      </Paper>
    </>
  );
}

function FilterComp(request: DataRequestState<MaterialKostenFilter>) {
  const { t } = useT("fuhrpark");
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return <></>;
}

function MaterialKostenResults({
  ...request
}: DataRequestState<MaterialKostenFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<MaterialKostenFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const { t } = useT("fuhrpark");

  const ladeMaterialKosten: DataLoader<MaterialKostenFilter, MaterialKosten> =
    useCallback(
      async (params) =>
        (await getApi()).material.getMaterialCosts({ ...params }),
      [getApi]
    );

  return (
    <>
      <DataTable
        columns={useColumns()}
        request={request}
        queryKey={["material-kosten"]}
        loadData={ladeMaterialKosten}
        size={"large"}
      />
    </>
  );
}
function useColumns(): Array<GridColDef<MaterialKosten>> {
  const { t, capitalize } = useT("fuhrpark");

  return useMemo(
    () => [
      {
        field: "gueltigVon",
        headerName: capitalize(t("assign-attachment-part-valid-from")),
        width: 250,
        renderCell: (params) => {
          if (params.value) {
            return (
              <MaterialKostenEditableRow
                type={"von"}
                gueltig={params.value}
                gueltigVon={params.value}
                gueltigBis={params.row.gueltigBis}
                eintrag={params.row}
              />
            );
          }
          return "";
        },
      },
      {
        field: "gueltigBis",
        headerName: capitalize(t("assign-attachment-part-valid-to")),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return (
              <MaterialKostenEditableRow
                type={"bis"}
                gueltig={params.value}
                gueltigVon={params.row.gueltigVon}
                gueltigBis={params.value}
                eintrag={params.row}
              />
            );
          }
          return "aktuell";
        },
      },
      {
        field: "kostenProTonne",
        headerName: capitalize(t("assign-attachment-part-cost-tons")),
        flex: 1,
        renderCell: (params) => {
          return (
            <MaterialKostenEditableRowPrice
              type={"tonne"}
              price={params.value ?? 0}
              eintrag={params.row}
            />
          );
        },
      },
      {
        field: "kostenProKubikmeter",
        headerName: capitalize(t("assign-attachment-part-cost-cubic")),
        flex: 1,
        renderCell: (params) => {
          return (
            <MaterialKostenEditableRowPrice
              type={"kubikmeter"}
              price={params.value ?? 0}
              eintrag={params.row}
            />
          );
        },
      },
    ],
    [t, capitalize]
  );
}
