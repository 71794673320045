/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Kunde } from '../models/Kunde';
import type { KundeDaten } from '../models/KundeDaten';
import type { KundeListe } from '../models/KundeListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class KundenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns KundeListe Liste der Kunden
   * @throws ApiError
   */
  public sucheKunden({
    search,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<KundeListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/kunden',
      query: {
        'search': search,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Kunde der angelegte Kunde
   * @throws ApiError
   */
  public createKunde({
    requestBody,
  }: {
    requestBody: KundeDaten,
  }): CancelablePromise<Kunde> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/kunden',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Kunde der Kunde
   * @throws ApiError
   */
  public getKunde({
    id,
  }: {
    id: string,
  }): CancelablePromise<Kunde> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/kunden/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Kunde der aktualisierte Kunde
   * @throws ApiError
   */
  public updateKunde({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: KundeDaten,
  }): CancelablePromise<Kunde> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/kunden/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Kunde der gelöschte Kunde
   * @throws ApiError
   */
  public deleteKunde({
    id,
  }: {
    id: string,
  }): CancelablePromise<Kunde> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/kunden/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
