// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Personaleintrag } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeletePersonaleintrag(): (
  taetigkeitsberichtId: string
) => Promise<Personaleintrag> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId) =>
      (await getAPI()).personaleintraege.deletePersonaleintrag({
        taetigkeitsberichtId,
      }),
    [getAPI]
  );
}
