// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Zahllauf } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetZahllauf(): (zahllaufId: string) => Promise<Zahllauf> {
  const getAPI = useGetApi();
  return useCallback(
    async (zahllaufId) =>
      (await getAPI()).zahllaeufe.getZahllauf({ zahllaufId }),
    [getAPI]
  );
}
