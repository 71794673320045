import { useT } from "../../i18n";
import Layout from "../../components/Layout";
import { Paper, Typography } from "@mui/material";
import AbwesenheitDataTable from "../../components/abwesenheiten/AbwesenheitDataTable";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import React from "react";
import Guard from "../../components/Guard";

export default function Abwesenheiten() {
  const { t } = useT("taetigkeitsbericht");
  const limit = parseInt(localStorage.getItem("urlaubLimit") ?? "25", 10);
  return (
    <Layout>
      <Guard
        permission={"abwesenheit:list"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Typography variant="h5">Abwesenheiten</Typography>
          <AbwesenheitDataTable limit={limit} />
        </Paper>
      </Guard>
    </Layout>
  );
}
