import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import AbfallartenFieldsContent from "../../components/abfallarten/AbfallartenFieldsContent";
import { useCreateAbfallart } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { AbfallartDaten } from "../../api";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function ErstelleAbfallart() {
  const { t } = useTranslation("customer");
  const { mutateAsync } = useSaveAbfallart();
  let schema = yup.object().shape({
    key: yup.string().required(),
    name: yup.string().required(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: { key: string; name: string },
      formikHelpers: FormikHelpers<{ key: string; name: string }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const abfallart = await mutateAsync({
        key: values.key,
        name: values.name,
      });
      navigate(`/abfallarten`);
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={"Neue Abfallart"} back="/abfallarten">
      <Guard
        permission={"abfallart:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              key: "",
              name: "",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">Neue Abfallart</Typography>
                <DialogContent>
                  <Form id="new-material">
                    <AbfallartenFieldsContent></AbfallartenFieldsContent>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-material"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveAbfallart() {
  const createAbfallart = useCreateAbfallart();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AbfallartDaten) => {
      return createAbfallart(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["materials"]);
      },
    }
  );
}
