/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Abwesenheit } from '../models/Abwesenheit';
import type { AbwesenheitDaten } from '../models/AbwesenheitDaten';
import type { AbwesenheitenListe } from '../models/AbwesenheitenListe';
import type { MyAbwesenheitDaten } from '../models/MyAbwesenheitDaten';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AbwesenheitenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Abwesenheiten.
   * @returns AbwesenheitenListe Die Seite der Abwesenheiten.
   * @throws ApiError
   */
  public sucheAbwesenheiten({
    search,
    mitarbeiterId,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    mitarbeiterId?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AbwesenheitenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/abwesenheiten',
      query: {
        'search': search,
        'mitarbeiterId': mitarbeiterId,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abwesenheit Die angelegte Abwesenheit
   * @throws ApiError
   */
  public createAbwesenheit({
    requestBody,
  }: {
    requestBody: AbwesenheitDaten,
  }): CancelablePromise<Abwesenheit> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/abwesenheiten',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Abwesenheiten des angemeldeten Benutzers.
   * @returns AbwesenheitenListe Die Seite der Abwesenheiten.
   * @throws ApiError
   */
  public sucheMeineAbwesenheiten({
    search,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AbwesenheitenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/abwesenheiten/my',
      query: {
        'search': search,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abwesenheit Die angelegte Abwesenheit
   * @throws ApiError
   */
  public createMeineAbwesenheit({
    requestBody,
  }: {
    requestBody: MyAbwesenheitDaten,
  }): CancelablePromise<Abwesenheit> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/abwesenheiten/my',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abwesenheit Die aktualisierte Abwesenheit
   * @throws ApiError
   */
  public updateMeineAbwesenheit({
    abwesenheitId,
    requestBody,
  }: {
    abwesenheitId: string,
    requestBody: MyAbwesenheitDaten,
  }): CancelablePromise<Abwesenheit> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/abwesenheiten/my/{abwesenheitId}',
      path: {
        'abwesenheitId': abwesenheitId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Abwesenheit Die Abwesenheit
   * @throws ApiError
   */
  public getAbwesenheit({
    abwesenheitId,
  }: {
    abwesenheitId: string,
  }): CancelablePromise<Abwesenheit> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/abwesenheiten/{abwesenheitId}',
      path: {
        'abwesenheitId': abwesenheitId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Abwesenheit Die aktualisierte Abwesenheit
   * @throws ApiError
   */
  public updateAbwesenheit({
    abwesenheitId,
    requestBody,
  }: {
    abwesenheitId: string,
    requestBody: AbwesenheitDaten,
  }): CancelablePromise<Abwesenheit> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/abwesenheiten/{abwesenheitId}',
      path: {
        'abwesenheitId': abwesenheitId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Abwesenheit Die gelöschte Abwesenheit
   * @throws ApiError
   */
  public deleteAbwesenheit({
    abwesenheitId,
  }: {
    abwesenheitId: string,
  }): CancelablePromise<Abwesenheit> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/abwesenheiten/{abwesenheitId}',
      path: {
        'abwesenheitId': abwesenheitId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Abwesenheit Die aktualisierte Abwesenheit
   * @throws ApiError
   */
  public approveAbwesenheit({
    abwesenheitId,
    requestBody,
  }: {
    abwesenheitId: string,
    requestBody: AbwesenheitDaten,
  }): CancelablePromise<Abwesenheit> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/abwesenheiten/{abwesenheitId}/approve',
      path: {
        'abwesenheitId': abwesenheitId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns any Verarbeitung gestartet
   * @throws ApiError
   */
  public approveMultipleAbwesenheiten({
    requestBody,
  }: {
    requestBody: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/abwesenheiten/approve/multiple',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
