import { Form, Formik, FormikHelpers } from "formik";
import {
  Button,
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useBaustelle } from "../../client/queries";
import {
  useArchiviereBaustelle,
  useRestoreBaustelle,
  useUpdateBaustelle,
} from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { BaustelleDaten } from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import BaustellenFieldsContent from "../../components/baustellen/BaustellenFieldsContent";
import DeleteBaustelleButton from "../../components/baustellen/DeleteBaustelleButton";
import { Archive, Unarchive } from "@mui/icons-material";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function DetailsBaustelle() {
  const { id: baustellenId = "" } = useParams<{
    id: string;
  }>();
  const baustelle = useBaustelle(baustellenId);
  const archiviereBaustelle = useArchiveBaustelle(baustellenId);
  const unArchiviereBaustelle = useUnArchiveBaustelle(baustellenId);

  const { mutateAsync } = useSaveBaustelle(baustellenId);
  const { t } = useTranslation("customer");
  let schema = yup.object().shape({
    bezeichnung: yup.string(),
    name: yup.string().required(),
    plz: yup.string(),
    strasse: yup.string(),
    ort: yup.string().required(),
    telefon: yup.string(),
    email: yup.string(),
    erstAuftragsSumme: yup.string(),
    auftragsSumme: yup.string(),
    saldo: yup.string(),
    kunde: yup.object({ id: yup.string(), name: yup.string() }).nullable(),
  });
  const navigate = useNavigate();

  const formatNumber = (value: number | undefined) => {
    if (value == null || isNaN(value)) return "";

    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const onSubmit = useCallback(
    async (
      values: {
        bezeichnung: string;
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        erstAuftragsSumme: string;
        auftragsSumme: string;
        saldo: string;
        kunde: { id: string; name: string };
      },
      formikHelpers: FormikHelpers<{
        bezeichnung: string;
        name: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        email: string;
        erstAuftragsSumme: string;
        auftragsSumme: string;
        saldo: string;
        kunde: { id: string; name: string };
      }>
    ) => {
      let auftragsSumme = values.auftragsSumme
        ? parseFloat(values.auftragsSumme.replace(/\./g, "").replace(",", "."))
        : 0.0;
      let saldo = values.saldo
        ? parseFloat(values.saldo.replace(/\./g, "").replace(",", "."))
        : 0.0;
      const baustellen = await mutateAsync({
        name: values.name,
        strasse: values.strasse,
        plz: values.plz,
        telefon: values.telefon,
        email: values.email,
        ort: values.ort,
        saldo: saldo,
        bezeichnung: values.bezeichnung,
        auftragsSumme: auftragsSumme,
        kundenId: values.kunde?.id,
      });
      navigate(`/baustellen`);
      const { setSubmitting, setStatus } = formikHelpers;
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Guard
      permission={"baustelle:edit"}
      fallback={<ErrorAlert error={notPermitted()} />}
    >
      <Paper
        elevation={1}
        sx={{
          marginTop: 2,
          padding: 3,
          marginLeft: 2,
          marginRight: 2,
          position: "relative",
        }}
      >
        <Guard permission={"baustelle:delete"}>
          <DeleteBaustelleButton id={baustellenId}></DeleteBaustelleButton>
        </Guard>
        <Formik
          enableReinitialize
          initialValues={{
            bezeichnung: baustelle.bezeichnung ?? "",
            name: baustelle.name ?? "",
            plz: baustelle.plz ?? "",
            strasse: baustelle.strasse ?? "",
            ort: baustelle.ort ?? "",
            telefon: baustelle.telefon ?? "",
            email: baustelle.email ?? "",
            erstAuftragsSumme: formatNumber(baustelle.erstAuftragsSumme),
            auftragsSumme: formatNumber(baustelle.auftragsSumme),
            saldo: formatNumber(baustelle.saldo),
            kunde: {
              id: baustelle.kundenId ?? "",
              name: baustelle.kundenName ?? "",
            },
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty }) => (
            <>
              <Typography variant="h5">
                {baustelle.geloeschtAm === undefined ? (
                  t("construction-site_one") + " " + baustelle.nummer
                ) : (
                  <>
                    <>{t("construction-site_one") + " " + baustelle.nummer}</>
                    <Chip
                      size="small"
                      icon={<DeleteIcon />}
                      label={"gelöscht"}
                      color="error"
                    />
                  </>
                )}
                {baustelle.archiviert !== undefined ? (
                  <Chip
                    size="small"
                    icon={<Archive />}
                    label={"archiviert"}
                    color="warning"
                  />
                ) : (
                  <></>
                )}
              </Typography>
              <DialogContent>
                <Form id="details-baustelle">
                  <BaustellenFieldsContent
                    isNew={false}
                  ></BaustellenFieldsContent>
                </Form>
              </DialogContent>
              <DialogActions>
                {baustelle.archiviert === undefined ? (
                  <Button
                    startIcon={<Archive />}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      archiviereBaustelle.mutate();
                    }}
                  >
                    Archivieren
                  </Button>
                ) : (
                  <Button
                    startIcon={<Unarchive />}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      unArchiviereBaustelle.mutate();
                    }}
                  >
                    Wiederherstellen
                  </Button>
                )}
                <SubmitButton
                  form="details-baustelle"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Guard>
  );
}
function useSaveBaustelle(id: string) {
  const updateBaustelle = useUpdateBaustelle();
  const queryClient = useQueryClient();

  return useMutation(
    (input: BaustelleDaten) => {
      return updateBaustelle(id, input);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["baustellen", id]);
      },
    }
  );
}

function useArchiveBaustelle(id: string) {
  const archiveBaustelle = useArchiviereBaustelle();
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return archiveBaustelle(id);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["baustellen", id]);
      },
    }
  );
}

function useUnArchiveBaustelle(id: string) {
  const archiveBaustelle = useRestoreBaustelle();
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return archiveBaustelle(id);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["baustellen", id]);
      },
    }
  );
}
