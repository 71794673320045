import {
  Box,
  capitalize,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { EingangsrechnungInfo } from "../../api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { apiUrl } from "../../config";
import DataTableWithFilter from "../data/DataTableWithFilter";

export type ZahllaufFilter = {
  search?: string;
  status?: string;
  zahllauf?: string;
  kundenId?: string;
  zahllaufBezahlt?: boolean;
  filtered?: string;
};

export type Props = Omit<DataRequest<ZahllaufFilter>, "filter"> &
  Partial<Pick<DataRequest<ZahllaufFilter>, "filter">>;

export default function ZahllaufArchivDataTable({ ...input }: Props) {
  const [filteredState, setFilteredState] = useState("");
  const request = useDataRequest<ZahllaufFilter>({
    filter: { zahllaufBezahlt: true },
    ...input,
  });

  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <ZahllaufResults request={request} setFilteredState={setFilteredState} />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<ZahllaufFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search, status, zahllauf }, setField] = useDebouncedFilter(request);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useT("zahllauf");

  useEffect(() => {
    setField("filtered", filteredStateFilter);
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
        </>
      )}
    </Box>
  );
}

function ZahllaufResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<ZahllaufFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}

function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<ZahllaufFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();

  const loadEingangsrechnungen: DataLoader<
    ZahllaufFilter,
    EingangsrechnungInfo
  > = useCallback(
    async (params) => {
      const api = await getApi();
      return await api.zahllaeufe.sucheZahllaeufe({
        ...params,
      });
    },
    [getApi]
  );

  const handleDownloadZahlungsdatei = (zahllaufId: GridRowId) => {
    window.open(
      `${apiUrl}/zahllaeufe/${encodeURIComponent(zahllaufId)}/anhang`
    );
  };

  return (
    <DataTableWithFilter
      columns={useColumns(handleDownloadZahlungsdatei)}
      request={request}
      queryKey={["zahllauf-archiv"]}
      loadData={loadEingangsrechnungen}
      // @ts-ignore
      disableRowSelectionOnClick={true}
      isRowSelectable={false}
      name={"zahllauf-archiv"}
      setFiltered={setFilteredState}
    />
  );
}

function useColumns(
  handleDownloadZahlungsdatei: (zahllaufId: GridRowId) => void
): Array<GridColDef<EingangsrechnungInfo>> {
  const { t } = useT("zahllauf");

  // @ts-ignore
  return useMemo(
    () => [
      {
        field: "bezeichnung",
        headerName: capitalize(t("description")),
        flex: 1,
        type: "string",
      },
      {
        field: "bezahltAm",
        headerName: capitalize(t("paid-on")),
        flex: 1,
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) => {
          const date = new Date(value);
          // @ts-ignore
          return isNaN(date)
            ? ""
            : `${("0" + date.getDate()).slice(-2)}.${(
                "0" +
                (date.getMonth() + 1)
              ).slice(-2)}.${date.getFullYear()}`;
        },
        type: "date",
      },
      {
        field: "anzahlRechnungen",
        headerName: capitalize(t("invoice-count")),
        flex: 1,
        type: "string",
      },
      {
        field: "zahlbetrag",
        headerName: capitalize(t("payment-amount")),
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "download",
        headerName: "",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 60,
        renderCell: (params) => (
          <>
            <IconButton
              onClick={() => handleDownloadZahlungsdatei(params.row.id)}
            >
              <FileDownloadIcon />
            </IconButton>
          </>
        ),
      },
    ],
    [t]
  );
}
