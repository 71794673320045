import { capitalize, Paper, Stack } from "@mui/material";
import { FastField } from "formik";
import ZahllaufSelect from "./ZahllaufSelect";
import { useT } from "../../i18n";
import KontoSelect from "../konten/KontoSelect";

interface ZahlungsdateiGenerierenModalFieldsContentProps {
  setAktuelleZahllaufId: (value: string) => void;
  setAktuelleKontoId: (value: string) => void;
}

function ZahlungsdateiGenerierenModalFieldsContent({
  setAktuelleZahllaufId,
  setAktuelleKontoId,
}: ZahlungsdateiGenerierenModalFieldsContentProps) {
  const { t } = useT("zahllauf");

  return (
    <Paper sx={{ marginTop: 2, padding: 3 }}>
      <Stack sx={{ gap: 4 }}>
        <FastField
          component={ZahllaufSelect}
          name="zahllauf"
          label={capitalize(t("paymentrun"))}
          required
          zahllaufBezahlt={false}
        />
        <FastField
          component={KontoSelect}
          name="konto"
          label={capitalize(t("account"))}
          required
        />
      </Stack>
    </Paper>
  );
}

export default ZahlungsdateiGenerierenModalFieldsContent;
