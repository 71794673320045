// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type UmsatzListeArray } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useSucheUmsatzByBaustelleImZeitraum(): (
  baustellenId: string,
  requestBody: { startDate: string; endDate: string }
) => Promise<UmsatzListeArray> {
  const getAPI = useGetApi();
  return useCallback(
    async (baustellenId, requestBody) =>
      (await getAPI()).umsatz.sucheUmsatzByBaustelleImZeitraum({
        baustellenId,
        requestBody,
      }),
    [getAPI]
  );
}
