/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Rechnung } from '../models/Rechnung';
import type { RechnungenListe } from '../models/RechnungenListe';
import type { ZahllaeufePage } from '../models/ZahllaeufePage';
import type { Zahllauf } from '../models/Zahllauf';
import type { ZahllaufDaten } from '../models/ZahllaufDaten';
import type { Zahlungsdatei } from '../models/Zahlungsdatei';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ZahllaeufeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Zahllaeufe.
   * @returns ZahllaeufePage Die Seite der Zahllaeufe.
   * @throws ApiError
   */
  public sucheZahllaeufe({
    zahllaufBezahlt,
    search,
    page,
    limit,
    sort,
    filtered,
  }: {
    zahllaufBezahlt?: boolean,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
    filtered?: string,
  }): CancelablePromise<ZahllaeufePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/zahllaeufe',
      query: {
        'zahllaufBezahlt': zahllaufBezahlt,
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
        'filtered': filtered,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Zahllauf der angelegte Zahllauf
   * @throws ApiError
   */
  public createZahllauf({
    requestBody,
  }: {
    requestBody: ZahllaufDaten,
  }): CancelablePromise<Zahllauf> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/zahllaeufe',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme ein bestimmten Zahllauf.
   * @returns Zahllauf Das Zahllauf.
   * @throws ApiError
   */
  public getZahllauf({
    zahllaufId,
  }: {
    zahllaufId: string,
  }): CancelablePromise<Zahllauf> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/zahllaeufe/{zahllaufId}',
      path: {
        'zahllaufId': zahllaufId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Zahllauf der aktualisierte Zahllauf
   * @throws ApiError
   */
  public updateZahllauf({
    zahllaufId,
    requestBody,
  }: {
    zahllaufId: string,
    requestBody: ZahllaufDaten,
  }): CancelablePromise<Zahllauf> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/zahllaeufe/{zahllaufId}',
      path: {
        'zahllaufId': zahllaufId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Zahllauf der gelöschte Zahllauf
   * @throws ApiError
   */
  public deleteZahllauf({
    zahllaufId,
  }: {
    zahllaufId: string,
  }): CancelablePromise<Zahllauf> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/zahllaeufe/{zahllaufId}',
      path: {
        'zahllaufId': zahllaufId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Rechnungen zu einem bestimmten Zahllauf.
   * @returns RechnungenListe Die Seite der Rechnungen zu einem Zahllauf.
   * @throws ApiError
   */
  public sucheRechnungenZuZahllauf({
    zahllaufId,
    search,
    page,
    limit,
    sort,
    filtered,
  }: {
    zahllaufId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
    filtered?: string,
  }): CancelablePromise<RechnungenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/zahllaeufe/{zahllaufId}/rechnungen',
      path: {
        'zahllaufId': zahllaufId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
        'filtered': filtered,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Rechnung die gelöschte Rechnung zu einem Zahllauf
   * @throws ApiError
   */
  public deleteRechnungZuZahllauf({
    zahllaufId,
    rechnungId,
  }: {
    zahllaufId: string,
    rechnungId: string,
  }): CancelablePromise<Rechnung> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/zahllaeufe/{zahllaufId}/rechnungen/{rechnungId}',
      path: {
        'zahllaufId': zahllaufId,
        'rechnungId': rechnungId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Erstelle eine Zahlungsdatei für einen bestimmten Zahllauf.
   * @returns Zahlungsdatei Die Zahlungsdatei.
   * @throws ApiError
   */
  public generiereZahlungsdatei({
    zahllaufId,
    requestBody,
  }: {
    zahllaufId: string,
    requestBody: {
      kontoId: string;
    },
  }): CancelablePromise<Zahlungsdatei> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/zahllaeufe/{zahllaufId}/generieren',
      path: {
        'zahllaufId': zahllaufId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
