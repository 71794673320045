import {
  Box,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useT } from "../../i18n";
import { ChangeEvent, useCallback } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import SubmitButton from "../SubmitButton";
import { useCreateZahllauf, useUpdateZahllauf } from "../../client/hooks";
import { Zahllauf } from "../../api";
import { useQueryClient } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import ZahllaufZuweisenModalFieldsContent from "./ZahllaufZuweisenModalFieldsContent";
import ErrorAlert from "../ErrorAlert";
import { invoicesNotReleased } from "../../errors";

interface ZahllaufZuweisenModalProps {
  setAktuelleBezeichnung: (value: string) => void;
  aktuelleBezeichnung: string;
  setAktuelleZahllaufId: (value: string) => void;
  aktuelleZahllaufId: string;
  setAuswahlZahllaufVariante: (value: string) => void;
  auswahlZahllaufVariante: string;
  setZahllaufZuweisenModalOpened: (value: boolean) => void;
  anzahlRechnungen: number;
  summeRechnungen: number;
  rechnungen: string[];
  setAktuelleKontoId: (value: string) => void;
  beinhaltetNichtFreigegebeneRechnungen: boolean;
}

function ZahllaufZuweisenModal({
  setAktuelleBezeichnung,
  aktuelleBezeichnung,
  setAktuelleZahllaufId,
  aktuelleZahllaufId,
  setAuswahlZahllaufVariante,
  auswahlZahllaufVariante,
  setZahllaufZuweisenModalOpened,
  anzahlRechnungen,
  summeRechnungen,
  rechnungen,
  setAktuelleKontoId,
  beinhaltetNichtFreigegebeneRechnungen,
}: ZahllaufZuweisenModalProps) {
  const { t } = useT("zahllauf");
  const queryClient = useQueryClient();

  const erstelleZahllauf = useCreateZahllauf();
  const aktualisiereZahllauf = useUpdateZahllauf();

  let schema =
    auswahlZahllaufVariante === "new"
      ? yup.object().shape({
          bezeichnung: yup.string().required(),
        })
      : yup.object().shape({
          zahllauf: yup.object().required(),
        });

  const onSubmit = useCallback(
    async (
      values: {
        paymentrunRadioGroup: string;
        bezeichnung: string;
        zahllauf: any;
      },
      formikHelpers: FormikHelpers<{
        paymentrunRadioGroup: string;
        bezeichnung: string;
        zahllauf: any;
      }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      try {
        if (values.paymentrunRadioGroup === "new") {
          const zahllauf = await erstelleZahllauf({
            bezeichnung: values.bezeichnung,
            rechnungen: rechnungen,
          });
        } else {
          const zahllauf = await aktualisiereZahllauf(
            typeof values.zahllauf === "string"
              ? values.zahllauf
              : (values.zahllauf as Zahllauf)?.id ?? "",
            {
              rechnungen: rechnungen,
            }
          );
        }
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
        setZahllaufZuweisenModalOpened(false);
        queryClient.invalidateQueries(["zahllauf"]);
      }
    },
    [erstelleZahllauf, aktualisiereZahllauf, rechnungen, t]
  );

  return (
    <Card
      sx={{
        position: "fixed",
        right: "2em",
        bottom: "1.5em",
        minWidth: "40%",
        boxShadow: 20,
        marginTop: 2,
        padding: 3,
        zIndex: 6,
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <CardHeader
          title={capitalize(t("assign-paymentrun"))}
          subheader={`${anzahlRechnungen} ${capitalize(
            t("invoices")
          )}, ${summeRechnungen
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`}
        ></CardHeader>
        <IconButton
          onClick={() => setZahllaufZuweisenModalOpened(false)}
          sx={{ marginBottom: "2em" }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      {beinhaltetNichtFreigegebeneRechnungen ? (
        <ErrorAlert error={invoicesNotReleased()} />
      ) : (
        <></>
      )}

      <CardContent>
        <Stack>
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={{
              paymentrunRadioGroup: auswahlZahllaufVariante,
              bezeichnung: aktuelleBezeichnung,
              zahllauf: aktuelleZahllaufId,
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => {
              return (
                <>
                  <Form id="zahllauf-zuweisen">
                    <FormControl>
                      <FormLabel id={"paymentrunRadioGroupLabel"}>
                        {capitalize(t("paymentrun"))}
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="paymentrunRadioGroupLabel"
                        defaultValue={capitalize(t("paymentrun"))}
                        name="paymentrunRadioGroup"
                        row
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setAuswahlZahllaufVariante(e.target.value);
                        }}
                        value={auswahlZahllaufVariante}
                      >
                        <FormControlLabel
                          value="new"
                          control={<Radio />}
                          label={capitalize(t("new"))}
                        />
                        <FormControlLabel
                          value="existing"
                          control={<Radio />}
                          label={capitalize(t("existing"))}
                        />
                      </RadioGroup>
                    </FormControl>
                    <ZahllaufZuweisenModalFieldsContent
                      setAktuelleBezeichnung={setAktuelleBezeichnung}
                      setAktuelleZahllaufId={setAktuelleZahllaufId}
                      auswahlZahllaufVariante={auswahlZahllaufVariante}
                    />
                    <Box
                      display={"grid"}
                      sx={{
                        marginTop: 3,
                        gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr 1fr"],
                        gap: 2,
                      }}
                    >
                      <SubmitButton
                        form={"zahllauf-zuweisen"}
                        variant="contained"
                        color="secondary"
                        sx={{
                          gridColumn: { sm: "5 / 6" },
                        }}
                        loading={isSubmitting}
                        disabled={isValidating || !isValid || !dirty}
                        type={"submit"}
                      >
                        {capitalize(t("assign"))}
                      </SubmitButton>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ZahllaufZuweisenModal;
