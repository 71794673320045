import { Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import KundenFieldsContent from "../../components/kunden/KundenFieldsContent";
import { useKunde } from "../../client/queries";
import { useUpdateKunde } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { KundeDaten } from "../../api";
import DeleteKundeButton from "../../components/kunden/DeleteKundeButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

export default function DetailsKunde() {
  const { id: customerId = "" } = useParams<{
    id: string;
  }>();
  const { t } = useTranslation("customer");
  const kunde = useKunde(customerId);
  const { mutateAsync } = useSaveKunde(customerId);
  let schema = yup.object().shape({
    name: yup.string().required(),
    namenszusatz: yup.string(),
    kundennummer: yup.string(),
    umsatzsteuerId: yup.string(),
    postfach: yup.string(),
    plzPostfach: yup.string(),
    plz: yup.string(),
    strasse: yup.string().required(),
    ort: yup.string().required(),
    telefon: yup.string(),
    fax: yup.string(),
    email: yup.string(),
    schriftAblage: yup.string(),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        schriftAblage: string;
      },
      formikHelpers: FormikHelpers<{
        name: string;
        namenszusatz: string;
        kundennummer: string;
        umsatzsteuerId: string;
        postfach: string;
        plzPostfach: string;
        plz: string;
        strasse: string;
        ort: string;
        telefon: string;
        fax: string;
        email: string;
        schriftAblage: string;
      }>
    ) => {
      const updatedKunde = await mutateAsync(values);
      const { setSubmitting, setStatus } = formikHelpers;
      navigate("/kunden");
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync]
  );
  return (
    <Layout title={"Kunde"} back="/kunden">
      <Guard
        permission={"kunde:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          <Guard permission={"kunde:delete"}>
            <DeleteKundeButton id={customerId}></DeleteKundeButton>
          </Guard>
          <Formik
            initialValues={{
              name: kunde.name ?? "",
              namenszusatz: kunde.namenszusatz ?? "",
              kundennummer: kunde.kundennummer ?? "",
              umsatzsteuerId: kunde.umsatzsteuerId ?? "",
              postfach: kunde.postfach ?? "",
              plzPostfach: kunde.plzPostfach ?? "",
              plz: kunde.plz ?? "",
              strasse: kunde.strasse ?? "",
              ort: kunde.ort ?? "",
              telefon: kunde.telefon ?? "",
              fax: kunde.fax ?? "",
              email: kunde.email ?? "",
              schriftAblage: kunde.schriftablage ?? "",
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty }) => (
              <>
                <Typography variant="h5">
                  {kunde.geloeschtAm === undefined ? (
                    "Kunde"
                  ) : (
                    <>
                      <>Kunde</>
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={"gelöscht"}
                        color="error"
                      />
                    </>
                  )}
                </Typography>
                <DialogContent>
                  <Form id="details-customer">
                    <KundenFieldsContent></KundenFieldsContent>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="details-customer"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
function useSaveKunde(id: string) {
  const updateKunde = useUpdateKunde();
  const queryClient = useQueryClient();

  return useMutation(
    (input: KundeDaten) => {
      return updateKunde(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["kunden", id]);
      },
    }
  );
}
