// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  MaterialtransportZwischenDaten,
  type MaterialtransportZwischenId,
} from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateMaterialtransportZwischen(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  zwischenId: string,
  requestBody: MaterialtransportZwischenDaten
) => Promise<MaterialtransportZwischenId> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, fuhrparkeintragId, zwischenId, requestBody) =>
      (await getAPI()).materialtransporte.updateMaterialtransportZwischen({
        taetigkeitsberichtId,
        fuhrparkeintragId,
        zwischenId,
        requestBody,
      }),
    [getAPI]
  );
}
