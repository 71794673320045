import { Alert } from "@mui/material";
import { type ReactNode, useEffect, useMemo } from "react";
import {
  AuthProvider as OidcAuthProvider,
  type AuthProviderProps,
  useAuth,
} from "react-oidc-context";
import GlobalLoading from "./components/GlobalLoading";
import { localStoragePrefix, oidcClientId } from "./config";
import { useSystemInformation } from "./system-information";

const locationAfterSigninKey = `${localStoragePrefix}location-after-signin`;

export function AuthProvider({ children }: { children: ReactNode }) {
  const { oidcAuthority } = useSystemInformation();
  const oidcConfig: AuthProviderProps = useMemo(
    () => ({
      authority: oidcAuthority,
      client_id: oidcClientId,
      redirect_uri: `${window.origin}/`,
      // TODO: should we really use the scope `offline_access`?
      scope: "openid profile email offline_access",
      onSigninCallback: () => {
        const href =
          window.sessionStorage.getItem(locationAfterSigninKey) ?? "/";
        window.sessionStorage.removeItem(locationAfterSigninKey);
        window.history.replaceState({}, document.title, href);
      },
    }),
    [oidcAuthority]
  );

  return (
    <OidcAuthProvider {...oidcConfig}>
      <AuthBouncer>{children}</AuthBouncer>
    </OidcAuthProvider>
  );
}

/**
 * The bouncer redirects users to the sign-in page if they are not
 * authenticated, otherwise renders its children.
 */
function AuthBouncer({ children }: { children: ReactNode }) {
  // Liste der Routen, die keine Authentifizierung erfordern
  const externeSystemeRoutes = [
    "/externesysteme/datev",
    "/externesysteme/google",
  ];

  // Prüfen, ob die aktuelle Route in den erlaubten Routen enthalten ist
  const isExterneSystemRoute = externeSystemeRoutes.includes(
    window.location.pathname
  );

  const searchString = window.location.search;

  var parameterString = searchString.replace("code=", "codeExt=");
  parameterString = parameterString.replace("scope=", "scopeExt=");
  parameterString = parameterString.replace("state=", "stateExt=");
  parameterString = parameterString.replace(
    "session_state=",
    "session_stateExt="
  );

  if (isExterneSystemRoute) {
    console.log(
      (window.location.href =
        window.location.origin +
        "/externesysteme/rewritten-datev" +
        parameterString)
    );

    if (window.location.pathname === "/externesysteme/datev") {
      window.location.href =
        window.location.origin +
        "/externesysteme/rewritten-datev" +
        parameterString;
    }
    if (window.location.pathname === "/externesysteme/google") {
      window.location.href =
        window.location.origin +
        "/externesysteme/rewritten-google" +
        parameterString;
    }
  }
  const { isLoading, error, isAuthenticated } = useAuth();
  if (isLoading) return <GlobalLoading />;
  if (error) return <Alert severity="error">{error.message}</Alert>;
  if (!isAuthenticated) return <SignIn />;
  return <>{children}</>;
}

function SignIn() {
  // We don't have a dedicated sign-in page, instead we're using the
  // OAuth 2.0 Implicit Flow to sign in the user. This means we need to
  // redirect them to the authorization server.
  const { signinRedirect } = useAuth();

  useEffect(() => {
    // Store the current URL in session storage so we can redirect back after sign-in.
    window.sessionStorage.setItem(locationAfterSigninKey, window.location.href);
    signinRedirect();
  }, [signinRedirect]);

  return <GlobalLoading />;
}
