import { Field, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useEffect } from "react";

export default function SkontoFields({
  bruttoBetrag,
}: {
  bruttoBetrag: string;
}) {
  const { values, setFieldValue } = useFormikContext();
  const [skontoInProzentField] = useField("skonto1InProzent");
  const [skontoInEuroField] = useField("skontoInEuro");
  useEffect(() => {
    if (skontoInProzentField.value) {
      if (
        skontoInEuroField.value === undefined ||
        skontoInEuroField.value === ""
      ) {
        setFieldValue("skontoInEuro", bruttoBetrag);
      }
    }
  }, [skontoInProzentField, setFieldValue]);
  return (
    <>
      <Field
        component={TextField}
        name="skonto1InProzent"
        label={"Skonto in %"}
        inputProps={{ style: { textAlign: "right" } }}
      ></Field>
      <Field
        component={TextField}
        name="skontoInEuro"
        label={"Skonto in €"}
        inputProps={{ style: { textAlign: "right" } }}
      ></Field>
    </>
  );
}
