import { Form, Formik, FormikHelpers } from "formik";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useUpdateAnbauteil } from "../../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { AnbauteilDaten } from "../../../api";
import { useAnbauteil } from "../../../client/queries";
import AnbauteilFieldsContent from "../../../components/fuhrpark/anbauteil/AnbauteilFieldsContent";
import ErrorAlert from "../../../components/ErrorAlert";
import { notPermitted } from "../../../errors";
import Guard from "../../../components/Guard";

interface AnbauteilDetailsProps {
  onClose: () => void;
  anbauteilId: string;
}

export default function AnbauteilDetails({
  anbauteilId,
  onClose,
}: AnbauteilDetailsProps) {
  const { t } = useTranslation("fuhrpark");
  const anbauteil = useAnbauteil(anbauteilId);
  const { mutateAsync } = useSaveAnbauteil(anbauteilId);

  let schema = yup.object().shape({
    geraeteBezeichnung: yup
      .string()
      .required(capitalize(t("attachment-part-required-title"))),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        geraeteBezeichnung: string;
      },
      formikHelpers: FormikHelpers<{
        geraeteBezeichnung: string;
      }>
    ) => {
      const updatedAnbauteil = await mutateAsync({
        geraeteBezeichnung: values.geraeteBezeichnung,
      });
      const { setSubmitting, setStatus } = formikHelpers;
      onClose();
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate]
  );
  return (
    <Guard
      permission={"fuhrpark:edit"}
      fallback={<ErrorAlert error={notPermitted()} />}
    >
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            geraeteBezeichnung: anbauteil?.geraeteBezeichnung ?? "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty }) => (
            <>
              <Typography variant="h5">
                {capitalize(t("attachment-part"))}
              </Typography>
              <DialogContent>
                <Form id="details-anbauteil">
                  <AnbauteilFieldsContent></AnbauteilFieldsContent>
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="details-anbauteil"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Guard>
  );
}

function useSaveAnbauteil(id: string) {
  const updateAnbauteil = useUpdateAnbauteil();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AnbauteilDaten) => {
      return updateAnbauteil(id, input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["anbauteile"]);
      },
    }
  );
}
