import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import { Kunde } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";
import { AnwesenheitenFilter } from "../anwesenheiten/AnwesenheitenDataTable";

export type KundenFilter = {
  search?: string;
  filtered?: string;
};

export type Props = Omit<DataRequest<KundenFilter>, "filter"> &
  Partial<Pick<DataRequest<KundenFilter>, "filter">>;

export default function KundenDataTable({ ...input }: Props) {
  const request = useDataRequest<KundenFilter>({ filter: {}, ...input });
  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <KundenResults request={request} setFilteredState={setFilteredState} />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<KundenFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search, filtered }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    setField("filtered", filteredStateFilter);
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-kunde");
            }}
          >
            Kunde anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-kunde");
            }}
          >
            Kunde anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function KundenResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<KundenFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}
function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<KundenFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadKunden: DataLoader<KundenFilter, Kunde> = useCallback(
    async (params) =>
      (await getApi()).kunden.sucheKunden({
        ...params,
      }),
    [getApi]
  );
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["kunden"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadKunden}
      onRowClick={(row) => {
        navigate("/kunden/" + row.id);
      }}
      name={"kunden"}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<Kunde>> {
  const { t, capitalize } = useT("customer");

  return useMemo(
    () => [
      {
        field: "name",
        headerName: t("name"),
        flex: 1,
      },
      {
        field: "strasse",
        headerName: t("street"),
        flex: 1,
      },
      {
        field: "plz",
        headerName: t("plz"),
        flex: 1,
      },
      {
        field: "ort",
        headerName: t("city"),
        flex: 1,
      },
      {
        field: "telefon",
        headerName: t("phone"),
        flex: 1,
      },
      {
        field: "email",
        headerName: t("email"),
        flex: 1,
      },
    ],
    [t, capitalize]
  );
}
