import { Box, capitalize, Divider, Paper, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useT } from "../../i18n";
import ZahllaufDataTable from "../../components/zahllauf/ZahllaufDataTable";
import React, { useState } from "react";
import ZahllaufZuweisenModal from "../../components/zahllauf/ZahllaufZuweisenModal";
import ZahlungsdateiGenerierenModal from "../../components/zahllauf/ZahlungsdateiGenerierenModal";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

function ZahllaufListe() {
  const { t } = useT("zahllauf");
  const limit = parseInt(localStorage.getItem("zahllaufLimit") ?? "25", 10);
  const [anzahlAlleRechnungen, setAnzahlAlleRechnungen] = useState<number>(0);
  const [summeAlleRechnungen, setSummeAlleRechnungen] = useState<number>(0);
  const [anzahlSelectedRechnungen, setAnzahlSelectedRechnungen] =
    useState<number>(0);
  const [summeSelectedRechnungen, setSummeSelectedRechnungen] =
    useState<number>(0);
  const [
    beinhaltetNichtFreigegebeneRechnungen,
    setBeinhaltetNichtFreigegebeneRechnungen,
  ] = useState<boolean>(false);
  const [zahllaufZuweisenModalOpened, setZahllaufZuweisenModalOpened] =
    useState<boolean>(false);
  const [
    zahlungsdateiGenerierenModalOpened,
    setZahlungsdateiGenerierenModalOpened,
  ] = useState<boolean>(false);
  const [alleRechnungen, setAlleSelectedRechnungen] = useState<string[]>([]);

  const [aktuelleBezeichnung, setAktuelleBezeichnung] = useState<string>("");
  const [aktuelleZahllaufId, setAktuelleZahllaufId] = useState<string>("");
  const [aktuelleKontoId, setAktuelleKontoId] = useState<string>("");
  const [auswahlZahllaufVariante, setAuswahlZahllaufVariante] =
    useState<string>("new");

  return (
    <Layout>
      <Guard
        permission={"zahllauf:list"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Typography variant="h5">{capitalize(t("paymentrun"))}</Typography>
          <ZahllaufDataTable
            setAnzahlAlleRechnungen={setAnzahlAlleRechnungen}
            setSummeAlleRechnungen={setSummeAlleRechnungen}
            setAnzahlSelectedRechnungen={setAnzahlSelectedRechnungen}
            setSummeSelectedRechnungen={setSummeSelectedRechnungen}
            setBeinhaltetNichtFreigegebeneRechnungen={
              setBeinhaltetNichtFreigegebeneRechnungen
            }
            setAlleSelectedRechnungen={setAlleSelectedRechnungen}
            setZahllaufZuweisenModalOpened={setZahllaufZuweisenModalOpened}
            zahllaufZuweisenModalOpened={zahllaufZuweisenModalOpened}
            setZahlungsdateiGenerierenModalOpened={
              setZahlungsdateiGenerierenModalOpened
            }
            zahlungsdateiGenerierenModalOpened={
              zahlungsdateiGenerierenModalOpened
            }
            limit={limit}
          />
          <Box
            display={"grid"}
            sx={{
              gridTemplateColumns: ["1fr", "2fr 1fr 1fr"],
              gap: 2,
            }}
          >
            <>
              <p></p>
            </>
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3 }}>
              <Box
                display={"flex"}
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  {anzahlSelectedRechnungen.toFixed(0)} gewählt
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography>
                  {summeSelectedRechnungen
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  {" €"}
                </Typography>
              </Box>
            </Paper>
            <Paper elevation={1} sx={{ marginTop: 2, padding: 3 }}>
              <Box
                display={"flex"}
                sx={{
                  justifyContent: "space-between",
                  color: "grey",
                }}
              >
                <Typography>
                  {anzahlAlleRechnungen.toFixed(0)} gesamt
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography>
                  {summeAlleRechnungen
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  {" €"}
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Paper>
      </Guard>
      {zahllaufZuweisenModalOpened && (
        <Guard
          permission={"zahllauf:create"}
          fallback={<ErrorAlert error={notPermitted()} />}
        >
          <ZahllaufZuweisenModal
            aktuelleBezeichnung={aktuelleBezeichnung}
            setAktuelleBezeichnung={setAktuelleBezeichnung}
            setAktuelleZahllaufId={setAktuelleZahllaufId}
            aktuelleZahllaufId={aktuelleZahllaufId}
            auswahlZahllaufVariante={auswahlZahllaufVariante}
            setAuswahlZahllaufVariante={setAuswahlZahllaufVariante}
            setZahllaufZuweisenModalOpened={setZahllaufZuweisenModalOpened}
            anzahlRechnungen={anzahlSelectedRechnungen}
            summeRechnungen={summeSelectedRechnungen}
            rechnungen={alleRechnungen}
            setAktuelleKontoId={setAktuelleKontoId}
            beinhaltetNichtFreigegebeneRechnungen={
              beinhaltetNichtFreigegebeneRechnungen
            }
          />
        </Guard>
      )}
      {zahlungsdateiGenerierenModalOpened && (
        <Guard
          permission={"zahllauf:archiv:edit"}
          fallback={<ErrorAlert error={notPermitted()} />}
        >
          <ZahlungsdateiGenerierenModal
            setAktuelleZahllaufId={setAktuelleZahllaufId}
            aktuelleZahllaufId={aktuelleZahllaufId}
            setAktuelleKontoId={setAktuelleKontoId}
            aktuelleKontoId={aktuelleZahllaufId}
            setZahlungsdateiGenerierenModalOpened={
              setZahlungsdateiGenerierenModalOpened
            }
          />
        </Guard>
      )}
    </Layout>
  );
}

export default ZahllaufListe;
