// Keep all configurable values in this file!

export const localStoragePrefix = "zeller-bip:";

// @ts-ignore
export const apiUrl = ENVJS_API_URL ?? "http://localhost:8080";
// @ts-ignore
export const appUrl = ENVJS_APP_URL ?? "http://localhost:5173";

// @ts-ignore
export const version = ENVJS_APP_VERSION ?? "dev";
// @ts-ignore
export const applicationVersion = ENVJS_APP_VERSION ?? "dev";

export const applicationVersionTime =
  // @ts-ignore
  ENVJS_APP_VERSION_TIMESTAMP ??
  new Date().toLocaleString("de-DE", { timeZone: "Europe/Berlin" });

export const oidcClientId =
  import.meta.env.VITE_OIDC_CLIENT_ID ?? "zeller-bip-app";

export const drawerWidth = 340;
