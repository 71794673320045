import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { Erzeuger } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";
import { KundenFilter } from "../kunden/KundenDataTable";

export type ErzeugerFilter = {
  search?: string;
  filtered?: string;
};

export type Props = Omit<DataRequest<ErzeugerFilter>, "filter"> &
  Partial<Pick<DataRequest<ErzeugerFilter>, "filter">>;

export default function ErzeugerDataTable({ ...input }: Props) {
  const request = useDataRequest<ErzeugerFilter>({ filter: {}, ...input });
  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <ErzeugerResults request={request} setFilteredState={setFilteredState} />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<ErzeugerFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    setField("filtered", filteredStateFilter);
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-erzeuger");
            }}
          >
            Erzeuger anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-erzeuger");
            }}
          >
            Erzeuger anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function ErzeugerResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<ErzeugerFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}
function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<ErzeugerFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadErzeuger: DataLoader<ErzeugerFilter, Erzeuger> = useCallback(
    async (params) => (await getApi()).erzeuger.sucheErzeuger({ ...params }),
    [getApi]
  );

  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["erzeuger"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadErzeuger}
      onRowClick={(row) => {
        navigate("/erzeuger/" + row.id);
      }}
      name={"erzeuger"}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<Erzeuger>> {
  const { t } = useT("customer");

  return useMemo(
    () => [
      {
        field: "datum",
        headerName: t("date"),
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "nummer",
        headerName: t("number"),
      },
      {
        field: "name",
        headerName: t("name"),
        flex: 5,
      },
    ],
    [t]
  );
}
