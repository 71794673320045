// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Materialtransport, MaterialtransportDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useUpdateMaterialtransport(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  materialtransportId: string,
  requestBody: MaterialtransportDaten
) => Promise<Materialtransport> {
  const getAPI = useGetApi();
  return useCallback(
    async (
      taetigkeitsberichtId,
      fuhrparkeintragId,
      materialtransportId,
      requestBody
    ) =>
      (await getAPI()).materialtransporte.updateMaterialtransport({
        taetigkeitsberichtId,
        fuhrparkeintragId,
        materialtransportId,
        requestBody,
      }),
    [getAPI]
  );
}
