import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Konto, KontenPage } from "../../api";
import { useGetKonto, useSucheKonten } from "../../client/hooks";

interface KontoSelectProps extends FieldProps {
  label: string;
  required?: boolean;
}

function KontoSelect({ label, required, field, form, meta }: KontoSelectProps) {
  const searchKonten = useSucheKonten();
  const ladeKonto = useGetKonto();
  const [optionen, setOptionen] = useState<ReadonlyArray<Konto>>([]);
  const [inputValue, setInputValue] = useState(field.value);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (field.value && typeof field.value === "string") {
      ladeKonto(field.value)
        .then((konto) => {
          form.setFieldValue(field.name, konto);
          setInputValue(konto);
        })
        .catch((error) => {
          console.error("Error loading konto:", error);
        });
    }
  }, [field.value]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: KontenPage) => void
        ) =>
          searchKonten({
            search: request.input,
            page: 0,
            limit: 20,
            sort: "kontoinhaber",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchKonten]
  );

  useEffect(() => {
    ladeOptionen({ input: searchValue }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([...(results ? results.items : [])]);
    });
  }, [searchValue, ladeOptionen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string | Konto) => {
        if (typeof option === "string") {
          return option;
        } else {
          return formatIBAN(option.iban);
        }
      }}
      onChange={(event, value) => {
        setInputValue(value);
      }}
      onInputChange={(event, value) => {
        setSearchValue(value);
      }}
      renderOption={(props, option: Konto) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={formatIBAN(option.iban)}
              secondary={[
                option.kontoinhaber,
                option.bankname,
                option.bic ?? formatIBAN(option.iban),
              ]
                .filter(Boolean)
                .join(", ")}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

function formatIBAN(iban: string) {
  return iban.replace(/(.{4})/g, "$1 ");
}

export default KontoSelect;
