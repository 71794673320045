import {
  Box,
  Button,
  Chip,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import DataTable from "../data/DataTable";
import { useGetApi } from "../../client";
import { Entsorger, EntsorgerInfo } from "../../api";
import CheckIcon from "@mui/icons-material/Check";
import DataTableWithFilter from "../data/DataTableWithFilter";
import { ErzeugerFilter } from "../erzeuger/ErzeugerDataTable";

export type EntsorgerFilter = {
  search?: string;
  filtered?: string;
};

export type Props = Omit<DataRequest<EntsorgerFilter>, "filter"> &
  Partial<Pick<DataRequest<EntsorgerFilter>, "filter">>;

export default function EntsorgerDataTable({ ...input }: Props) {
  const request = useDataRequest<EntsorgerFilter>({ filter: {}, ...input });
  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <EntsorgerResults request={request} setFilteredState={setFilteredState} />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<EntsorgerFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    setField("filtered", filteredStateFilter);
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-entsorger");
            }}
          >
            Entsorger anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-entsorger");
            }}
          >
            Entsorger anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function EntsorgerResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<EntsorgerFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}
function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<EntsorgerFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadEntsorger: DataLoader<EntsorgerFilter, EntsorgerInfo> = useCallback(
    async (params) => (await getApi()).entsorger.sucheEntsorger({ ...params }),
    [getApi]
  );
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["entsorger"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadEntsorger}
      onRowClick={(row) => {
        navigate("/entsorger/" + row.id);
      }}
      name={"entsorger"}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<EntsorgerInfo>> {
  const { t } = useT("entsorger");

  return useMemo(
    () => [
      {
        field: "datum",
        headerName: t("date"),
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "verbleibortKurzname",
        headerName: "Verbleibort",
        flex: 1,
      },
      {
        field: "nummer",
        headerName: t("en-number"),
        flex: 1,
      },
      {
        field: "materialienName",
        headerName: "Abfallart",
        flex: 1,
      },
      {
        field: "abfallartKey",
        headerName: "EAK-Schlüssel",
        flex: 1,
      },
      {
        field: "archiviert",
        headerName: t("archived"),
        flex: 1,
        type: "boolean",
        renderCell: (params) => {
          if (params.value === true) {
            return (
              <Chip
                icon={<CheckIcon />}
                label={t("archived")}
                color="secondary"
              />
            );
          } else {
            return "";
          }
        },
      },
    ],
    [t]
  );
}
