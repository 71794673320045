import { useT } from "../../i18n";
import { Field, useField, useFormikContext } from "formik";
import {
  Box,
  capitalize,
  FormControlLabel,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Select, TextField } from "formik-mui";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import BenutzerSelect from "../benutzer/BenutzerSelect";
export default function AnwesenheitFieldsContent({
  isVerwaltungAnwesenheit,
  startzeit,
  endzeit,
}: {
  isVerwaltungAnwesenheit: boolean;
  startzeit: string | null;
  endzeit: string | null;
}) {
  const { t } = useT("entsorger");
  const { setFieldValue } = useFormikContext();
  const [starttime, setStarttime] = useState<Dayjs | null>(
    startzeit ? dayjs(startzeit) : null
  );
  const [endtime, setEndtime] = useState<Dayjs | null>(
    endzeit ? dayjs(endzeit) : null
  );
  const setStart = (value: string) => {
    setFieldValue("start", value);
  };
  const setEnde = (value: string) => {
    setFieldValue("ende", value);
  };
  const [unbezahltePause] = useField("unbezahltePause");
  const [fahrzeitenField] = useField("fahrzeiten");
  useEffect(() => {
    if (starttime !== null && endtime !== null) {
      const bruttoArbeitszeitInMinuten = endtime.diff(starttime, "minute");
      const nettoArbeitszeitInMinuten =
        bruttoArbeitszeitInMinuten -
        parseFloat(unbezahltePause.value) +
        parseFloat(fahrzeitenField.value);
      const arbeitszeitInStundenDezimal = nettoArbeitszeitInMinuten / 60;
      const arbeitszeitBruttoInStundenDezimal = bruttoArbeitszeitInMinuten / 60;
      setFieldValue("arbeitszeitBrutto", arbeitszeitBruttoInStundenDezimal);
      setFieldValue("arbeitszeitNetto", arbeitszeitInStundenDezimal);
    }
  }, [starttime, endtime, unbezahltePause.value, fahrzeitenField.value]);
  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          component={TextField}
          label={t("date") + " *"}
          name="datum"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        {isVerwaltungAnwesenheit && (
          <Field
            component={BenutzerSelect}
            label={"Mitarbeiter *"}
            name="mitarbeiter"
          />
        )}
      </Box>
      <Typography sx={{ mt: 2 }}>Einsatz von - bis</Typography>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          {starttime ? (
            <TimePicker
              label={"Startzeit"}
              ampm={false}
              value={starttime}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setStart(date.toISOString());
                    setStarttime(date);
                  }
                }
              }}
            ></TimePicker>
          ) : (
            <TimePicker
              label={"Startzeit"}
              ampm={false}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setStart(date.toISOString());
                    setStarttime(date);
                  }
                }
              }}
            ></TimePicker>
          )}
          {endtime ? (
            <TimePicker
              label={"Endzeit"}
              ampm={false}
              value={endtime}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setEnde(date.toISOString());
                    setEndtime(date);
                  }
                }
              }}
            ></TimePicker>
          ) : (
            <TimePicker
              label={"Endzeit"}
              ampm={false}
              onChange={(date) => {
                if (date !== null && dayjs.isDayjs(date)) {
                  if (date.isValid()) {
                    setEnde(date.toISOString());
                    setEndtime(date);
                  }
                }
              }}
            ></TimePicker>
          )}
        </LocalizationProvider>
      </Box>
      <Typography sx={{ mt: 2 }}>Pausen</Typography>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"unbezahlte Pause in Minuten"}
          name="unbezahltePause"
          type={"number"}
          inputProps={{ style: { textAlign: "right" } }}
        />
        <Field
          component={TextField}
          label={"bezahlte Pause in Minuten"}
          name="bezahltePause"
          type={"number"}
          inputProps={{ style: { textAlign: "right" } }}
        />
      </Box>
      <Typography sx={{ mt: 2 }}>Arbeitszeiten</Typography>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"Netto Arbeitszeit"}
          name="arbeitszeitNetto"
          type={"number"}
          inputProps={{ readOnly: true, style: { textAlign: "right" } }}
          disabled
        />
        <Field
          component={TextField}
          label={"Brutto Arbeitszeit"}
          name="arbeitszeitBrutto"
          type={"number"}
          inputProps={{ readOnly: true, style: { textAlign: "right" } }}
          disabled
        />
      </Box>
      <Typography sx={{ mt: 2 }}>Fahrzeiten</Typography>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={TextField}
          label={"Fahrzeiten in Minuten"}
          name="fahrzeiten"
          type={"number"}
          inputProps={{ style: { textAlign: "right" } }}
        />
      </Box>
    </Stack>
  );
}
