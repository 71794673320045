// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Abwesenheit, AbwesenheitDaten } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useCreateAbwesenheit(): (
  requestBody: AbwesenheitDaten
) => Promise<Abwesenheit> {
  const getAPI = useGetApi();
  return useCallback(
    async (requestBody) =>
      (await getAPI()).abwesenheiten.createAbwesenheit({ requestBody }),
    [getAPI]
  );
}
