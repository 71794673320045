import { Box, Stack } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { useT } from "../../i18n";
import KundenSelect from "../kunden/KundenSelect";
import BaustellenKundenSelect from "../baustellen/BaustellenKundenSelect";
import { TextField } from "formik-mui";
import Buchungsart from "../Buchungsart";
import { useState } from "react";

export default function AusgangsrechnungenFieldsContent({
  buchungsart,
}: {
  buchungsart: string;
}) {
  const [isKosten, setIsKosten] = useState(buchungsart === "Kosten");
  const { t } = useT("ausgangsrechnung");
  const { setFieldValue } = useFormikContext();

  return (
    <Stack direction="column">
      <Buchungsart isKosten={isKosten} fieldName={"buchungsart"} />
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field
          component={BaustellenKundenSelect}
          name="baustelle"
          label={t("construction-site")}
        />
        <Field component={KundenSelect} name="kunde" label={t("customer")} />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field
          component={TextField}
          name="rechnungsNummer"
          label={t("invoice-number")}
        />
        <Field
          component={TextField}
          name="faelligkeitsDatum"
          label={t("date")}
          type={"date"}
          inputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field component={TextField} name="lfsNr" label={t("lfs-No")} />
        <Field
          component={TextField}
          name="gesamtPreis"
          label={t("total-price")}
          onFocus={(event: any) => {
            const formattedValue = event.target.value;
            if (formattedValue) {
              setFieldValue(
                "gesamtPreis",
                formattedValue
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
            }
          }}
          inputProps={{ style: { textAlign: "right" } }}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <Field component={TextField} name="bemerkung" label={t("remark")} />
      </Box>
    </Stack>
  );
}
