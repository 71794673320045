import {
  Box,
  capitalize,
  FormControlLabel,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useT } from "../../i18n";
import AbladestellenSelect from "../abladestellen/AbladestellenSelect";
import MaterialSelect from "../material/MaterialSelect";
interface EntsorgerFormValues {
  datum: string;
  nummer: string;
  archiviert: boolean;
  verbleibort: any;
  materialienId?: string;
  abfallartKey: string;
}

export default function EntsorgerFieldsContent() {
  const { t } = useT("entsorger");
  const { values } = useFormikContext<EntsorgerFormValues>();

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2 }}
      >
        <Field
          component={TextField}
          label={t("date") + " *"}
          name="datum"
          type="date"
        />
        <Field
          component={TextField}
          label={t("en-number") + " *"}
          name="nummer"
        />
        <FormControlLabel
          control={
            <Field
              name={"archiviert"}
              as={Switch}
              color="primary"
              type="checkbox"
            />
          }
          label={capitalize(t("archived"))}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"], gap: 2, mt: 2 }}
      >
        <Field
          component={AbladestellenSelect}
          label={"Verbleibort"}
          name="verbleibort"
        />

        {values.verbleibort ? (
          <Field
            component={MaterialSelect}
            label={"Abfallart"}
            name="abfallart"
            abladestelleId={values.verbleibort?.id}
          />
        ) : (
          <>
            <Field
              component={Select}
              label={"Abfallart"}
              name="abfallart"
              disabled
              abladestelleId={values.verbleibort?.id}
            />
          </>
        )}
        <Stack>
          <Typography color="grey">EAK-Schlüssel</Typography>
          <ListItemText primary={values.abfallartKey} />
        </Stack>
      </Box>
    </Stack>
  );
}
