import { Box, Button, capitalize, IconButton, Stack } from "@mui/material";
import { FastField } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React from "react";
import { useDeleteMaschinentransport } from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ActionTimestamp, Buchungstyp } from "../../../../api";
import FahrzeugSelect from "../../../fuhrpark/fahrzeug/FahrzeugSelect";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import Grid from "@mui/material/Grid2";

interface MaschinentransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  baustelleId: string;
  transportiertesFahrzeug: any;
  fuhrTyp: Buchungstyp;
  bemerkung?: string;
  lieferscheinNummer?: string;
  abholortBaustelle?: any;
  abstellortBaustelle?: any;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

export default function MaschinentransportFieldsContent({
  index,
  maschinenTransportIndex,
  push,
  remove,
  newestIndex,
  maschinentransport,
}: {
  index: number;
  maschinenTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  maschinentransport: MaschinentransportDaten;
}) {
  const { t } = useT("taetigkeitsbericht");

  const deleteMaschinentransport = useDeleteMaschinentransport();

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {capitalize(t("vehicle-transport"))}
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              component={FahrzeugSelect}
              name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.transportiertesFahrzeug`}
              label={capitalize(t("vehicle-transport-vehicle"))}
              required
              fahrzeugHerkunft={"EIGEN_GERAET"}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              component={BaustellenSelect}
              name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.abholortBaustelle`}
              label={capitalize(t("vehicle-transport-from"))}
              required
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              component={BaustellenSelect}
              name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.abstellortBaustelle`}
              label={capitalize(t("vehicle-transport-to"))}
              required
            />
          </Grid>

          <Grid size={{ xs: 3, md: 3 }} container>
            {maschinenTransportIndex === newestIndex ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  push({
                    transportiertesFahrzeug: "",
                    abholortBaustelle: "",
                    abstellortBaustelle: "",
                    lieferscheinNummer: "",
                    bemerkung: "",
                  });
                }}
              >
                <AddCircleIcon />
                {t("vehicle-transport-tr")}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={capitalize(t("vehicle-transport-lfs"))}
              name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.lieferscheinNummer`}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={capitalize(t("vehicle-transport-remark"))}
              name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.bemerkung`}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Box></Box>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Button
              sx={{ color: "gray" }}
              onClick={() => {
                const maschinentransportId = maschinentransport.id;
                if (maschinentransportId) {
                  deleteMaschinentransport(
                    maschinentransport.taetigkeitsberichtId,
                    maschinentransport.fuhrparkeintragId,
                    maschinentransportId
                  ).then(() => {
                    remove(maschinenTransportIndex);
                  });
                } else {
                  remove(maschinenTransportIndex);
                }
              }}
              aria-label="delete"
            >
              <DeleteIcon />
              {t("vehicle-transport-tr")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
