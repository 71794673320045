/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Personaleintrag } from '../models/Personaleintrag';
import type { PersonaleintragDaten } from '../models/PersonaleintragDaten';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersonaleintraegeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Bekomme einen Personaleintrag
   * @returns Personaleintrag Der Personaleintrag
   * @throws ApiError
   */
  public getPersonaleintrag({
    taetigkeitsberichtId,
  }: {
    taetigkeitsberichtId: string,
  }): CancelablePromise<Personaleintrag> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/personaleintraege',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Personaleintrag der angelegte Personaleintrag
   * @throws ApiError
   */
  public createPersonaleintrag({
    taetigkeitsberichtId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    requestBody: PersonaleintragDaten,
  }): CancelablePromise<Personaleintrag> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/personaleintraege',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Aktualisiere einen Personaleintrag
   * @returns Personaleintrag Der aktualisierte Personaleintrag
   * @throws ApiError
   */
  public updatePersonaleintrag({
    taetigkeitsberichtId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    requestBody: PersonaleintragDaten,
  }): CancelablePromise<Personaleintrag> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/personaleintraege',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Personaleintrag der gelöschte Personaleintrag
   * @throws ApiError
   */
  public deletePersonaleintrag({
    taetigkeitsberichtId,
  }: {
    taetigkeitsberichtId: string,
  }): CancelablePromise<Personaleintrag> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/personaleintraege',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
