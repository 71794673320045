import { Form, Formik, FormikHelpers, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Layout from "../../components/Layout";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
  IconButton,
  Grid,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import AbladestelleFieldsContent from "../../components/abladestellen/AbladestelleFieldsContent";
import { useCreateAbladestelle, useCreateMaterial } from "../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AbfallartenSelect from "../../components/abfallarten/AbfallartenSelect";
import TextField from "@mui/material/TextField";
import { Abfallart } from "../../api";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";
import AbfallartenEAKSelect from "../../components/abfallarten/AbfallartenEAKSelect";

interface Material {
  name: string;
  archiviert: boolean;
  abfallart: any;
  abfallartKey?: string;
  kostenProTonne?: string;
  kostenProKubikmeter?: string;
}

interface ExtendedAbladestelleDaten {
  kurzname: string;
  firma: string;
  zusatz: string;
  ort: string;
  isDeponie: boolean;
  materials: Material[];
}

const validationSchema = Yup.object().shape({
  kurzname: Yup.string().required("Kurzname ist erforderlich"),
  firma: Yup.string(),
  ort: Yup.string(),
  isDeponie: Yup.boolean(),
  materials: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name ist erforderlich"),
        archiviert: Yup.boolean(),
        abfallart: Yup.object().required("Abfallart ist erforderlich"),
        kostenProTonne: Yup.string(),
        kostenProKubikmeter: Yup.string(),
      })
    )
    .min(0, ""),
});

export default function ErstelleAbladestelle() {
  const { t } = useTranslation("abladestelle");
  const createAbladestelle = useCreateAbladestelle();
  const createMaterial = useCreateMaterial();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: ExtendedAbladestelleDaten & { materials: Material[] },
      formikHelpers: FormikHelpers<
        ExtendedAbladestelleDaten & { materials: Material[] }
      >
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      try {
        // Create Abladestelle first
        const abladestelle = await createAbladestelle({
          kurzname: values.kurzname,
          firma: values.firma,
          zusatz: values.zusatz,
          ort: values.ort,
          isDeponie: values.isDeponie,
        });

        // Create each material
        const materialPromises = values.materials.map((material) => {
          const kostenProTonne = material.kostenProTonne
            ? parseFloat(material.kostenProTonne.replace(",", "."))
            : undefined;
          const kostenProKubikmeter = material.kostenProKubikmeter
            ? parseFloat(material.kostenProKubikmeter.replace(",", "."))
            : undefined;
          const abfallartId = (material.abfallart as Abfallart)?.id ?? null;
          const materialData = {
            abladestelleId: abladestelle.id,
            name: material.name,
            abfallartId: abfallartId,
            archiviert: material.archiviert,
            kostenProTonne: kostenProTonne,
            kostenProKubikmeter: kostenProKubikmeter,
          };
          return createMaterial(materialData);
        });

        await Promise.all(materialPromises);

        navigate(`/abladestellen`);
        setStatus(undefined);
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, createAbladestelle, createMaterial, t]
  );

  return (
    <Layout title={t("Erstelle Abladestelle")} back="/abladestellen">
      <Guard
        permission={"abladestelle:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik<ExtendedAbladestelleDaten & { materials: Material[] }>
            initialValues={{
              kurzname: "",
              firma: "",
              zusatz: "",
              ort: "",
              isDeponie: false,
              materials: [],
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              isSubmitting,
              isValidating,
              isValid,
              dirty,
              values,
              status,
              setFieldValue,
            }) => (
              <>
                <Typography variant="h5">
                  {t("Erstelle Abladestelle")}
                </Typography>
                {status && (
                  <Typography color="error" variant="body1">
                    {status}
                  </Typography>
                )}
                <DialogContent>
                  <Form id="new-abladestelle">
                    <AbladestelleFieldsContent />
                    <FieldArray name="materials">
                      {({ push, remove }) => (
                        <>
                          {values.materials.map((material, index) => (
                            <Box key={index} sx={{ marginTop: 2 }}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Field
                                    name={`materials.${index}.name`}
                                    as={TextField}
                                    label={t("Material Name")}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Field
                                    name={`materials.${index}.abfallart`}
                                    component={AbfallartenEAKSelect}
                                    label="Abfallart"
                                    fullWidth
                                    required
                                    keyField={`materials.${index}.abfallartKey`}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Stack>
                                    <Typography color={"textSecondary"}>
                                      EAK-Schlüssel
                                    </Typography>
                                    <Box>
                                      {values.materials[index].abfallartKey}
                                    </Box>
                                  </Stack>
                                </Grid>
                                <Grid item xs={1.5}>
                                  <Field
                                    name={`materials.${index}.kostenProTonne`}
                                    as={TextField}
                                    label={t("price-per-tons")}
                                    inputProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid item xs={1.5}>
                                  <Field
                                    name={`materials.${index}.kostenProKubikmeter`}
                                    as={TextField}
                                    label={t("price-per-cubic")}
                                    inputProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        name={`materials.${index}.archiviert`}
                                        as={Switch}
                                        color="primary"
                                      />
                                    }
                                    label={t("Archiviert")}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    onClick={() => remove(index)}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                          <Box sx={{ marginTop: 2 }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                push({
                                  name: "",
                                  archiviert: false,
                                  abfallart: "",
                                })
                              }
                            >
                              {t("Material hinzufügen")}
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="new-abladestelle"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
