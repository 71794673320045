import { capitalize, Paper, Stack } from "@mui/material";
import { FastField } from "formik";
import { TextField } from "formik-mui";
import ZahllaufSelect from "./ZahllaufSelect";
import { useT } from "../../i18n";

interface ZahllaufZuweisenModalFieldsContentProps {
  setAktuelleBezeichnung: (value: string) => void;
  setAktuelleZahllaufId: (value: string) => void;
  auswahlZahllaufVariante: string;
}

function ZahllaufZuweisenModalFieldsContent({
  auswahlZahllaufVariante,
}: ZahllaufZuweisenModalFieldsContentProps) {
  const { t } = useT("zahllauf");

  return (
    <Paper sx={{ marginTop: 2, padding: 3 }}>
      {auswahlZahllaufVariante === "new" ? (
        <Stack sx={{ gap: 4 }}>
          <FastField
            component={TextField}
            name="bezeichnung"
            label={capitalize(t("description"))}
            required
          />
        </Stack>
      ) : (
        <>
          <Stack sx={{ gap: 4 }}>
            <FastField
              component={ZahllaufSelect}
              name="zahllauf"
              label={capitalize(t("paymentrun"))}
              required
              zahllaufBezahlt={false}
            />
          </Stack>
        </>
      )}
    </Paper>
  );
}

export default ZahllaufZuweisenModalFieldsContent;
