import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Select,
} from "@mui/material";
import { FastField, Field, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React, { useEffect, useState } from "react";
import {
  useDeleteMaterialtransportZwischen,
  useGetFahrzeug,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Abfallart,
  ActionTimestamp,
  Baustelle,
  Buchungstyp,
  EinheitenListe,
} from "../../../../api";
import Buchungsart from "../../../Buchungsart";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";
import AbfallartenSelect from "../../../abfallarten/AbfallartenSelect";
import BemerkungNachEakUndBaustelleSelect from "./BemerkungNachEakUndBaustelleSelect";
import Grid from "@mui/material/Grid2";
import EinheitSelect from "../../../einheiten/EinheitSelect";

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelle?: any;
  zielAbladestelle?: any;
  quelleBaustelleId?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeugerId?: string;
  abfallart?: any;
}

export default function MaterialtransportFieldsContent({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: MaterialTransportDaten;
  fahrzeugId: string;
}) {
  const { t } = useT("fuhrpark");
  const deleteMaterialtransportZwischen = useDeleteMaterialtransportZwischen();
  const { setFieldValue } = useFormikContext();
  const [isKosten, setIsKosten] = useState(
    materialtransport.buchungsArt === "Kosten"
  );

  const getfahrzeug = useGetFahrzeug();
  const [mengeField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.menge`
  );
  const [preisField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.kostenProEinheit`
  );
  const [fuhrenField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.anzahlFuhren`
  );
  const [baustelleField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.zielBaustelle`
  );
  const [abfallartField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.abfallart`
  );
  const [einheitField] = useField(
    `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.einheit`
  );

  const [currentfahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  useEffect(() => {
    setFieldValue(
      `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.mengeGesamt`,
      mengeField.value * fuhrenField.value
    );
    setFieldValue(
      `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.preisGesamt`,
      (mengeField.value * fuhrenField.value * preisField.value).toFixed(2)
    );
  }, [mengeField.value, fuhrenField.value, preisField.value]);
  useEffect(() => {
    if (!materialtransport.id) {
      if (einheitField.value === "CB") {
        if (fahrzeugId) {
          getfahrzeug(fahrzeugId).then((fahrzeug) => {
            if ((fahrzeug?.ladeMenge ?? 0) > 0) {
              setFieldValue(
                `fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.menge`,
                fahrzeug.ladeMenge ?? ""
              );
            }
          });
        }
      }
    }
  }, [einheitField.value]);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {t("material-transport-between-sites")}
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              component={BaustellenSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.zielBaustelle`}
              label={t("vehicle-unloading-point")}
              required
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              component={AbfallartenSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.abfallart`}
              label={t("eak-key")}
              showKeyHeader={true}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Box></Box>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }} container>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                push({
                  quelleAbladestelleId: "",
                  meLadeVolumenFahrzeug: currentfahrzeugLadeMenge ?? 0,
                  anzahlFuhren: 1,
                  lieferscheinNummer: "",
                  bemerkung: "",
                  buchungsArt: "Kosten",
                });
              }}
            >
              <AddCircleIcon />
              {t("vehicle-transport-ab-ma")}
            </Button>
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("vehicle-number-of-trips")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.anzahlFuhren`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={EinheitSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.einheit`}
              label={t("unit")}
              required
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("quantity")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.menge`}
              required
              type={"number"}
              inputProps={{ step: 1, style: { textAlign: "right" } }}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-quantity")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.mengeGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("price")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.kostenProEinheit`}
              required
              type={"number"}
              inputProps={{ step: 0.01, style: { textAlign: "right" } }}
            />
          </Grid>
          <Grid size={{ xs: 1.5, md: 1.5 }}>
            <FastField
              component={TextField}
              label={t("total-price")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.preisGesamt`}
              type={"number"}
              inputProps={{
                step: 0.01,
                readOnly: true,
                style: { textAlign: "right" },
              }}
              inputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Button
              sx={{ color: "gray" }}
              onClick={() => {
                const materialransportId = materialtransport.id;
                if (materialransportId) {
                  deleteMaterialtransportZwischen(
                    materialtransport.taetigkeitsberichtId,
                    materialtransport.fuhrparkeintragId,
                    materialransportId
                  ).then(() => {
                    remove(materialTransportIndex);
                  });
                } else {
                  remove(materialTransportIndex);
                }
              }}
              aria-label="delete"
            >
              <DeleteIcon />
              {t("vehicle-transport-ab-ma")}
            </Button>
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <FastField
              fullWidth
              component={TextField}
              label={t("vehicle-lfsNo")}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.lieferscheinNummer`}
            />
          </Grid>
          <Grid size={{ xs: 3, md: 3 }}>
            <Field
              component={BemerkungNachEakUndBaustelleSelect}
              name={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.bemerkung`}
              label={t("vehicle-remark")}
              baustelleId={
                typeof baustelleField.value === "string"
                  ? baustelleField.value
                  : (baustelleField.value as Baustelle)?.id ?? ""
              }
              abfallartId={
                typeof abfallartField.value === "string"
                  ? abfallartField.value
                  : (abfallartField.value as Abfallart)?.id ?? ""
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialtransport.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}
