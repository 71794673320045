/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Entsorger } from '../models/Entsorger';
import type { EntsorgerDaten } from '../models/EntsorgerDaten';
import type { EntsorgerInfo } from '../models/EntsorgerInfo';
import type { EntsorgerListe } from '../models/EntsorgerListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EntsorgerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns EntsorgerListe Liste der Entsorger
   * @throws ApiError
   */
  public sucheEntsorger({
    search,
    filtered,
    page,
    limit,
    sort,
    isArchiviert,
  }: {
    search?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
    isArchiviert?: boolean,
  }): CancelablePromise<EntsorgerListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/entsorger',
      query: {
        'search': search,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
        'isArchiviert': isArchiviert,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Entsorger der angelegte Entsorger
   * @throws ApiError
   */
  public createEntsorger({
    requestBody,
  }: {
    requestBody: EntsorgerDaten,
  }): CancelablePromise<Entsorger> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/entsorger',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns EntsorgerInfo Entsorger
   * @throws ApiError
   */
  public getEntsorger({
    entsorgerId,
  }: {
    entsorgerId: string,
  }): CancelablePromise<EntsorgerInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/entsorger/{entsorgerId}',
      path: {
        'entsorgerId': entsorgerId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Entsorger der aktualisierte Entsorger
   * @throws ApiError
   */
  public updateEntsorger({
    entsorgerId,
    requestBody,
  }: {
    entsorgerId: string,
    requestBody: EntsorgerDaten,
  }): CancelablePromise<Entsorger> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/entsorger/{entsorgerId}',
      path: {
        'entsorgerId': entsorgerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Entsorger der gelöschte Entsorger
   * @throws ApiError
   */
  public deleteEntsorger({
    entsorgerId,
  }: {
    entsorgerId: string,
  }): CancelablePromise<Entsorger> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/entsorger/{entsorgerId}',
      path: {
        'entsorgerId': entsorgerId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
