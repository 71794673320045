import { FormControlLabel, FormLabel, Radio, Stack } from "@mui/material";
import { RadioGroup } from "formik-mui";
import { Field } from "formik";
import { useState } from "react";
import { useT } from "../i18n";

interface BuchungsartProps {
  fieldName: string;
  isKosten: boolean;
}

export default function Buchungsart({ isKosten, fieldName }: BuchungsartProps) {
  const [isErtrag, setIsErtrag] = useState<boolean>(!isKosten);
  const { t } = useT("ausgangsrechnung");
  return (
    <Field component={RadioGroup} name={fieldName}>
      <FormLabel id="demo-radio-buttons-group-label">Buchungsart</FormLabel>
      <Stack direction={"row"}>
        <FormControlLabel
          value="Ertrag"
          control={<Radio disabled={false} checked={isErtrag} />}
          label={t("profit")}
          disabled={false}
          onChange={() => setIsErtrag(!isErtrag)}
        />
        <FormControlLabel
          value="Kosten"
          control={<Radio disabled={false} checked={!isErtrag} />}
          label={t("cost")}
          disabled={false}
          onChange={() => setIsErtrag(!isErtrag)}
        />
      </Stack>
    </Field>
  );
}
