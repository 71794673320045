import { capitalize, Paper, Typography } from "@mui/material";
import Layout from "../../../components/Layout";
import FahrzeugDataTable from "../../../components/fuhrpark/fahrzeug/FahrzeugDataTable";
import { useT } from "../../../i18n";
import ErrorAlert from "../../../components/ErrorAlert";
import { notPermitted } from "../../../errors";
import React from "react";
import Guard from "../../../components/Guard";

export default function Fahrzeuge() {
  const { t } = useT("fuhrpark");
  const limit = parseInt(localStorage.getItem("fahrzeugeLimit") ?? "25", 10);
  return (
    <Layout>
      <Guard
        permission={"fuhrpark:list"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Typography variant="h5">{capitalize(t("vehicles"))}</Typography>
          <FahrzeugDataTable limit={limit} />
        </Paper>
      </Guard>
    </Layout>
  );
}
