// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type ZugewiesenesFahrzeugAnbauteil } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useGetZugewiesenesFahrzeugAnbauteil(): (
  fahrzeugId: string,
  anbauteilId: string
) => Promise<ZugewiesenesFahrzeugAnbauteil> {
  const getAPI = useGetApi();
  return useCallback(
    async (fahrzeugId, anbauteilId) =>
      (await getAPI()).fahrzeuge.getZugewiesenesFahrzeugAnbauteil({
        fahrzeugId,
        anbauteilId,
      }),
    [getAPI]
  );
}
