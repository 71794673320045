/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeponieDaten } from '../models/DeponieDaten';
import type { DeponieListe } from '../models/DeponieListe';
import type { Materialtransport } from '../models/Materialtransport';
import type { MaterialtransportBemerkungListe } from '../models/MaterialtransportBemerkungListe';
import type { MaterialtransportDaten } from '../models/MaterialtransportDaten';
import type { MaterialtransportPage } from '../models/MaterialtransportPage';
import type { MaterialtransportZwischenDaten } from '../models/MaterialtransportZwischenDaten';
import type { MaterialtransportZwischenId } from '../models/MaterialtransportZwischenId';
import type { MaterialZwischenInfo } from '../models/MaterialZwischenInfo';
import type { MaterialZwischenListe } from '../models/MaterialZwischenListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MaterialtransporteService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Durchsuche alle Materialtransporte zu einem bestimmten Fuhrparkeintrag.
   * @returns MaterialtransportPage Die Seite der Materialtransporte.
   * @throws ApiError
   */
  public sucheMaterialtransporte({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    search,
    page,
    limit,
    sort,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<MaterialtransportPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Materialtransport der angelegte Materialtransport
   * @throws ApiError
   */
  public createMaterialtransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    requestBody: MaterialtransportDaten,
  }): CancelablePromise<Materialtransport> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Materialtransporte zu einem bestimmten Fuhrparkeintrag.
   * @returns MaterialtransportPage Die Seite der Materialtransporte.
   * @throws ApiError
   */
  public sucheMaterialtransporteAnfuhr({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    search,
    page,
    limit,
    sort,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<MaterialtransportPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/anfuhr',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Materialtransporte zu einem bestimmten Fuhrparkeintrag.
   * @returns MaterialtransportPage Die Seite der Materialtransporte.
   * @throws ApiError
   */
  public sucheMaterialtransporteAbtransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    search,
    page,
    limit,
    sort,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<MaterialtransportPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/abtransport',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Materialtransporte zu einem bestimmten Fuhrparkeintrag.
   * @returns MaterialZwischenListe Die Liste der Materialtransporte Zwischeneintraegen.
   * @throws ApiError
   */
  public getMaterialtransporteZwischenListe({
    taetigkeitsberichtId,
    fuhrparkeintragId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
  }): CancelablePromise<MaterialZwischenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/zwischen',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Materialtransport der angelegte Materialtransport
   * @throws ApiError
   */
  public createMaterialtransportZwischenBaustellen({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    requestBody: MaterialtransportZwischenDaten,
  }): CancelablePromise<Materialtransport> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/zwischen',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns MaterialtransportZwischenId der aktualisierte MaterialtransportZwischenId
   * @throws ApiError
   */
  public updateMaterialtransportZwischen({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    zwischenId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    zwischenId: string,
    requestBody: MaterialtransportZwischenDaten,
  }): CancelablePromise<MaterialtransportZwischenId> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/zwischen/{zwischenId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'zwischenId': zwischenId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns MaterialZwischenInfo der gelöschte Materialtransport
   * @throws ApiError
   */
  public deleteMaterialtransportZwischen({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    zwischenId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    zwischenId: string,
  }): CancelablePromise<MaterialZwischenInfo> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/zwischen/{zwischenId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'zwischenId': zwischenId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Bekomme einen Materialtransport
   * @returns Materialtransport Der Materialtransport
   * @throws ApiError
   */
  public getMaterialtransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    materialtransportId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    materialtransportId: string,
  }): CancelablePromise<Materialtransport> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/{materialtransportId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'materialtransportId': materialtransportId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Aktualisiere einen Materialtransport
   * @returns Materialtransport Der aktualisierte Materialtransport
   * @throws ApiError
   */
  public updateMaterialtransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    materialtransportId,
    requestBody,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    materialtransportId: string,
    requestBody: MaterialtransportDaten,
  }): CancelablePromise<Materialtransport> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/{materialtransportId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'materialtransportId': materialtransportId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns Materialtransport der gelöschte Materialtransport
   * @throws ApiError
   */
  public deleteMaterialtransport({
    taetigkeitsberichtId,
    fuhrparkeintragId,
    materialtransportId,
  }: {
    taetigkeitsberichtId: string,
    fuhrparkeintragId: string,
    materialtransportId: string,
  }): CancelablePromise<Materialtransport> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/taetigkeitsberichte/{taetigkeitsberichtId}/fuhrparkeintraege/{fuhrparkeintragId}/materialtransporte/{materialtransportId}',
      path: {
        'taetigkeitsberichtId': taetigkeitsberichtId,
        'fuhrparkeintragId': fuhrparkeintragId,
        'materialtransportId': materialtransportId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Materialtransporte zur Deponie.
   * @returns DeponieListe Die Seite der Materialtransporte zur Deponie.
   * @throws ApiError
   */
  public sucheMaterialtransporteDeponie({
    search,
    rechnungId,
    baustelleId,
    page,
    limit,
    sort,
  }: {
    search?: string,
    rechnungId?: string,
    baustelleId?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<DeponieListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/materialtransporte/deponie',
      query: {
        'search': search,
        'rechnungId': rechnungId,
        'baustelleId': baustelleId,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Materialtransport Der aktualisierte Materialtransport zur Deponie.
   * @throws ApiError
   */
  public updateMaterialtransportDeponie({
    requestBody,
  }: {
    requestBody: DeponieDaten,
  }): CancelablePromise<Materialtransport> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/materialtransporte/deponie',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Durchsuche alle Materialtransporte.
   * @returns MaterialtransportBemerkungListe Die Seite der Materialtransporte.
   * @throws ApiError
   */
  public sucheMaterialtransportBemerkungen({
    zielbaustelleId,
    abfallartId,
  }: {
    zielbaustelleId?: string,
    abfallartId?: string,
  }): CancelablePromise<MaterialtransportBemerkungListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/materialtransporte/bemerkungen',
      query: {
        'zielbaustelleId': zielbaustelleId,
        'abfallartId': abfallartId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
