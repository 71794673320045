import { FieldProps } from "formik";
import { Autocomplete } from "formik-mui";
import {
  AutocompleteRenderInputParams,
  debounce,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ApiError, MaterialtransportBemerkungListe } from "../../../../api";
import { useSucheMaterialtransportBemerkungen } from "../../../../client/hooks";

interface BemerkungNachEakUndBaustelleSelectProps extends FieldProps {
  label: string;
  required?: boolean;
  baustelleId: string;
  abfallartId: string;
}

function BemerkungNachEakUndBaustelleSelect({
  label,
  required,
  baustelleId,
  abfallartId,
  field,
  form,
  meta,
}: BemerkungNachEakUndBaustelleSelectProps) {
  const searchBemerkungen = useSucheMaterialtransportBemerkungen();
  const [optionen, setOptionen] = useState<ReadonlyArray<string>>([]);
  const [inputValue, setInputValue] = useState(field.value);

  useEffect(() => {
    form.setFieldValue(field.name, inputValue);
  }, [inputValue]);

  const ladeOptionen = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (
            error?: ApiError,
            results?: MaterialtransportBemerkungListe
          ) => void
        ) =>
          searchBemerkungen({
            zielbaustelleId: baustelleId,
            abfallartId: abfallartId,
          })
            .then((results) => {
              console.log(results);
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchBemerkungen, baustelleId, abfallartId]
  );

  useEffect(() => {
    ladeOptionen({ input: "" }, (error, results) => {
      if (error) console.error("Error:", error);
      setOptionen([
        ...(results
          ? results.items
              .map((item) => item.bemerkung)
              .filter(
                (bemerkung): bemerkung is string => bemerkung !== undefined
              )
          : []),
      ]);
    });
  }, [ladeOptionen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      freeSolo={true}
      filterSelectedOptions={true}
      filterOptions={(x) => x} // Deaktiviert die Filterlogik, damit komplett nur in dem Backend gesucht werden kann.
      options={optionen}
      getOptionLabel={(option: string) => option}
      onChange={(event, value) => {
        setInputValue(value);
      }}
      onInputChange={(event, value) => {
        setInputValue(value);
      }}
      renderOption={(props, option: string) => {
        return (
          <ListItem {...props}>
            <ListItemText primary={option} />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label + (required ? " *" : "")} />
      )}
    />
  );
}

export default BemerkungNachEakUndBaustelleSelect;
