import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaschinentransport,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useCreateTaetigkeitsbericht,
  useUpdateMaschinentransport,
} from "../../client/hooks";
import {
  Abfallart,
  Abladestelle,
  ActionTimestamp,
  Baustelle,
  Buchungstyp,
  EinheitenListe,
  Entsorger,
  Erzeuger,
  Fahrzeug,
  Lieferant,
  Material,
  ZugewiesenesFahrzeugAnbauteil,
} from "../../api";
import * as Yup from "yup";
import { ExpandContextProvider } from "../../expand";
import DeleteIcon from "@mui/icons-material/Delete";
import FuhrparkeintragFremdPersonalItem from "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragFremdPersonalItem";
import TaetigkeitsberichtFieldsContentExtern from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContentExtern";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

interface MaschinentransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  baustelleId: string;
  transportiertesFahrzeug: any;
  fuhrTyp: Buchungstyp;
  bemerkung?: string;
  lieferscheinNummer?: string;
  abholortBaustelle?: any;
  abstellortBaustelle?: any;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelle?: any;
  zielAbladestelle?: any;
  quelleBaustelle?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: any;
  abfallart?: any;
}

interface ExtendedFuhrparkeintragAnbauteilDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  anbauteil: any;
  anbauteilNutzungsdauer: number;
  anbauteilAnzahl: number;
  anbauteilStundenPreis: number;
  anbauteilKosten: number;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface ExtendedTaetigkeitsberichtDaten {
  lieferant: any;
  baustelle: any;
  datum: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeug: any;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: ExtendedFuhrparkeintragAnbauteilDaten[];
  maschinentransporte: MaschinentransportDaten[];
  materialanfuhr: MaterialTransportDaten[];
  materialabtransport: MaterialTransportDaten[];
  materialtransport: MaterialTransportDaten[];
}

const validationSchema = Yup.object().shape({
  baustelle: yup.object().required(),
  lieferant: yup.object().required(),
  datum: yup.string().required(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeug: yup.object().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteil: yup.object().required(),
              anbauteilNutzungsdauer: yup
                .number()
                .min(0, "Nutzungsdauer muss mindestens 0 sein!")
                .required("Nutzungsdauer ist erforderlich!"),
              anbauteilStundenPreis: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              anbauteilAnzahl: yup
                .number()
                .min(1, "Stückzahl muss mindestens 0 sein!")
                .required("Stückzahl ist erforderlich!"),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
        materialanfuhr: Yup.array().of(
          Yup.object().shape({
            quelleAbladestelle: yup.object().required(),
            material: yup.object().required(),
            meLadeVolumenFahrzeug: yup
              .number()
              .min(0, "Ladevolumen muss mindestens 0 sein!")
              .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialabtransport: Yup.array().of(
          Yup.object().shape({
            zielAbladestelle: yup.object().required(),
            meLadeVolumenFahrzeug: yup
              .number()
              .min(0, "Ladevolumen muss mindestens 0 sein!")
              .required("Ladevolumen ist erforderlich!"),
            material: yup.object().required(),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            bsNr: yup.string().nullable(),
            erzeuger: yup.object().nullable(),
            entsorger: yup.object().nullable(),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialtransport: Yup.array().of(
          Yup.object().shape({
            zielBaustelle: yup.object().required(),
            meLadeVolumenFahrzeug: yup
              .number()
              .min(0, "Ladevolumen muss mindestens 0 sein!")
              .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
            abfallart: yup.object().nullable(),
          })
        ),
      })
    )
    .min(0, ""),
});

export default function ErstelleTaetigkeitsberichtExtern() {
  const { t } = useTranslation("taetigkeitsbericht");
  const createTaetigkeitsbericht = useCreateTaetigkeitsbericht();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const createMaterialtransport = useCreateMaterialtransport();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();
  const updateMaschinentransport = useUpdateMaschinentransport();
  const createMaschinentransport = useCreateMaschinentransport();
  const navigate = useNavigate();
  enum Buchungstyp {
    AR = "AR",
    ER = "ER",
    PS = "PS",
    FP = "FP",
    MA = "MA",
    AB = "AB",
    DP = "DP",
    TR = "TR",
  }
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [expandedItems, setExpandedItems] = useState<boolean[]>([]);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    setExpandedItems((prevState) => {
      const newExpandedItems = [...prevState];
      newExpandedItems[index] = !newExpandedItems[index]; // Toggle the expanded state for this index
      return newExpandedItems;
    });
  };

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
    }
  ) {
    const baustelleId =
      typeof values.baustelle === "string"
        ? values.baustelle
        : (values.baustelle as Baustelle)?.id ?? null;
    const lieferantId =
      typeof values.lieferant === "string"
        ? values.lieferant
        : (values.lieferant as Lieferant)?.id ?? null;
    try {
      const taetigkeitsbericht = await createTaetigkeitsbericht({
        baustelleId: baustelleId,
        datum: values.datum,
        taetigkeitsberichtTyp: "EXTERN",
        lieferantId: lieferantId,
      });

      // Create each fuhrparkeintrag
      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          const fuhrparkeintragData = {
            fahrzeugId:
              typeof fuhrparkeintrag.fahrzeug === "string"
                ? fuhrparkeintrag.fahrzeug
                : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id ?? "",
            fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
            fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
            fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
            bemerkung: fuhrparkeintrag.bemerkung,
          };
          return createFuhrparkeintrag(
            taetigkeitsbericht.id,
            fuhrparkeintragData
          );
        }
      );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);

      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      const fuhrparkeintragAnbauteilPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
            (fuhrparkeintragAnbauteil) => {
              const fuhrparkeintragAnbauteilData = {
                anbauteilId:
                  typeof fuhrparkeintragAnbauteil.anbauteil === "string"
                    ? fuhrparkeintragAnbauteil.anbauteil
                    : (
                        fuhrparkeintragAnbauteil.anbauteil as ZugewiesenesFahrzeugAnbauteil
                      )?.anbauteilId ?? "",
                anbauteilNutzungsdauer:
                  fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                anbauteilStundenPreis:
                  fuhrparkeintragAnbauteil.anbauteilStundenPreis,
              };
              return createFuhrparkeintragAnbauteil(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                fuhrparkeintragAnbauteilData
              );
            }
          );
        }
      );

      await Promise.all(fuhrparkeintragAnbauteilPromises);
      localStorage.setItem("expandedItems", JSON.stringify(expandedItems));
      navigate(
        `/fremdleistungen/${taetigkeitsbericht.id}?expandedindexInherited=${expandedIndex}`
      );
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten & {
        fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
      },
      formikHelpers: FormikHelpers<
        ExtendedTaetigkeitsberichtDaten & {
          fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
        }
      >
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      const baustelleId =
        typeof values.baustelle === "string"
          ? values.baustelle
          : (values.baustelle as Baustelle)?.id ?? null;
      const lieferantId =
        typeof values.lieferant === "string"
          ? values.lieferant
          : (values.lieferant as Lieferant)?.id ?? null;
      try {
        // Create Tätigkeitsbericht first
        const taetigkeitsbericht = await createTaetigkeitsbericht({
          baustelleId: baustelleId,
          datum: values.datum,
          taetigkeitsberichtTyp: "EXTERN",
          lieferantId: lieferantId,
        });

        // Create each fuhrparkeintrag
        const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
          (fuhrparkeintrag) => {
            const fuhrparkeintragData = {
              fahrzeugId:
                typeof fuhrparkeintrag.fahrzeug === "string"
                  ? fuhrparkeintrag.fahrzeug
                  : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id ?? "",
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
            };
            return createFuhrparkeintrag(
              taetigkeitsbericht.id,
              fuhrparkeintragData
            );
          }
        );

        const fuhrparkeintragResults = await Promise.all(
          fuhrparkeintragPromises
        );

        const fuhrparkeintragIds = fuhrparkeintragResults.map(
          (result) => result.id
        );

        const fuhrparkeintragAnbauteilPromises =
          values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil) => {
                const fuhrparkeintragAnbauteilData = {
                  anbauteilId:
                    typeof fuhrparkeintragAnbauteil.anbauteil === "string"
                      ? fuhrparkeintragAnbauteil.anbauteil
                      : (
                          fuhrparkeintragAnbauteil.anbauteil as ZugewiesenesFahrzeugAnbauteil
                        )?.anbauteilId ?? "",
                  anbauteilNutzungsdauer:
                    fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                  anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  anbauteilStundenPreis:
                    fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                };
                return createFuhrparkeintragAnbauteil(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteilData
                );
              }
            );
          });

        await Promise.all(fuhrparkeintragAnbauteilPromises);

        const maschinentransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.maschinentransporte.map(
              (maschinentransport) => {
                const abholortBaustellenId =
                  typeof maschinentransport.abholortBaustelle === "string"
                    ? maschinentransport.abholortBaustelle
                    : (maschinentransport.abholortBaustelle as Baustelle)?.id ??
                      null;
                const abstellortBaustellenId =
                  typeof maschinentransport.abstellortBaustelle === "string"
                    ? maschinentransport.abstellortBaustelle
                    : (maschinentransport.abstellortBaustelle as Baustelle)
                        ?.id ?? null;
                const maschinentransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  transportiertesFahrzeugId:
                    typeof maschinentransport.transportiertesFahrzeug ===
                    "string"
                      ? maschinentransport.transportiertesFahrzeug
                      : (maschinentransport.transportiertesFahrzeug as Fahrzeug)
                          ?.id ?? "",
                  abholortBaustellenId: abholortBaustellenId,
                  abstellortBaustellenId: abstellortBaustellenId,
                  fuhrTyp: Buchungstyp.TR,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                };
                if (maschinentransport.id) {
                  return updateMaschinentransport(
                    taetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    maschinentransport.id,
                    maschinentransportData
                  );
                } else {
                  return createMaschinentransport(
                    taetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    maschinentransportData
                  );
                }
              }
            );
          }
        );

        await Promise.all(maschinentransportPromises);

        const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialanfuhr.map((materialtransport) => {
              const quelleAbladestelleId =
                typeof materialtransport.quelleAbladestelle === "string"
                  ? materialtransport.quelleAbladestelle
                  : (materialtransport.quelleAbladestelle as Abladestelle)
                      ?.id ?? null;
              const materialId =
                typeof materialtransport.material === "string"
                  ? materialtransport.material
                  : (materialtransport.material as Material)?.id ?? null;
              const materialtransportData = {
                baustelleId: taetigkeitsbericht.baustelleId,
                fuhrTyp: Buchungstyp.MA,
                buchungsTyp: Buchungstyp.MA,
                lieferscheinNummer: materialtransport.lieferscheinNummer,
                materialId: materialId,
                quelleAbladestelleId: quelleAbladestelleId,
                bemerkung: materialtransport.bemerkung,
                einheit: materialtransport.einheit,
                anzahlFuhren: materialtransport.anzahlFuhren,
                menge: materialtransport.menge,
                kostenProEinheit: materialtransport.kostenProEinheit,
                meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
                buchungsArt: materialtransport.buchungsArt,
              };
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialtransportData
              );
            });
          }
        );

        await Promise.all(materialanfuhrPromises);

        const materialabtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialabtransport.map(
              (materialtransport) => {
                const zielAbladestelleId =
                  typeof materialtransport.zielAbladestelle === "string"
                    ? materialtransport.zielAbladestelle
                    : (materialtransport.zielAbladestelle as Abladestelle)
                        ?.id ?? null;
                const materialId =
                  typeof materialtransport.material === "string"
                    ? materialtransport.material
                    : (materialtransport.material as Material)?.id ?? null;
                const entsorgerId =
                  typeof materialtransport.entsorger === "string"
                    ? materialtransport.entsorger
                    : (materialtransport.entsorger as Entsorger)?.id ?? null;
                const erzeugerId =
                  typeof materialtransport.erzeuger === "string"
                    ? materialtransport.erzeuger
                    : (materialtransport.erzeuger as Erzeuger)?.id ?? null;
                const materialtransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  fuhrTyp: materialtransport.fuhrTyp,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  materialId: materialId,
                  zielAbladestelleId: zielAbladestelleId,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  menge: materialtransport.menge,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                  bsNr: materialtransport.bsNr,
                  entsorgerId: entsorgerId,
                  erzeugerId: erzeugerId,
                };
                return createMaterialtransport(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialtransportData
                );
              }
            );
          }
        );

        await Promise.all(materialabtransportPromises);

        const materialtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialtransport.map(
              (materialtransport) => {
                const materialtransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  zielBaustelleId:
                    typeof materialtransport.zielBaustelle === "string"
                      ? materialtransport.zielBaustelle
                      : (materialtransport.zielBaustelle as Baustelle)?.id ??
                        null,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  menge: materialtransport.menge,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                  abfallartId:
                    typeof materialtransport.abfallart === "string"
                      ? materialtransport.abfallart
                      : (materialtransport.abfallart as Abfallart)?.id ?? null,
                };
                return createMaterialtransportZwischenBaustellen(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialtransportData
                );
              }
            );
          }
        );

        await Promise.all(materialtransportPromises);

        setStatus(undefined);
        localStorage.setItem("expandedItems", JSON.stringify(expandedItems));
        navigate(`/fremdleistungen`);
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [
      navigate,
      createTaetigkeitsbericht,
      createFuhrparkeintrag,
      createFuhrparkeintragAnbauteil,
      t,
    ]
  );

  return (
    <Layout
      title={capitalize(t("create-activity-report-external"))}
      back={"/fremdleistungen"}
    >
      <Guard
        permission={"taetigkeitsbericht:create"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
        >
          <Formik
            initialValues={{
              lieferant: "",
              baustelle: "",
              datum: "",
              fuhrparkeintraege: [] as ExtendedFuhrparkeintragDaten[],
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => {
              return (
                <>
                  <Typography variant="h5">
                    {capitalize(t("new-activity-report-external"))}
                  </Typography>
                  <DialogContent>
                    <Form id="neuer-taetigkeitsbericht">
                      <TaetigkeitsberichtFieldsContentExtern isNew={true} />
                      <FieldArray name="fuhrparkeintraege">
                        {({ push, remove }) => (
                          <>
                            {values.fuhrparkeintraege.map(
                              (fuhrparkeintrag, index) => (
                                <Box key={index} sx={{ marginTop: 2 }}>
                                  <Box
                                    display={"grid"}
                                    sx={{
                                      gridTemplateColumns: ["1fr", "10fr .1fr"],
                                      gap: 2,
                                      marginTop: 2,
                                    }}
                                  >
                                    <ExpandContextProvider
                                      id={`fuhrparkeintrag-${index}`}
                                    >
                                      <FuhrparkeintragFremdPersonalItem
                                        isNew={!fuhrparkeintrag.id}
                                        fuhrparkeintrag={fuhrparkeintrag}
                                        index={index}
                                        expanded={expandedItems[index] ?? true}
                                        handleChange={handleAccordionChange}
                                        taetigkeitsberichtDatum={values.datum}
                                        dauerVorhanden={0}
                                        isValid={isValid}
                                        isValidating={isValidating}
                                        remove={() => remove(index)}
                                        taetigkeitsberichtId={""}
                                      />
                                    </ExpandContextProvider>
                                  </Box>
                                </Box>
                              )
                            )}
                            <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  var werte = 0;

                                  push({
                                    fahrzeug: "",
                                    fahrzeugHerkunft: "",
                                    fahrzeugStundenPreis: 0,
                                    fahrzeugNutzungsdauer: 0,
                                    bemerkung: "",
                                    fuhrparkeintragAnbauteile: [
                                      {
                                        anbauteil: "",
                                        anbauteilNutzungsdauer: 0,
                                        anbauteilAnzahl: 1,
                                        anbauteilStundenPreis: 0,
                                        anbauteilKosten: 0,
                                      },
                                    ],
                                    maschinentransporte: [],
                                    materialanfuhr: [],
                                    materialabtransport: [],
                                    materialtransport: [],
                                  });

                                  values.fuhrparkeintraege.map(() => {
                                    werte++;
                                  });

                                  if (werte > 0) {
                                    saveFuhrparkeintraege(values);
                                  }
                                }}
                                disabled={isValidating || !isValid}
                              >
                                {capitalize(t("add-carpool"))}
                              </Button>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    <SubmitButton
                      form="neuer-taetigkeitsbericht"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("save"))}
                    </SubmitButton>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}
