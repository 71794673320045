import { Form, Formik, FormikHelpers } from "formik";
import {
  capitalize,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import { useCreateAnbauteil } from "../../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import { AnbauteilDaten } from "../../../api";
import AnbauteilFieldsContent from "../../../components/fuhrpark/anbauteil/AnbauteilFieldsContent";
import ErrorAlert from "../../../components/ErrorAlert";
import { notPermitted } from "../../../errors";
import Guard from "../../../components/Guard";

interface ErstelleAnbauteilProps {
  onClose: () => void;
}

export default function ErstelleAnbauteil({ onClose }: ErstelleAnbauteilProps) {
  const { t } = useTranslation("fuhrpark");
  const { mutateAsync } = useSaveAnbauteil();
  let schema = yup.object().shape({
    geraeteBezeichnung: yup
      .string()
      .required(capitalize(t("attachment-part-required-title"))),
  });
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      values: {
        geraeteBezeichnung: string;
      },
      formikHelpers: FormikHelpers<{
        geraeteBezeichnung: string;
      }>
    ) => {
      const { geraeteBezeichnung } = values;
      const { setSubmitting, setStatus } = formikHelpers;
      const anbauteil = await mutateAsync({
        geraeteBezeichnung: geraeteBezeichnung,
      });
      onClose();
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, mutateAsync, onClose]
  );
  return (
    <Guard
      permission={"fuhrpark:create"}
      fallback={<ErrorAlert error={notPermitted()} />}
    >
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            geraeteBezeichnung: "",
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty }) => (
            <>
              <Typography variant="h5">{t("new-attachment-part")}</Typography>
              <DialogContent>
                <Form id="neues-anbauteil">
                  <AnbauteilFieldsContent />
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="neues-anbauteil"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Guard>
  );
}

function useSaveAnbauteil() {
  const createAnbauteil = useCreateAnbauteil();
  const queryClient = useQueryClient();

  return useMutation(
    (input: AnbauteilDaten) => {
      return createAnbauteil(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["anbauteile"]);
      },
    }
  );
}
