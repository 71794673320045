import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useGetRechnungDatevProtokolle } from "../../client/hooks";
import { bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect, useState } from "react";
import { DatevProtokoll } from "../../api";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
export default function DatevProtokolleButton({
  id,
  label,
  color,
  title,
}: {
  id: string;
  label: string;
  color: "primary" | "secondary" | "error" | "warning" | "info" | "success";
  title: string;
}) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `datev-${id}-protokoll`,
  });
  const datevProtokolle = useGetRechnungDatevProtokolle();
  const [protokolle, setProtokolle] = useState<DatevProtokoll[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await datevProtokolle(id);
      setProtokolle(data.items);
    };
    fetchData();
  }, [id]);
  return (
    <>
      <Chip
        icon={
          color === "success" ? (
            <CheckCircleOutlineIcon />
          ) : color === "warning" ? (
            <IndeterminateCheckBoxIcon />
          ) : (
            <HighlightOffIcon />
          )
        }
        title={title}
        color={color}
        label={label}
        onClick={(event) => {
          event.stopPropagation();
          popupState.open();
        }}
      ></Chip>
      <Dialog {...bindPopover(popupState)}>
        <DialogTitle id="alert-dialog-title">DATEV Protokolle</DialogTitle>
        <DialogContent>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {protokolle.map((protokoll) => (
                <TableRow>
                  <TableCell>
                    {protokoll.timestamp
                      ? new Date(protokoll.timestamp).toLocaleDateString(
                          "de-DE",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )
                      : "N/A"}
                  </TableCell>
                  <TableCell>{protokoll.text}</TableCell>
                  <TableCell>{protokoll.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => popupState.close()} color="inherit">
            abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
