import {
  capitalize,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../../data";
import { useT } from "../../../i18n";
import DataTable from "../../data/DataTable";
import { useGetApi } from "../../../client";

export type FahrzeugKostenFilter = {
  fahrzeugId: string;
  search?: string;
};

export type FahrzeugKosten = {
  id: string;
  kostenVersion: number;
  kostenStunde: number;
};

export type Props = Omit<DataRequest<FahrzeugKostenFilter>, "filter"> &
  Partial<Pick<DataRequest<FahrzeugKostenFilter>, "filter">> & {
    fahrzeugKostenId: string;
  };

export default function FahrzeugKostenDataTable({
  fahrzeugKostenId,
  ...input
}: Props) {
  const { id: fahrzeugId = "" } = useParams<{
    id: string;
  }>();
  const { t } = useT("fuhrpark");
  const request = useDataRequest<FahrzeugKostenFilter>({
    filter: { fahrzeugId: fahrzeugId },
    ...input,
  });

  return (
    <>
      <Paper elevation={1} sx={{ padding: 3 }}>
        <Stack direction={"column"} spacing={2}>
          <Typography variant="h5">{capitalize(t("cost-history"))}</Typography>
          <FilterComp {...request} />
          <FahrzeugKostenResults {...request} />
        </Stack>
      </Paper>
    </>
  );
}

function FilterComp(request: DataRequestState<FahrzeugKostenFilter>) {
  const { t } = useT("fuhrpark");
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return <></>;
}

function FahrzeugKostenResults({
  ...request
}: DataRequestState<FahrzeugKostenFilter>) {
  return <Results request={request} />;
}
function Results({
  request,
}: {
  request: DataRequestState<FahrzeugKostenFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const { t } = useT("fuhrpark");

  const ladeFahrzeugKosten: DataLoader<FahrzeugKostenFilter, FahrzeugKosten> =
    useCallback(
      async (params) =>
        (await getApi()).fahrzeuge.getFahrzeugKosten({ ...params }),
      [getApi]
    );

  return (
    <>
      <DataTable
        columns={useColumns()}
        request={request}
        queryKey={["fahrzeug-kosten"]}
        loadData={ladeFahrzeugKosten}
        name={"fahrzeug-kosten"}
      />
    </>
  );
}
function useColumns(): Array<GridColDef<FahrzeugKosten>> {
  const { t, capitalize } = useT("fuhrpark");

  return useMemo(
    () => [
      {
        field: "gueltigVon",
        headerName: capitalize(t("assign-attachment-part-valid-from")),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleString();
          }
          return "";
        },
      },
      {
        field: "gueltigBis",
        headerName: capitalize(t("assign-attachment-part-valid-to")),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleString();
          }
          return "aktuell";
        },
      },
      {
        field: "kostenStunde",
        headerName: capitalize(t("assign-attachment-part-cost-hour")),
        flex: 1,
      },
    ],
    [t, capitalize]
  );
}
