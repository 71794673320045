// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type MaschinentransportPage } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheMaschinentransporteQuery = {
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheMaschinentransporte(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  query: SucheMaschinentransporteQuery
) => Promise<MaschinentransportPage> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, fuhrparkeintragId, query = {}) =>
      (await getAPI()).maschinentransporte.sucheMaschinentransporte({
        taetigkeitsberichtId,
        fuhrparkeintragId,
        ...query,
      }),
    [getAPI]
  );
}
