import { Box, capitalize, Stack } from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../i18n";
import BaustellenSelect from "../baustellen/BaustellenSelect";
import { useEffect } from "react";
import { useGetBenutzer } from "../../client/hooks";
import LieferantenSelect from "../lieferanten/LieferantenSelect";

export default function TaetigkeitsberichtFieldsContentExtern({
  isNew,
}: {
  isNew: boolean;
}) {
  const { t } = useT("taetigkeitsbericht");
  const { setFieldValue } = useFormikContext();
  const [mitarbeiterField] = useField("mitarbeiter");
  const [stundenField] = useField("arbeitszeitNetto");
  const [stundensatzField] = useField("stundensatz");

  const searchBenutzer = useGetBenutzer();

  const calculateLohnkosten = () => {
    setFieldValue(
      "lohnkosten",
      (stundensatzField.value * stundenField.value).toFixed(2)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const benutzer = await searchBenutzer(mitarbeiterField.value);
      await setFieldValue("stundensatz", benutzer.berechnungsSatz);
    };
    if (isNew && mitarbeiterField.value) {
      fetchData();
    }
  }, [mitarbeiterField.value]);

  useEffect(() => {
    calculateLohnkosten();
  }, [stundenField.value, stundensatzField.value]);

  return (
    <Stack direction="column">
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr 1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={LieferantenSelect}
          name="lieferant"
          label={capitalize(t("activity-report-supplier"))}
          required
        />
        <FastField
          component={BaustellenSelect}
          name="baustelle"
          label={capitalize(t("activity-report-construction"))}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("activity-report-date"))}
          name="datum"
          type={"date"}
          InputLabelProps={{ shrink: true }}
          required
        />
      </Box>
    </Stack>
  );
}
