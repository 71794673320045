import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import { AnwesenheitInfo } from "../../api";
import FilterBenutzerSelect from "../benutzer/FilterBenutzerSelect";
import { Field, Formik } from "formik";
import DataTableWithFilter from "../data/DataTableWithFilter";
import { useApproveMultipleAnwesenheiten } from "../../client/hooks";
import { useQueryClient } from "react-query";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";

export type AnwesenheitenFilter = {
  search?: string;
  mitarbeiterId?: string;
  filtered?: string;
};

interface AnwesenheitProps {
  anwesenheitIds: string[];
  setAnwesenheitIds: (value: string[]) => void;
}

export type Props = Omit<DataRequest<AnwesenheitenFilter>, "filter"> &
  Partial<Pick<DataRequest<AnwesenheitenFilter>, "filter">>;

export default function AnwesenheitenDataTable({ ...input }: Props) {
  const request = useDataRequest<AnwesenheitenFilter>({ filter: {}, ...input });
  const [anwesenheitIds, setAnwesenheitIds] = useState<string[]>([]);

  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        anwesenheitIds={anwesenheitIds}
        setAnwesenheitIds={setAnwesenheitIds}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
        {...request}
      />
      <AnwesenheitenResults
        anwesenheitIds={anwesenheitIds}
        setAnwesenheitIds={setAnwesenheitIds}
        setFilteredState={setFilteredState}
        {...request}
      />
    </Stack>
  );
}

function FilterComp({
  anwesenheitIds,
  setAnwesenheitIds,
  filteredStateFilter,
  setFilteredState,
  ...request
}: AnwesenheitProps & {
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
} & DataRequestState<AnwesenheitenFilter>) {
  const [{ search, mitarbeiterId, filtered }, setField] =
    useDebouncedFilter(request);
  const setMitarbeiterId = (value: string) => {
    setField("mitarbeiterId", value);
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const approve = useApproveMultipleAnwesenheiten();
  const queryClient = useQueryClient();

  async function approveAnwesenheiten(anwesenheitsIds: Array<string>) {
    await approve(anwesenheitsIds);
    queryClient.invalidateQueries(["anwesenheiten"]);
    navigate(`/anwesenheiten`);
  }

  useEffect(() => {
    setField("filtered", filteredStateFilter);
  }, [filteredStateFilter]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-anwesenheit");
            }}
          >
            Anwesenheit anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Formik
            initialValues={{ mitarbeiterId: mitarbeiterId }}
            onSubmit={() => {}}
          >
            <Field
              component={FilterBenutzerSelect}
              name={"mitarbeiterId"}
              label={"Mitarbeiter"}
              setBenutzer={setMitarbeiterId}
            ></Field>
          </Formik>
          <ButtonGroup sx={{ position: "absolute", top: 0, right: 0 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ marginRight: 2 }}
              onClick={() => approveAnwesenheiten(anwesenheitIds)}
            >
              Anwesenheiten freigeben
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate("/neu-anwesenheit");
              }}
            >
              Anwesenheit anlegen
            </Button>
          </ButtonGroup>
        </>
      )}
    </Box>
  );
}

function AnwesenheitenResults({
  anwesenheitIds,
  setAnwesenheitIds,
  setFilteredState,
  ...request
}: AnwesenheitProps & {
  setFilteredState: (value: string) => void;
} & DataRequestState<AnwesenheitenFilter>) {
  return (
    <Results
      anwesenheitIds={anwesenheitIds}
      setAnwesenheitIds={setAnwesenheitIds}
      request={request}
      setFilteredState={setFilteredState}
    />
  );
}

function Results({
  anwesenheitIds,
  setAnwesenheitIds,
  request,
  setFilteredState,
}: AnwesenheitProps & {
  request: DataRequestState<AnwesenheitenFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadAnwesenheiten: DataLoader<AnwesenheitenFilter, AnwesenheitInfo> =
    useCallback(
      async (params) =>
        (await getApi()).anwesenheiten.sucheAnwesenheiten({ ...params }),
      [getApi]
    );
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["anwesenheiten"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadAnwesenheiten}
      name={"anwesenheiten"}
      // @ts-ignore
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      onRowSelectionModelChange={(newRowSelectionModel: any) => {
        setAnwesenheitIds(newRowSelectionModel);
      }}
      onRowClick={(row) => {
        navigate("/anwesenheiten/" + row.id);
      }}
      setFiltered={setFilteredState}
    />
  );
}

function useColumns(): Array<GridColDef<AnwesenheitInfo>> {
  const { t } = useT("entsorger");

  return useMemo(
    () => [
      {
        field: "name",
        headerName: "Mitarbeiter",
        flex: 1,
      },
      {
        field: "datum",
        headerName: t("date"),
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleDateString(undefined, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }
          return "";
        },
      },
      {
        field: "start",
        headerName: "Start",
        filterable: false,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "ende",
        headerName: "Ende",
        filterable: false,
        renderCell: (params) => {
          if (params.value) {
            return new Date(params.value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          }
          return "";
        },
      },
      {
        field: "arbeitszeitNetto",
        headerName: "Netto Arbeitszeit",
        flex: 0.5,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "fahrzeiten",
        headerName: "Fahrzeiten",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "unbezahltePause",
        headerName: "Unbezahlte Pause",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "arbeitszeitBrutto",
        headerName: "Brutto Arbeitszeit",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
          switch (params.value) {
            case "OFFEN":
              return (
                <Chip
                  icon={<SpellcheckIcon />}
                  title={"Offen"}
                  color={"secondary"}
                  label={"Offen"}
                ></Chip>
              );
            case "ANGENOMMEN":
              return (
                <Chip
                  icon={<PlaylistAddCheckCircleIcon />}
                  title={"Freigegeben"}
                  color={"success"}
                  label={"Freigegeben"}
                ></Chip>
              );
          }
        },
      },
    ],
    [t]
  );
}
