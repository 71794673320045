/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Abfallart } from '../models/Abfallart';
import type { AbfallartDaten } from '../models/AbfallartDaten';
import type { AbfallartenListe } from '../models/AbfallartenListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AbfallartenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AbfallartenListe Liste der Abfallarten
   * @throws ApiError
   */
  public sucheAbfallarten({
    search,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<AbfallartenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/abfallarten',
      query: {
        'search': search,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abfallart die angelegte Abfallart
   * @throws ApiError
   */
  public createAbfallart({
    requestBody,
  }: {
    requestBody: AbfallartDaten,
  }): CancelablePromise<Abfallart> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/abfallarten',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abfallart Abfallart
   * @throws ApiError
   */
  public getAbfallart({
    id,
  }: {
    id: string,
  }): CancelablePromise<Abfallart> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/abfallarten/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abfallart die aktualisierte Abfallart
   * @throws ApiError
   */
  public updateAbfallart({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: AbfallartDaten,
  }): CancelablePromise<Abfallart> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/abfallarten/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Abfallart die gelöschte Abfallart
   * @throws ApiError
   */
  public deleteAbfallart({
    id,
  }: {
    id: string,
  }): CancelablePromise<Abfallart> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/abfallarten/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
