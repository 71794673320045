/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tagesbericht } from '../models/Tagesbericht';
import type { TagesberichtDaten } from '../models/TagesberichtDaten';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagesberichteService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns Tagesbericht der angelegte Tagesbericht
   * @throws ApiError
   */
  public createTagesbericht({
    requestBody,
  }: {
    requestBody: TagesberichtDaten,
  }): CancelablePromise<Tagesbericht> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/tagesberichte',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Tagesbericht der bearbeitete Tagesbericht
   * @throws ApiError
   */
  public updateTagesbericht({
    anwesenheitsId,
    requestBody,
  }: {
    anwesenheitsId: string,
    requestBody: TagesberichtDaten,
  }): CancelablePromise<Tagesbericht> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/tagesberichte/{anwesenheitsId}',
      path: {
        'anwesenheitsId': anwesenheitsId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
