// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type Anwesenheit } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export function useDeleteAnwesenheit(): (
  anwesenheitId: string
) => Promise<Anwesenheit> {
  const getAPI = useGetApi();
  return useCallback(
    async (anwesenheitId) =>
      (await getAPI()).anwesenheiten.deleteAnwesenheit({ anwesenheitId }),
    [getAPI]
  );
}
